export const descriptionGeneral1704 = {
    "5g-micro": `5G Micro 8T8R là giải pháp truy cập 5G do Viettel cung cấp, sử dụng công nghệ MIMO (Multiple-Input Multiple-Output) với 8 ăng-ten phát và 8 ăng-ten thu, được thiết kế chuyên dụng cho các khu vực hẹp và mật độ người dùng cao. 5G Micro 8T8R sở hữu những ưu điểm vượt trội so với các giải pháp truy cập 5G truyền thống như: 
    5G Micro 8T8R có thể hỗ trợ nhiều người dùng truy cập cùng lúc, đáp ứng nhu cầu truy cập dữ liệu tốc độ cao trong khu vực hẹp. Giải pháp này giúp tăng dung lượng mạng lên gấp 4 lần so với các giải pháp 4G LTE, đảm bảo trải nghiệm mượt mà cho người dùng khi sử dụng các dịch vụ 5G như xem video VR/AR, chơi game online, tải xuống dữ liệu tốc độ cao. 5G Micro 8T8R có thể cung cấp tốc độ truy cập 5G lên đến 1.5 Gbps, cao hơn nhiều so với tốc độ truy cập 4G LTE. Giải pháp này giúp người dùng truy cập internet nhanh chóng, tải xuống dữ liệu trong tích tắc và tận hưởng trải nghiệm 5G trọn vẹn. 5G Micro 8T8R có thể phát sóng 5G xa hơn và xuyên qua các chướng ngại vật tốt hơn so với các giải pháp 4G LTE. Giải pháp này giúp mở rộng độ phủ sóng 5G đến các khu vực hẹp, bên trong tòa nhà, đảm bảo người dùng ở mọi nơi đều có thể truy cập internet tốc độ cao. 5G Micro 8T8R có kích thước nhỏ gọn, dễ dàng lắp đặt và triển khai trong các khu vực hẹp. Giải pháp này có thể được lắp đặt trên cột điện, tường nhà, mái nhà, ... giúp tiết kiệm không gian và đảm tính thẩm mỹ. 5G Micro 8T8R sử dụng công nghệ MIMO tiên tiến để tối ưu hóa hiệu suất truyền dẫn, giúp tiết kiệm năng lượng và giảm chi phí vận hành mạng. Giải pháp này góp phần bảo vệ môi trường và nâng cao hiệu quả sử dụng năng lượng cho nhà mạng.
    5G Micro 8T8R là giải pháp truy cập 5G linh hoạt, hiệu quả cho khu vực hẹp, giúp Viettel mang đến dịch vụ 5G chất lượng cao đến với người dùng trên toàn quốc. Giải pháp này đóng góp quan trọng vào sự phát triển của mạng 5G tại Việt Nam, góp phần thúc đẩy công nghiệp 4.0 và đưa Việt Nam hòa nhập sâu rộng vào cuộc cách mạng công nghiệp 4.0.`,
    "5g-macrocell": `5G Macrocell 8T8R là giải pháp truy cập 5G do Viettel cung cấp, sử dụng công nghệ MIMO (Multiple-Input Multiple-Output) với 8 ăng-ten phát và 8 ăng-ten thu, mang đến những ưu điểm vượt trội so với các giải pháp truy cập 5G truyền thống.
    5G Macrocell 8T8R có thể hỗ trợ nhiều người dùng truy cập cùng lúc, đáp ứng nhu cầu truy cập dữ liệu tốc độ cao ngày càng tăng trong mạng 5G. Giải pháp này giúp tăng dung lượng mạng lên gấp 4 lần so với các giải pháp 4G LTE, đảm bảo trải nghiệm mượt mà cho người dùng khi sử dụng các dịch vụ 5G như xem video VR/AR, chơi game online, tải xuống dữ liệu tốc độ cao.
    5G Macrocell 8T8R có thể cung cấp tốc độ truy cập 5G lên đến 1.5 Gbps, cao hơn nhiều so với tốc độ truy cập 4G LTE. Giải pháp này giúp người dùng truy cập internet nhanh chóng, tải xuống dữ liệu trong tích tắc và tận hưởng trải nghiệm 5G trọn vẹn. Không chỉ vật, thiết bị có thể phát sóng 5G xa hơn và xuyên qua các chướng ngại vật tốt hơn so với các giải pháp 4G LTE. Giải pháp này giúp mở rộng độ phủ sóng 5G đến các khu vực xa xôi, hẻo lánh, đảm bảo người dùng ở mọi nơi đều có thể truy cập internet tốc độ cao. Đặc biệt, 5G Macrocell 8T8R sử dụng công nghệ MIMO tiên tiến để tối ưu hóa hiệu suất truyền dẫn, giúp tiết kiệm năng lượng và giảm chi phí vận hành mạng. Giải pháp này góp phần bảo vệ môi trường và nâng cao hiệu quả sử dụng năng lượng cho nhà mạng.
    Hiện nay, 5G Macrocell 8T8R đã được Viettel triển khai tại nhiều tỉnh thành phố trên cả nước, mang đến trải nghiệm 5G tuyệt vời cho người dùng.`,
    "5g-massive-mimo": `5G Massive MIMO 32T32R là giải pháp truy cập 5G tiên tiến do Viettel cung cấp, sử dụng công nghệ MIMO (Multiple-Input Multiple-Output) với 32 ăng-ten phát và 32 ăng-ten thu, mang đến những ưu điểm vượt trội so với các giải pháp truy cập 5G truyền thống. 5G Massive MIMO 32T32R có thể hỗ trợ nhiều người dùng truy cập cùng lúc, đáp ứng nhu cầu truy cập dữ liệu tốc độ cao ngày càng tăng trong mạng 5G. Giải pháp này giúp tăng dung lượng mạng lên gấp 8 lần so với các giải pháp 4G LTE, đảm bảo trải nghiệm mượt mà cho người dùng khi sử dụng các dịch vụ 5G như xem video VR/AR, chơi game online, tải xuống dữ liệu tốc độ cao.
    5G Massive MIMO 32T32R có thể cung cấp tốc độ truy cập 5G lên đến 2.5 Gbps, cao hơn nhiều so với tốc độ truy cập 4G LTE. Giải pháp này giúp người dùng truy cập internet nhanh chóng, tải xuống dữ liệu trong tích tắc và tận hưởng trải nghiệm 5G trọn vẹn.
    Thiết bị có thể phát sóng 5G xa hơn và xuyên qua các chướng ngại vật tốt hơn so với các giải pháp 4G LTE. Giải pháp này giúp mở rộng độ phủ sóng 5G đến các khu vực xa xôi, hẻo lánh, đảm bảo người dùng ở mọi nơi đều có thể truy cập internet tốc độ cao.
    5G Massive MIMO 32T32R sử dụng công nghệ MIMO tiên tiến để tối ưu hóa hiệu suất truyền dẫn, giúp tiết kiệm năng lượng và giảm chi phí vận hành mạng. Giải pháp này góp phần bảo vệ môi trường và nâng cao hiệu quả sử dụng năng lượng cho nhà mạng.
    5G Massive MIMO 32T32R là giải pháp truy cập 5G đột phá, giúp Viettel cung cấp dịch vụ 5G chất lượng cao đến với người dùng trên toàn quốc. Giải pháp này đóng góp quan trọng vào sự phát triển của mạng 5G tại Việt Nam, góp phần thúc đẩy công nghiệp 4.0 và đưa Việt Nam hòa nhập sâu rộng vào cuộc cách mạng công nghiệp 4.0.`,
    "site-router-100g": `Site Router 100G Viettel là thiết bị được thiết kế đặc biệt cho mạng 5G, ứng dụng công nghệ tiên tiến nhất ở lớp access của mạng metro, đáp ứng nhu cầu dịch vụ 5G ngày càng tăng. Thiết bị sở hữu nhiều tính năng vượt trội, giúp nâng cao hiệu quả vận hành mạng và mang lại trải nghiệm 5G tốt nhất cho người dùng.
    Site Router 100G Viettel có khả năng xử lý lưu lượng truy cập cao, lên đến 100 Gbps, đáp ứng nhu cầu truy cập dữ liệu tốc độ cao của người dùng 5G. Thiết bị hỗ trợ nhiều giao thức truyền dẫn đa dạng như: GPON, XGPON, TWDM PON, giúp nhà mạng linh hoạt triển khai mạng 5G trên các hạ tầng cáp quang khác nhau.
    Thiết bị được thiết kế với độ bền cao, có thể hoạt động trong môi trường khắc nghiệt và được tích hợp nhiều tính năng dự phòng, đảm bảo mạng hoạt động liên tục 24/7, không gián đoạn dịch vụ.
    Site Router 100G Viettel có thể dễ dàng mở rộng dung lượng khi nhu cầu sử dụng tăng cao, hỗ trợ nhiều mô-đun chức năng khác nhau, giúp nhà mạng linh hoạt đáp ứng các yêu cầu cụ thể của từng khu vực.
    Site Router 100G Viettel được tích hợp hệ thống quản lý thông minh, giúp nhà mạng dễ dàng theo dõi và quản lý thiết bị từ xa. Hệ thống quản lý cung cấp nhiều tính năng như: giám sát hiệu suất, cảnh báo sự cố, tự động điều chỉnh cấu hình, ... Đặc biệt, Site Router 100G Viettel được tích hợp các tính năng bảo mật tiên tiến, giúp bảo vệ mạng khỏi các cyberattacks.
    Site Router 100G Viettel là giải pháp tối ưu cho hạ tầng mạng 5G, giúp nhà mạng cung cấp dịch vụ 5G chất lượng cao, đáp ứng nhu cầu ngày càng tăng của người dùng. Hiện nay, Site Router 100G Viettel đã được triển khai tại nhiều tỉnh thành phố trên cả nước, góp phần nâng cao chất lượng dịch vụ 5G cho người dân Việt Nam.`,
    "chip-5g-dfe": `Chip 5G DFE là sản phẩm do các kỹ sư Viettel nghiên cứu và phát triển thành công, đánh dấu một cột mốc quan trọng trong hành trình làm chủ công nghệ viễn thông của Việt Nam. Chip được thiết kế với nhiều tính năng ưu việt, đáp ứng nhu cầu ngày càng cao của thị trường 5G:
    Chip 5G DFE có khả năng tính toán 1.000 tỷ phép tính trên giây, tương đương với chip 5G của các nhà sản xuất hàng đầu thế giới như Qualcomm, Huawei, Samsung. Nhờ đó, chip có thể xử lý hiệu quả các tác vụ đòi hỏi hiệu suất cao trong mạng 5G như truyền tải dữ liệu tốc độ cao, hỗ trợ nhiều người dùng cùng lúc, đáp ứng nhu cầu về các dịch vụ 5G tiên tiến như VR/AR, xe tự lái, ..
    Chip 5G DFE được thiết kế với kiến trúc tiên tiến, giúp tiết kiệm năng lượng so với các dòng chip 5G khác. Việc tiết kiệm năng lượng góp phần giảm chi phí vận hành mạng 5G và bảo vệ môi trường.
    Chip 5G DFE có kích thước nhỏ gọn, giúp tiết kiệm diện tích lắp đặt và tối ưu hóa thiết kế các thiết bị mạng 5G. Bên cạnh đó, Chip 5G DFE được thiết kế theo tiêu chuẩn quốc tế, tương thích với các thiết bị mạng 5G của các nhà cung cấp khác nhau. Điều này giúp nhà mạng dễ dàng triển khai và nâng cấp mạng 5G, đồng thời giảm chi phí đầu tư. Ngoài ra, Chip 5G DFE được tích hợp các tính năng bảo mật tiên tiến, giúp bảo vệ dữ liệu và mạng lưới viễn thông khỏi các cyberattacks.
    Sự ra đời của Chip 5G DFE là minh chứng cho năng lực nghiên cứu và phát triển của Viettel, khẳng định vị thế của Việt Nam trên bản đồ công nghệ viễn thông thế giới. Chip 5G DFE sẽ góp phần thúc đẩy sự phát triển của mạng 5G tại Việt Nam, mang đến cho người dân và doanh nghiệp những trải nghiệm viễn thông hoàn toàn mới.`,
    "thiet-bi-ai-home-hub": `Viettel AI Home Hub là thiết bị trợ lý thông minh dành cho gia đình, được tích hợp nhiều tính năng hiện đại, mang đến trải nghiệm tiện nghi và an toàn cho người dùng. Thiết bị sử dụng công nghệ trí tuệ nhân tạo (AI) tiên tiến, cho phép người dùng điều khiển các thiết bị điện tử trong nhà bằng giọng nói, tương tác với các dịch vụ giải trí đa dạng và truy cập thông tin nhanh chóng. Viettel AI Home Hub nổi bật với các tính năng như: ra lệnh bằng giọng nói để điều khiển các thiết bị điện tử trong nhà như: tivi, điều hòa, quạt, đèn, ...Tạo ngữ cảnh thông minh để kích hoạt nhiều thiết bị cùng lúc với một câu lệnh. Hẹn giờ bật/tắt các thiết bị tự động. Trả lời các câu hỏi bằng tiếng Việt một cách thông minh và chính xác. Cập nhật tin tức, thời tiết, giao thông. Hỗ trợ đặt lịch hẹn, ghi nhớ, tạo danh sách mua sắm. Giải trí với nhạc, phim, truyện audio. Ra lệnh bằng giọng nói để gọi điện thoại, nhắn tin cho người thân, bạn bè. Thiết lập cuộc gọi khẩn cấp SOS. Kết nối với camera Viettel để theo dõi hình ảnh camera trực tiếp. Nhận thông báo khi có chuyển động lạ trong nhà. Đặc biệt, thiết bị giúp hỗ trợ học tập cho trẻ em với các bài học tiếng Anh, toán học, ... Viettel AI Home Hub là thiết bị thông minh không thể thiếu cho ngôi nhà hiện đại, mang đến cho bạn và gia đình cuộc sống tiện nghi, an toàn và thú vị hơn.`,
    "camera-giao-thong": `Viettel cung cấp hệ thống camera giao thông thông minh, ứng dụng công nghệ AI tiên tiến, góp phần nâng cao hiệu quả quản lý giao thông, đảm bảo an toàn cho người tham gia giao thông. Hệ thống camera giao thông Viettel giúp giám sát giao thông theo thời gian thực, phát hiện vi phạm giao thông tự động, đếm lượng phương tiện, hỗ trợ điều tra tai nạn giao thông, đặc biệt là quản lý giao thông thông minh giúp điều chỉnh giao thông linh hoạt, giảm ùn tắc, tiết kiệm thời gian di chuyển cho người dân. Sử dụng Camera giao thông Viettel góp phần nâng cao hiệu quả quản lý giao thông, đảm bảo an toàn cho người tham gia giao thông. Giảm thiểu ùn tắc giao thông, tiết kiệm thời gian di chuyển. Phát hiện vi phạm giao thông hiệu quả, xử lý vi phạm nhanh chóng, chính xác. Thu thập dữ liệu giao thông valuable, phục vụ công tác quy hoạch, phát triển hạ tầng giao thông.`,
    "camera-thong-minh-toa-nha": `Viettel cung cấp giải pháp camera thông minh cho tòa nhà toàn diện, giúp nâng cao an ninh và hiệu quả quản lý cho doanh nghiệp. Hệ thống camera của Viettel sở hữu nhiều tính năng ưu việt, đáp ứng nhu cầu đa dạng của khách hàng như: Camera Viettel sử dụng công nghệ hình ảnh tiên tiến, cho hình ảnh sắc nét, độ phân giải cao, giúp quan sát chi tiết mọi ngóc ngách trong tòa nhà, hỗ trợ camera quan sát ban đêm hồng ngoại, đảm bảo hình ảnh rõ ràng ngay cả trong điều kiện thiếu sáng.
    Với những tính năng thông minh, camera giúp nhận diện khuôn mặt,  xác định và theo dõi đối tượng ra vào tòa nhà, hỗ trợ kiểm soát an ninh hiệu quả.  Phát hiện các hành vi bất thường, nghi ngờ, giúp ngăn chặn kịp thời các hành vi xâm nhập, trộm cắp. Theo dõi số lượng người ra vào tòa nhà, giúp quản lý lưu lượng di chuyển và điều chỉnh hoạt động an ninh phù hợp. Tự động phát hiện và báo động khi có sự kiện bất thường xảy ra, giúp nhân viên an ninh phản ứng nhanh chóng. Dữ liệu hình ảnh và video được lưu trữ trên hệ thống cloud an toàn của Viettel, đảm bảo tính bảo mật và khả năng truy cập từ xa dễ dàng. Hệ thống camera Viettel được quản lý thông qua phần mềm chuyên dụng, giúp người dùng dễ dàng theo dõi hình ảnh camera trực tiếp, xem lại video, quản lý các tính năng thông minh và cài đặt hệ thống. Hỗ trợ truy cập từ xa qua điện thoại thông minh, máy tính bảng, giúp người dùng theo dõi camera mọi lúc mọi nơi.
    Viettel cung cấp giải pháp camera thông minh trọn gói, bao gồm thiết bị camera, phần mềm quản lý, dịch vụ lắp đặt và bảo trì, giúp khách hàng tiết kiệm thời gian và chi phí. Đội ngũ nhân viên kỹ thuật chuyên nghiệp của Viettel luôn sẵn sàng hỗ trợ khách hàng tư vấn, giải đáp thắc mắc và khắc phục sự cố 24/7.`,
    "camera-thong-minh-ho-gia-dinh": `Home Camera Viettel là giải pháp camera thông minh với nhiều tính năng hiện đại, đáp ứng nhu cầu theo dõi, giám sát tình hình an ninh, bảo vệ tài sản hoặc đơn giản là đàm thoại trực tiếp với các thành viên trong gia đình.
    Dòng camera của Viettel bao gồm 2 sản phẩm: Camera 360 độ trong nhà và camera ngoài trời. Mỗi loại camera sẽ có chức năng theo dõi, bảo vệ trong phạm vi được lắp đặt của mình. Bạn hoàn toàn có thể lựa chọn 1 trong 2 thiết bị này, tuy nhiên, Viettel khuyến khích khách hàng nên sử dụng trọn bộ Camera Wifi bởi những lý do sau: Người dùng có thể dễ dàng cài đặt, quản lý và theo dõi 2 thiết bị camera này qua ứng dụng VHome do Viettel phát hành. Đặc biệt, những thiết bị này đều rất dễ kết nối và có sự liên kết với nhau để đưa ra kết luận chính xác trong những tình huống xấu. Camera Viettel được trang bị những tính năng thông minh như: Công nghệ AI phát hiện và phân biệt chuyển động của người/vật; Có thể ghi lại hình ảnh có màu kể cả vào ban đêm; Đặc biệt, tính năng đàm thoại trực tiếp 2 chiều giúp thuận tiện cho việc giám sát…
    Thiết bị camera quan sát ngoài trời có thể được lắp đặt ở các không gian bên ngoài, với tính năng chống bụi, chống khuẩn và chống thấm nước, khả năng quan sát rất rộng. Camera trong nhà được lắp đặt ở phòng khách, phòng bếp, phòng ngủ, phòng lưu trữ hoặc lắp đặt ở ngoài sân…Như vậy trọn bộ Camera Wifi Viettel có thể được lắp đặt tại nhiều khu vực khác nhau.
    Mặt khác, việc lắp đặt trọn bộ wifi trong nhà và wifi ngoài trời của Viettel cũng giúp khách hàng tiết kiệm một phần chi phí. Yếu tố đồng bộ, tính thẩm mỹ cũng sẽ được đảm bảo hơn.`,
    "camera-anh-nhiet-giam-sat-toan-canh": `Camera ảnh nhiệt giám sát toàn cảnh VEE-IRST là một thiết bị giám sát sử dụng cảm biến hồng ngoại để phát hiện và ghi hình nhiệt độ của vật thể. Camera này có thể hoạt động trong điều kiện thiếu sáng hoặc không có ánh sáng, giúp người dùng giám sát hiệu quả 24/7. Camera VEE-IRST có góc nhìn rộng, cho phép nhìn toàn bộ khu vực cần giám sát chỉ với một camera. Camera VEE-IRST có thể hoạt động trong điều kiện thiếu sáng hoặc không có ánh sáng và có thể phát hiện, ghi hình nhiệt độ của vật thể, giúp dễ dàng xác định những vật thể bất thường. Thiết bị được thiết kế chống nước và bụi bẩn theo tiêu chuẩn IP66, hoạt động ổn định trong mọi môi trường, dễ dàng cài đặt và sử dụng, phù hợp với mọi nhu cầu.
    Camera ảnh nhiệt giám sát toàn cảnh VEE-IRST được sử dụng rộng rãi trong nhiều lĩnh vực khác nhau, bao gồm: giám sát an ninh, giám sát cháy nổ, giám sát môi trường, giám sát y tế.
    Camera VEE-IRST là một trong những thiết bị giám sát hiệu quả, có thể hoạt động trong mọi điều kiện và có nhiều ứng dụng.`,
    "he-thong-giam-sat-quang-dien-tu-tam-xa": `Là đài quan sát đa kênh (hồng ngoại, khả kiến) định hướng trang bị cho các tàu chiến, tàu cảnh sát biển để hỗ trợ quan sát, trinh sát và chỉ thị, cung cấp thông tin mục tiêu cho các hệ thống điều khiển hỏa lực trên tàu. Thiết bị có những tính năng nổi bật như sau: cự ly phát hiện, nhận dạng, nhận diện đối tượng tầu hộ vệ/tầu đánh cá lần lượt là: 30 km/20 km. Cự ly đo cực đại (đối tượng tầu CSB): 20 km. Khả năng quan sát ngày đêm: 2 kênh (ảnh nhiệt, ảnh ngày). Ổn định hình ảnh bằng gyro; Hệ thống ổn định 4 trục; Độ ổn định: 20 urad. Hệ số phóng đại số lớn nhất ≥ 4x. Thời gian triển khai hoặc thu hồi dưới 30 phút (với kíp 03 người thực hiện). Có khả năng kết nối với ra đa trên tàu hoặc kết nối về trung tâm chỉ huy thông qua thiết bị truyền thông tin, dữ liệu. Với những tính năng vượt trội, thiết kế nhỏ gọn, tiện lợi và hiệu suất hoạt động mạnh mẽ, hệ thống giám sát Quang điện tử tầm xa trên tàu VEE-LRS hứa hẹn sẽ trở thành một công cụ hỗ trợ đắc lực cho các hoạt động quan sát, trinh sát trong nhiều lĩnh vực khác nhau.`,
    "thiet-bi-kinh-ngam-anh-nhiet": `VEE-W01 là thiết bị kính ngắm ảnh nhiệt thế hệ mới được Viettel High Tech nghiên cứu, sản xuất và cung cấp cho thị trường. Thiết bị này được đánh giá cao bởi khả năng quan sát ban đêm hiệu quả, nhỏ gọn, dễ dàng mang theo và sử dụng trong nhiều lĩnh vực khác nhau. VEE-W01 sử dụng cảm biến hồng ngoại tiên tiến, cho phép quan sát rõ nét trong điều kiện thiếu sáng, thậm chí trong đêm tối hoàn toàn. Nhờ độ phân giải cao và khả năng dò tìm nhạy bén, VEE-W01 có thể phát hiện và xác định mục tiêu chính xác, kể cả những mục tiêu di chuyển hoặc ẩn nấp trong địa hình phức tạp. VEE-W01 không bị ảnh hưởng bởi sương mù, khói bụi, đảm bảo hoạt động hiệu quả trong mọi điều kiện thời tiết. Thiết bị có kích thước nhỏ gọn, trọng lượng nhẹ, dễ dàng mang theo và sử dụng trong mọi điều kiện. VEE-W01 được thiết kế với giao diện đơn giản, dễ dàng điều khiển và sử dụng, phù hợp với mọi đối tượng người dùng. Thiết bị được ứng dụng rộng rãi trong nhiều lĩnh vực như: quân sự, an ninh, kiểm lâm, săn bắn, cứu hộ,...Thiết bị kính ngắm ảnh nhiệt VEE-W01 với những tính năng vượt trội, thiết kế nhỏ gọn, tiện lợi và hiệu suất hoạt động mạnh mẽ, hứa hẹn sẽ trở thành một công cụ hỗ trợ đắc lực cho các hoạt động quan sát, trinh sát trong nhiều lĩnh vực khác nhau.`,
    "thiet-bi-trinh-sat-anh-nhiet": `Thiết bị trinh sát ảnh nhiệt VEE-B01 được ví như "Mắt thần" nhìn xuyên màn đêm. Là thiết bị trinh sát ảnh nhiệt thế hệ mới được Viettel High Tech nghiên cứu, sản xuất và cung cấp cho thị trường. Thiết bị này được đánh giá cao bởi khả năng quan sát ban đêm hiệu quả và tính ứng dụng đa dạng trong nhiều lĩnh vực. Điểm nổi bật của thiết bị trinh sát ảnh nhiệt VEE-B01 là khả năng quan sát ban đêm, phát hiện mục tiêu, quan sát xuyên sương mù, khói bụi, hiệu suất hoạt động mạnh mẽ, độ ổn định cao, tiêu thụ điện năng thấp. Dễ dàng điều khiển và vận hành bằng phần mềm chuyên dụng trên máy tính hoặc thiết bị di động. Truyền dữ liệu hình ảnh, video theo thời gian thực, giúp người dùng theo dõi tình hình quan sát từ xa. Có thể tích hợp với các hệ thống khác như radar, hệ thống chỉ huy điều khiển,... để nâng cao hiệu quả hoạt động.
    Thiết bị được ứng dụng rộng rãi trong các lĩnh vực như: quân sự, an ninh, kiểm lâm, giao thông, môi trường,...VEE-B01 là một sản phẩm công nghệ cao, khẳng định vị thế của Viettel High Tech trong lĩnh vực quang điện tử, góp phần nâng cao năng lực bảo vệ an ninh, quốc phòng và phát triển kinh tế - xã hội của Việt Nam.`,
    "dai-quang-dien-tu-tam-xa": `Đài quang điện tử tầm xa VEE-LRA được ví như "Mắt thần" soi sáng tầm nhìn xa,  là sản phẩm tiêu biểu của Viettel High Tech trong lĩnh vực quang điện tử, được thiết kế và sản xuất để đáp ứng nhu cầu quan sát, trinh sát tầm xa hiệu quả trong nhiều lĩnh vực khác nhau. Đài quang điện tử tầm xa VEE-LRA nổi bật với khả năng quan sát ấn tượng với bán kính hoạt động lên tới 20km (ban ngày) và 10km (ban đêm), camera hồng ngoại với độ phân giải cao, cung cấp hình ảnh chi tiết, rõ ràng, giúp người quan sát nhận diện mục tiêu dễ dàng ngay cả trong điều kiện thiếu sáng, đảm bảo hoạt động liên tục 24/7. Thiết bị có khả năng chịu được điều kiện thời tiết khắc nghiệt và hoạt động hiệu quả trong môi trường bụi bặm, sương mù, mưa,... Hệ thống chống rung tiên tiến đảm bảo hình ảnh thu được luôn ổn định, sắc nét. Không chỉ vậy, với thiết kế tối ưu giúp tiết kiệm điện năng, nâng cao hiệu quả sử dụng. Hệ thống điều khiển dễ dàng. Thiết bị truyền dữ liệu hình ảnh, video theo thời gian thực, giúp người dùng theo dõi tình hình quan sát từ xa. Hơn thế, thiết bị có thể tích hợp với các hệ thống khác như radar, hệ thống chỉ huy điều khiển,... Đài quang điện tử tầm xa VEE-LRA với những tính năng vượt trội, hiệu suất hoạt động mạnh mẽ và tính ứng dụng đa dạng, góp phần mang lại giải pháp quan sát, trinh sát hiệu quả, phục vụ cho công tác bảo vệ an ninh, quốc phòng và phát triển kinh tế - xã hội.`,
    "may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat": `UAV2-1.5 là một sản phẩm UAV chiến đấu cấp chiến thuật do Viện Nghiên cứu và Phát triển Công nghệ Quân sự (VIQTECH) thuộc Tập đoàn Viettel nghiên cứu, sản xuất và cung cấp cho Quân đội Nhân dân Việt Nam. Máy bay này được đánh giá cao bởi khả năng tác chiến hiệu quả và tính ứng dụng đa dạng trong các nhiệm vụ quân sự. UAV2-1.5 sở hữu thiết kế hiện đại, mang tính đột phá, được trang bị nhiều tính năng tiên tiến như: khả năng tàng hình, khả năng bay tự động, tải trọng vũ khí đa dạng, hệ thống truyền dữ liệu hiện đại. UAV2-1.5 được sử dụng hiệu quả trong nhiều nhiệm vụ quân sự như: do thám, trinh sát, tấn công mục tiêu, hỗ trợ tác chiến, khả năng chống nhiễu cao. UAV2-1.5 là một sản phẩm UAV chiến đấu cấp chiến thuật nội địa với nhiều ưu điểm nổi bật, thể hiện trình độ nghiên cứu, sản xuất vũ khí trang bị tiên tiến của Việt Nam.`,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16": `UAV1-6 là một sản phẩm UAV trinh sát hạng nhẹ tầm gần do Công ty cổ phần Công nghệ Viễn thông Viettel (Viettel Tech) nghiên cứu, sản xuất và cung cấp cho thị trường. Máy bay này được đánh giá cao bởi hiệu suất hoạt động ấn tượng và tính ứng dụng đa dạng trong nhiều lĩnh vực. Thiết bị có những đặc điểm nổi bật như: thiết kế nhỏ gọn, linh hoạt, trọng lượng chỉ 1,5kg dễ dàng mang vác, triển khai ở mọi địa hình, đặc biệt là những khu vực hiểm trở, khó tiếp cận. Máy bay có thể bay liên tục 60 phút với vận tốc lên tới 80km/h, bán kính hoạt động 10km, đáp ứng nhu cầu trinh sát tầm gần hiệu quả. Bên cạnh đó, UAV1-6 có thể hoạt động trong điều kiện gió cấp 4, đảm bảo hoạt động bay an toàn, ổn định. Máy bay được trang bị camera độ phân giải cao, hệ thống định vị GPS, hệ thống truyền dữ liệu,... đáp ứng nhu cầu thu thập thông tin, hình ảnh chi tiết. Nhờ hệ thống điều khiển thông minh, UAV1-6 được điều khiển bằng phần mềm chuyên dụng trên máy tính bảng hoặc điện thoại thông minh, dễ dàng sử dụng và thao tác. Từ những tính năng trên có thể thấy, UAV1-6 được sử dụng hiệu quả trong nhiều lĩnh vực như: quân sự, nông nghiệp, môi trường, Khảo cổ học, tìm kiếm cứu nạn,.... UAV1-6 là một sản phẩm UAV nội địa với nhiều ưu điểm nổi bật, góp phần đáp ứng nhu cầu ngày càng cao về các giải pháp công nghệ tiên tiến trong nhiều lĩnh vực.`,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g": `VUA-SC-3G là niềm tự hào của ngành công nghiệp công nghệ cao Việt Nam, được Tập đoàn Viettel nghiên cứu, sản xuất và cung cấp cho thị trường. Máy bay không người lái (UAV) trinh sát hạng nhẹ tầm gần này đóng vai trò quan trọng trong nhiều lĩnh vực, đặc biệt là quốc phòng, an ninh và nông nghiệp. VUA-SC-3G sử dụng động cơ đốt trong và có 2 phương thức cất cánh. Với điều kiện thuận lợi có đường thẳng thì máy bay có thể cất cánh tại đường băng hoặc sử dụng bệ phóng với điều kiện ở những khu vực khác. Sải cánh máy bay đạt 3,2m và chiều dài là 2,2m, máy bay có thể bay cao tối đa là 3.000m (3km) và cự ly trong bán kính 50km, khối lượng cất cánh 26kg và thời gian bay liên tục đạt 3 tiếng trong dải vận tốc 70-120km/h. Trang bị hệ thống Camera độ phân giải HD cả ban ngày lẫn ban đêm, tín hiệu được gửi về phòng điều khiển trực tiếp, ngoài ra còn có cảm biến đo nhiệt độ, hệ thống đo khoảng cách bằng laser. VUA-SC-3G sử dụng động cơ đốt trong với cánh quạt đẩy là truyền lực chính, khi đạt độ cao từ 150m trở lên với tầm nhìn hạn chế, máy bay có thể bay tự động với lộ trình được tạo sẵn mà không cần người điều khiển trực tiếp, hoặc trong trường hợp mất tín hiệu thì máy bay cũng có thể đủ thông minh để vẽ được bản đồ bay trở về vị trí xuất phát. VUA-SC-3G là một UAV nội địa với nhiều ưu điểm nổi bật, góp phần đáp ứng nhu cầu ngày càng cao về các giải pháp công nghệ tiên tiến.`,
    "thiet-bi-truyen-dan-vo-tuyen-toc-do-cao": `Thiết bị truyền dẫn vô tuyến tốc độ cao VHCR là hệ thống truyền dẫn dữ liệu hiện đại do Tập đoàn Viettel nghiên cứu và sản xuất, đáp ứng nhu cầu truyền tải thông tin dung lượng lớn trong các mạng viễn thông. Hệ thống được trang bị nhiều công nghệ tiên tiến, mang lại hiệu quả hoạt động cao và khả năng thích ứng với môi trường vận hành phức tạp.
    VHCR có khả năng truyền dẫn dữ liệu với tốc độ lên đến 10 Gbps, đáp ứng nhu cầu truyền tải video HD, truyền hình trực tiếp, truy cập internet tốc độ cao,... Hệ thống được trang bị các công nghệ chống nhiễu tiên tiến, giúp hoạt động hiệu quả trong môi trường nhiễu điện tử phức tạp, khu vực hẻo lánh, nơi mà việc triển khai cáp quang gặp nhiều khó khăn. VHCR có cấu trúc dạng mô-đun, dễ dàng tháo lắp và vận chuyển, cho phép triển khai nhanh chóng trên nhiều địa hình khác nhau. Đặc biệt, VHCR có thể tích hợp với các hệ thống truyền dẫn khác để tạo thành mạng lưới truyền dẫn thông tin thống nhất, hiệu quả.
    VHCR là một hệ thống truyền dẫn vô tuyến tốc độ cao hiện đại, đóng vai trò quan trọng trong việc nâng cao năng lực truyền tải thông tin của Việt Nam. Hệ thống thể hiện trình độ công nghệ cao của ngành công nghiệp quốc phòng Việt Nam và góp phần khẳng định vị thế của Việt Nam trên trường quốc tế.`,
    "may-thu-phat-vo-tuyen-dien-ca-nhan": `Máy thu phát vô tuyến điện cá nhân VRH-911 là thiết bị liên lạc di động được sử dụng rộng rãi trong các lực lượng chức năng và doanh nghiệp. Máy có thiết kế nhỏ gọn, dễ dàng sử dụng và mang theo, phù hợp cho các hoạt động liên lạc trong phạm vi ngắn.
    VRH-911 cho phép liên lạc thoại tin cậy và an toàn trong phạm vi 1 - 3 km (tùy thuộc vào địa hình và điều kiện môi trường). Máy hỗ trợ mã hóa tín hiệu để đảm bảo an ninh thông tin liên lạc. Công suất phát 2W giúp máy hoạt động hiệu quả trong môi trường nhiễu cao. Máy có thiết kế nhỏ gọn, trọng lượng nhẹ, dễ dàng di chuyển và sử dụng. VRH-911 được thiết kế để hoạt động trong môi trường khắc nghiệt, chống va đập và bụi bẩn. Máy có thể hoạt động liên tục trong 12 giờ sau mỗi lần sạc đầy.
    VRH-911 được sử dụng trong các lực lượng chức năng như công an, quân đội, biên phòng, cảnh sát phòng cháy chữa cháy. Máy cũng được sử dụng trong các doanh nghiệp như bảo vệ, dịch vụ vận tải, du lịch.`,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay": `Máy thu phát vô tuyến điện sóng cực ngắn cầm tay VRH-811/S là thiết bị liên lạc chiến thuật được sử dụng rộng rãi trong Quân đội Nhân dân Việt Nam, phục vụ cấp chiến thuật; Khả năng liên lạc thoại, truyền dữ liệu, mã hóa bảo mật, chống tác chiến điện tử; Được thiết kế nhỏ gọn, định hướng trang bị cho lực lượng lục quân (cấp trung đội trưởng, trinh sát, đặc công) oạt động tác chiến trong điều kiện địa hình phức tạp và môi trường nhiễu cao.
    VRH-811/S cho phép liên lạc thoại tin cậy và an toàn trong phạm vi 3 - 10 km (tùy thuộc vào địa hình và điều kiện môi trường). Máy hỗ trợ mã hóa tín hiệu để đảm bảo an ninh thông tin liên lạc.  Công suất phát 5W giúp máy hoạt động hiệu quả trong môi trường nhiễu cao. Kích thước nhỏ gọn: Máy có thiết kế nhỏ gọn, trọng lượng nhẹ, dễ dàng di chuyển và sử dụng. VRH-811/S được thiết kế để hoạt động trong môi trường khắc nghiệt, chống va đập và bụi bẩn.`,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang": `Máy thu phát vô tuyến điện sóng cực ngắn nhẩy tần công suất 50W (VRU-812/S) và Bộ thông thoại số cho xe tăng là hai thiết bị quan trọng được sử dụng trong Quân đội Nhân dân Việt Nam, góp phần nâng cao khả năng liên lạc và phối hợp tác chiến trong các điều kiện phức tạp.
    Máy thu phát vô tuyến điện sóng cực ngắn nhẩy tần công suất 50W (VRU-812/S) sử dụng công nghệ SDR, phục vụ cấp chiến thuật; có khả năng liên lạc thoại, truyền dữ liệu, mã hóa bảo mật, chống tác chiến điện tử; định hướng trang bị cho lục quân đến cấp Lữ đoàn, Sư đoàn bộ binh, Tăng thiết giáp.
    VRU-812/S cho phép liên lạc thoại tin cậy và an toàn trong phạm vi 6 - 20 km (tùy thuộc vào địa hình và điều kiện môi trường). Máy sử dụng công nghệ nhảy tần tiên tiến để chống nhiễu và bảo mật thông tin liên lạc. VRU-812/S hỗ trợ mã hóa tín hiệu để đảm bảo an ninh thông tin.
    Máy có thiết kế nhỏ gọn, dễ dàng di chuyển và sử dụng. Đặc biệt với công suất phát 50W giúp máy hoạt động hiệu quả trong môi trường nhiễu cao.
    2. Bộ thông thoại số cho xe tăng được sử dụng trên các xe tăng của Quân đội Nhân dân Việt Nam. Thiết bị giúp nâng cao hiệu quả phối hợp tác chiến giữa các thành viên trong kíp xe tăng. Cụ thể như sau:  Bộ thông thoại số cho phép liên lạc nội bộ giữa các thành viên trong kíp xe tăng, kết nối với hệ thống thông tin của xe tăng để truyền tải dữ liệu và hình ảnh, âm thanh rõ ràng, giúp cho việc liên lạc hiệu quả trong môi trường tiếng ồn lớn. Bộ thông thoại được thiết kế để hoạt động trong môi trường khắc nghiệt.`,
    "he-thong-rada-thu-dong": `Hệ thống ra đa thụ động V-ELINT 18 là hệ thống do Tập đoàn Viettel nghiên cứu và sản xuất, có khả năng phát hiện và định vị chính xác các nguồn phát sóng radar trong dải tần số rộng, bao gồm cả radar cảnh giới, radar điều khiển hỏa lực, radar trinh sát,...Phân loại các loại radar khác nhau dựa trên đặc điểm tín hiệu của chúng. Xác định vị trí của các mục tiêu đang phát sóng radar, từ đó hỗ trợ cho các hệ thống vũ khí tấn công hiệu quả. Hệ thống được trang bị các công nghệ chống nhiễu tiên tiến, giúp hoạt động hiệu quả trong môi trường nhiễu điện tử phức tạp. Đặc biệt, V-ELINT 18 có cấu trúc dạng mô-đun, dễ dàng tháo lắp và vận chuyển, cho phép triển khai nhanh chóng trên nhiều địa hình khác nhau và hoạt động trong điều kiện thời tiết khắc nghiệt
    V-ELINT 18 là một hệ thống ra đa thụ động hiện đại, đóng vai trò quan trọng trong việc nâng cao năng lực trinh sát điện tử của Quân đội Nhân dân Việt Nam. Hệ thống thể hiện trình độ công nghệ cao của ngành công nghiệp quốc phòng Việt Nam và góp phần khẳng định vị thế của Việt Nam trên trường quốc tế.`,
    "rada-canh-gioi-bien": `Đài ra đa cảnh giới biển tầm gần phân giải cao VRS-SRX là hệ thống ra đa hiện đại do Tập đoàn Viettel nghiên cứu và sản xuất, đáp ứng nhu cầu bảo vệ bờ biển Việt Nam trong thời đại mới. Hệ thống do Tập đoàn Viettel nghiên cứu và sản xuất, thể hiện trình độ công nghệ cao của ngành công nghiệp quốc phòng Việt Nam và góp phần khẳng định vị thế của Việt Nam trên trường quốc tế.
    Đặc biệt, VRS-SRX có khả năng phát hiện và định vị chính xác các mục tiêu trên biển như tàu thuyền, xuồng cao tốc, người bơi,... ở cự ly lên đến 45 km. Phân biệt các mục tiêu có kích thước nhỏ, giúp tăng cường hiệu quả giám sát và trinh sát. VRS-SRX được trang bị các công nghệ chống nhiễu tiên tiến, giúp hệ thống hoạt động hiệu quả trong môi trường nhiễu điện tử phức tạp.
    Ngoài ra, hệ thống có cấu trúc dạng mô-đun, dễ dàng tháo lắp và vận chuyển, cho phép triển khai nhanh chóng trên nhiều địa hình khác nhau và có thể tích hợp với các hệ thống trinh sát khác để tạo thành mạng lưới giám sát biển thống nhất, hiệu quả.
    Đài ra đa cảnh giới biển tầm gần phân giải cao VRS-SRX lđược sử dụng để cảnh giới và bảo vệ bờ biển Việt Nam khỏi các hoạt động xâm phạm trái phép, buôn lậu, ma túy,... giám sát và trinh sát các hoạt động trên biển trong khu vực, sử dụng để kiểm soát giao thông hàng hải, đảm bảo an toàn cho các hoạt động di chuyển trên biển.`,
    "rada-chien-thuat": `Đài ra đa 3D chiến thuật băng S (VRS-SRS) là một hệ thống ra đa 3D chiến thuật hiện đại, do Tập đoàn Viettel nghiên cứu và sản xuất, đóng vai trò quan trọng trong việc nâng cao năng lực tác chiến phòng không của Quân đội Nhân dân Việt Nam. Hệ thống thể hiện trình độ công nghệ cao của ngành công nghiệp quốc phòng Việt Nam và góp phần khẳng định vị thế của Việt Nam trên trường quốc tế.
    Đài ra đa 3D chiến thuật băng S (VRS-SRS) có các tính năng chính như sau :
    Phát hiện và định vị mục tiêu 3D: VRS-SRS có khả năng phát hiện và định vị chính xác các mục tiêu bay ở cự ly lên đến 120 km, độ cao dưới 10 km. Hệ thống có thể theo dõi đồng thời nhiều mục tiêu, bao gồm máy bay chiến đấu, máy bay trực thăng, UAV, tên lửa hành trình,...
    Khả năng chống nhiễu cao: VRS-SRS được trang bị các công nghệ chống nhiễu tiên tiến, giúp hệ thống hoạt động hiệu quả trong môi trường nhiễu điện tử phức tạp.
    Tính cơ động cao: Hệ thống có cấu trúc dạng mô-đun, dễ dàng tháo lắp và vận chuyển, cho phép triển khai nhanh chóng trên nhiều địa hình khác nhau.
    Khả năng tích hợp cao: VRS-SRS có thể tích hợp với các hệ thống phòng thủ khác để tạo thành mạng lưới phòng thủ phòng không thống nhất, hiệu quả.
    Đài ra đa 3D chiến thuật băng S (VRS-SRS) được sử dụng để phát hiện, theo dõi và tiêu diệt các mục tiêu bay trong khu vực tác chiến. Cảnh giới và bảo vệ các mục tiêu quan trọng như sân bay, căn cứ quân sự, khu công nghiệp,... Giám sát và trinh sát các mục tiêu trên không trong khu vực.`,
    "rada-tam-trung": `Đài ra đa 3D cảnh giới tầm trung băng S (VRS-MRS) là hệ thống ra đa hiện đại do Tập đoàn Viettel nghiên cứu và sản xuất, đáp ứng nhu cầu bảo vệ bầu trời Việt Nam trong thời đại mới. Hệ thống được trang bị nhiều công nghệ tiên tiến, mang lại hiệu quả hoạt động cao và khả năng thích ứng với môi trường tác chiến phức tạp.
    Đài ra đa 3D cảnh giới tầm trung băng S (VRS-MRS) có nhiều tính năng trong đó nổi bật là:
    Phát hiện và định vị mục tiêu 3D: VRS-MRS có khả năng phát hiện và định vị chính xác các mục tiêu bay ở cự ly lên đến 360 km, độ cao dưới 25 km. Hệ thống có thể theo dõi đồng thời nhiều mục tiêu, bao gồm máy bay dân dụng, máy bay chiến đấu, máy bay trực thăng, UAV, tên lửa hành trình,...
    Khả năng chống nhiễu cao: VRS-MRS được trang bị các công nghệ chống nhiễu tiên tiến, giúp hệ thống hoạt động hiệu quả trong môi trường nhiễu điện tử phức tạp.
    Tính cơ động cao: Hệ thống có cấu trúc dạng mô-đun, dễ dàng tháo lắp và vận chuyển, cho phép triển khai nhanh chóng trên nhiều địa hình khác nhau.
    Khả năng tích hợp cao: VRS-MRS có thể tích hợp với các hệ thống phòng thủ khác để tạo thành mạng lưới phòng thủ phòng không thống nhất, hiệu quả.
    Đài ra đa 3D cảnh giới tầm trung băng S (VRS-MRS) được sử dụng trong các lĩnh vực như:
    Cảnh giới và bảo vệ bầu trời: VRS-MRS được sử dụng để cảnh giới và bảo vệ bầu trời Việt Nam khỏi các mối đe dọa từ máy bay, UAV, tên lửa hành trình,...
    Kiểm soát giao thông hàng không: Hệ thống có thể được sử dụng để kiểm soát giao thông hàng không, đảm bảo an toàn cho các hoạt động bay.
    Giám sát và trinh sát: VRS-MRS có thể được sử dụng để giám sát và trinh sát các mục tiêu trên không trong khu vực.
    VRS-MRS là một hệ thống ra đa 3D tầm trung hiện đại, đóng vai trò quan trọng trong việc bảo vệ bầu trời Việt Nam. Hệ thống thể hiện trình độ công nghệ cao của ngành công nghiệp quốc phòng Việt Nam và góp phần khẳng định vị thế của Việt Nam trên trường quốc tế.`,  
}
export const descriptionDetail1704 = {
    "5g-micro": ``,
    "5g-macrocell": ``,
    "5g-massive-mimo": ``,
    "site-router-100g": ``,
    "chip-5g-dfe": ``,
    "thiet-bi-ai-home-hub": ``,
    "camera-giao-thong": ``,
    "camera-thong-minh-toa-nha": ``,
    "camera-thong-minh-ho-gia-dinh": ``,
    "camera-anh-nhiet-giam-sat-toan-canh": ``,
    "he-thong-giam-sat-quang-dien-tu-tam-xa": ``,
    "thiet-bi-kinh-ngam-anh-nhiet": ``,
    "thiet-bi-trinh-sat-anh-nhiet": ``,
    "dai-quang-dien-tu-tam-xa": ``,
    "may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat": ``,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16": ``,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g": ``,
    "thiet-bi-truyen-dan-vo-tuyen-toc-do-cao": ``,
    "may-thu-phat-vo-tuyen-dien-ca-nhan": ``,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay": ``,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang": ``,
    "he-thong-rada-thu-dong": ``,
    "rada-canh-gioi-bien": ``,
    "rada-chien-thuat": ``,
    "rada-tam-trung": ``, 
}