export const descriptionGeneral1704Eng = {
    "5g-micro": `5G Micro 8T8R is a 5G access solution provided by Viettel, using MIMO (Multiple-Input Multiple-Output) technology with 8 transmitting antennas and 8 receiving antennas, designed specifically for narrow areas and high user density. 5G Micro 8T8R possesses outstanding advantages over traditional 5G access solutions such as: 5G Micro 8T8R can support multiple users to access at the same time, meeting the needs of high-speed data access in narrow areas. This solution helps increase network capacity by 4 times compared to 4G LTE solutions, ensuring a smooth experience for users when using 5G services such as watching VR / AR videos, playing online games, downloading high-speed data. 5G Micro 8T8R can provide 5G access speeds of up to 1.5 Gbps, which is much higher than 4G LTE access speeds. This solution helps users access the internet quickly, download data in a snap, and enjoy the full 5G experience. 5G Micro 8T8R can broadcast 5G farther and penetrate obstacles better than 4G LTE solutions. This solution helps extend 5G coverage to narrow areas, inside buildings, ensuring users everywhere can access high-speed internet. 5G Micro 8T8R is compact in size, easy to install and deploy in narrow areas. This solution can be installed on poles, walls, roofs, ... helps save space and ensure aesthetics. 5G Micro 8T8R uses advanced MIMO technology to optimize transmission performance, which saves energy and reduces network operating costs. This solution contributes to environmental protection and improves energy efficiency for operators. 5G Micro 8T8R is a flexible and effective 5G access solution for narrow areas, helping Viettel bring high-quality 5G services to users nationwide. This solution makes an important contribution to the development of 5G networks in Vietnam, contributing to promoting Industry 4.0 and bringing Vietnam deeply integrated into the industrial revolution 4.0.`,
    "5g-macrocell": `5G Macrocell 8T8R is a 5G access solution provided by Viettel, using MIMO (Multiple-Input Multiple-Output) technology with 8 transmitting antennas and 8 receiving antennas, bringing outstanding advantages compared to traditional 5G access solutions. 5G Macrocell 8T8R can support multiple users to access at the same time, meeting the increasing demand for high-speed data access in 5G networks. This solution helps increase network capacity by 4 times compared to 4G LTE solutions, ensuring a smooth experience for users when using 5G services such as watching VR / AR videos, playing online games, downloading high-speed data. The 5G Macrocell 8T8R can provide 5G access speeds of up to 1.5 Gbps, which is much higher than 4G LTE access speeds. This solution helps users access the internet quickly, download data in a snap, and enjoy the full 5G experience. Not only objects, devices can broadcast 5G farther and penetrate obstacles better than 4G LTE solutions. This solution helps extend 5G coverage to remote areas, ensuring users everywhere can access high-speed internet. In particular, 5G Macrocell 8T8R uses advanced MIMO technology to optimize transmission performance, which saves energy and reduces network operating costs. This solution contributes to environmental protection and improves energy efficiency for operators. Currently, 5G Macrocell 8T8R has been deployed by Viettel in many provinces and cities across the country, bringing a great 5G experience to users.`,
    "5g-massive-mimo": `5G Massive MIMO 32T32R is an advanced 5G access solution provided by Viettel, using MIMO (Multiple-Input Multiple-Output) technology with 32 transmitting antennas and 32 receiving antennas, bringing outstanding advantages compared to traditional 5G access solutions. 5G Massive MIMO 32T32R can support multiple users to access at the same time, meeting the increasing demand for high-speed data access in 5G networks. This solution helps increase network capacity by 8 times compared to 4G LTE solutions, ensuring a smooth experience for users when using 5G services such as watching VR / AR videos, playing online games, downloading high-speed data. 5G Massive MIMO 32T32R can provide 5G access speeds of up to 2.5 Gbps, which is much higher than 4G LTE access speeds. This solution helps users access the internet quickly, download data in a snap, and enjoy the full 5G experience. The device can broadcast 5G farther and penetrate obstacles better than 4G LTE solutions. This solution helps extend 5G coverage to remote areas, ensuring users everywhere can access high-speed internet. 5G Massive MIMO 32T32R uses advanced MIMO technology to optimize transmission performance, which saves energy and reduces network operating costs. This solution contributes to environmental protection and improves energy efficiency for operators. 5G Massive MIMO 32T32R is a breakthrough 5G access solution, helping Viettel provide high-quality 5G services to users nationwide. This solution makes an important contribution to the development of 5G networks in Vietnam, contributing to promoting Industry 4.0 and bringing Vietnam deeply integrated into the industrial revolution 4.0.`,
    "site-router-100g": `Site Router 100G Viettel is a device specially designed for 5G networks, applying the most advanced technology at the access layer of the metro network, meeting the increasing demand for 5G services. The device possesses many outstanding features, helping to improve network operation efficiency and bring the best 5G experience to users. Viettel 100G Site Router is capable of handling high traffic, up to 100 Gbps, meeting the high-speed data access needs of 5G users. The device supports a variety of transmission protocols such as GPON, XGPON, TWDM PON, helping operators flexibly deploy 5G networks on different fiber optic infrastructures. The device is designed with high durability, can operate in harsh environments and is integrated with many redundant features, ensuring 24/7 continuous operation of the network, without interruption of service. Viettel 100G Site Router can easily expand capacity when demand increases, supports many different function modules, giving operators flexibility to meet the specific requirements of each region. Viettel 100G Site Router is integrated with a smart management system, making it easy for network operators to monitor and manage devices remotely. The management system provides many features such as: performance monitoring, crash alerts, automatic configuration adjustment, ... In particular, Viettel Site Router 100G is integrated with advanced security features, helping to protect the network from cyberattacks. Viettel 100G Site Router is the optimal solution for 5G network infrastructure, helping operators provide high-quality 5G services, meeting the increasing needs of users. Currently, Viettel 100G Site Router has been deployed in many provinces and cities across the country, contributing to improving the quality of 5G services for Vietnamese people.`,
    "chip-5g-dfe": `5G DFE chip is a product successfully researched and developed by Viettel engineers, marking an important milestone in Vietnam's journey to master telecommunications technology. The chip is designed with many preeminent features, meeting the increasing needs of the 5G market: The 5G DFE chip is capable of calculating 1,000 billion calculations per second, equivalent to 5G chips of the world's leading manufacturers such as Qualcomm, Huawei, Samsung. As a result, the chip can effectively handle high-performance tasks in 5G networks such as high-speed data transmission, supporting multiple users at the same time, meeting the demand for advanced 5G services such as VR / AR, self-driving cars, .. The 5G DFE chip is designed with an advanced architecture, which saves energy compared to other 5G chips. The energy saving contributes to reducing the cost of operating 5G networks and protecting the environment. The 5G DFE chip is compact in size, which saves installation area and optimizes the design of 5G network devices. Besides, DFE 5G chip is designed according to international standards, compatible with 5G network devices of different suppliers. This makes it easy for operators to deploy and upgrade 5G networks, while reducing investment costs. In addition, the 5G DFE chip is packed with advanced security features, helping to protect data and telecommunications networks from cyberattacks. The introduction of DFE 5G Chip is a testament to Viettel's research and development capacity, affirming Vietnam's position on the world telecommunications technology map. DFE 5G chip will contribute to promoting the development of 5G networks in Vietnam, bringing people and businesses completely new telecommunications experiences.`,
    "thiet-bi-ai-home-hub": `Viettel AI Home Hub is a smart assistant device for homes, integrated with many modern features, providing a comfortable and safe experience for users. The device uses advanced artificial intelligence (AI) technology, allowing users to control electronic devices in the home with voice, interact with diverse entertainment services and quickly access information. Viettel AI Home Hub stands out with features such as: voice commands to control electronic devices in the house such as televisions, air conditioners, fans, lights, ... Create intelligent context to activate multiple devices at the same time with a single command. Timer to turn on/off devices automatically. Answer questions in Vietnamese intelligently and accurately. Update news, weather, traffic. Support booking appointments, remembering, creating shopping lists. Entertain with music, movies, audio stories. Voice commands to make phone calls, text relatives, friends. Set up an SOS emergency call. Connect to Viettel camera to monitor live camera images. Get notified when there's strange movement in your home. In particular, the device helps support learning for children with English lessons, math, ... Viettel AI Home Hub is an indispensable smart device for the modern home, bringing you and your family a more comfortable, safe and interesting life.`,
    "camera-giao-thong": `Viettel provides a smart traffic camera system, applying advanced AI technology, contributing to improving traffic management efficiency, ensuring safety for road users. Viettel traffic camera system helps to monitor traffic in real time, detect traffic violations automatically, count the number of vehicles, support traffic accident investigation, especially smart traffic management to help adjust traffic flexibly, reduce congestion, save travel time for people. Using Viettel traffic camera contributes to improving traffic management efficiency, ensuring safety for road users. Minimize traffic jams, save travel time. Detect traffic violations effectively, handle violations quickly and accurately. Collect valuable traffic data, serve the planning and development of transport infrastructure.`,
    "camera-thong-minh-toa-nha": `Viettel provides smart camera solutions for comprehensive buildings, helping to improve security and management efficiency for businesses. Viettel's camera system possesses many preeminent features, meeting the diverse needs of customers such as: Viettel camera uses advanced image technology, gives sharp, high-resolution images, helps to observe in detail every corner of the building, supports infrared night vision cameras, Ensure clear images even in low light conditions. With smart features, the camera helps to recognize faces, identify and track objects entering and leaving the building, supporting effective security control. Detects abnormal, suspicious behaviors, helps to promptly prevent intrusion and theft. Monitor the number of people entering and leaving the building, help manage movement and adjust security activities accordingly. Automatically detect and alarm when an abnormal event occurs, helping security personnel react quickly. Image and video data is stored on Viettel's secure cloud system, ensuring security and easy remote access. Viettel camera system is managed through specialized software, making it easy for users to monitor live camera images, review videos, manage smart features and system settings. Support remote access via smartphones, tablets, helping users monitor the camera anytime, anywhere. Viettel provides a package of smart camera solutions, including camera equipment, management software, installation and maintenance services, helping customers save time and costs. Viettel's professional technical staff is always ready to support customers to consult, answer questions and troubleshoot problems 24/7.`,
    "camera-thong-minh-ho-gia-dinh": `Home Camera Viettel is a smart camera solution with many modern features, meeting the needs of monitoring, monitoring the security situation, protecting assets or simply talking directly with family members. Viettel's camera line includes 2 products: Indoor 360-degree camera and outdoor camera. Each type of camera will have the function of monitoring and protection within its installed range. You can completely choose 1 of these 2 devices, however, Viettel encourages customers to use the full set of Wifi cameras for the following reasons: Users can easily install, manage and monitor these 2 camera devices via the VHome application released by Viettel. In particular, these devices are very easy to connect and have a connection with each other to draw accurate conclusions in bad situations. Viettel camera is equipped with smart features such as: AI technology detects and distinguishes the movement of people / objects; Can record images in color even at night; In particular, the 2-way live conversation feature makes it convenient for monitoring... Outdoor CCTV equipment can be installed in outside spaces, with dustproof, anti-bacterial and waterproof features, very wide visibility. Indoor cameras are installed in living rooms, kitchens, bedrooms, storage rooms or installed in the yard ... Thus, the entire set of Viettel Wifi Cameras can be installed in many different areas. On the other hand, the installation of Viettel's indoor wifi and outdoor wifi also helps customers save part of costs. Synchronous factors, aesthetics will also be more guaranteed.`,
    "camera-anh-nhiet-giam-sat-toan-canh": `The VEE-IRST is a surveillance device that uses an infrared sensor to detect and record an object's temperature. This camera can operate in low light or no light conditions, giving users effective monitoring 24/7. The VEE-IRST camera has a wide viewing angle, allowing to see the entire area to be monitored with just one camera. The VEE-IRST camera can operate in low light or no light conditions and can detect and record the temperature of objects, making it easy to identify abnormal objects. The device is designed to be waterproof and dirtproof according to IP66 standard, stable operation in any environment, easy to install and use, suitable for all needs. VEE-IRST panoramic surveillance thermal imaging camera is widely used in many different fields, including: security monitoring, fire monitoring, environmental monitoring, medical surveillance. The VEE-IRST camera is one of the effective surveillance devices, which can work in any condition and has a wide range of applications.`,
    "he-thong-giam-sat-quang-dien-tu-tam-xa": `It is a multi-channel observatory (infrared, visible) oriented to equip warships and coast guard ships to support observation, reconnaissance and directive, providing targeting information for onboard fire control systems. The device has the following outstanding features: the detection, identification and identification distance of corvettes/fishing boats is: 30 km / 20 km respectively. Maximum measuring distance (CSB subject): 20 km. Day and night observability: 2 channels (thermal photo, day photo). Image stabilization using gyro; 4-axis stabilization system; Stability: 20 urads. The largest numerical magnification factor ≥ 4x. Deployment or withdrawal time is less than 30 minutes (with a crew of 03 people). Ability to connect to onboard radar or connect to the command center through information and data transmission equipment. With outstanding features, compact design, convenience and powerful performance, the long-range Optoelectronic surveillance system on board VEE-LRS promises to become an effective support tool for observation and reconnaissance activities in many different fields.`,
    "thiet-bi-kinh-ngam-anh-nhiet": `VEE-W01 is a new generation thermal imaging viewfinder device researched, manufactured and supplied to the market by Viettel High Tech. This device is highly appreciated for its effective, compact, easy to carry and use night vision in many different fields. The VEE-W01 uses an advanced infrared sensor, allowing clear viewing in low light conditions, even in complete darkness. Thanks to its high resolution and keen detection capabilities, the VEE-W01 can accurately detect and identify targets, including those moving or hiding in complex terrain. VEE-W01 is not affected by fog, dust, ensuring effective operation in all weather conditions. The device is compact in size, light weight, easy to carry and use in any conditions. VEE-W01 is designed with a simple interface, easy to control and use, suitable for all types of users. The device is widely applied in many fields such as military, security, forest rangers, hunting, rescue ,... The VEE-W01 thermal imaging viewfinder with outstanding features, compact design, convenience and powerful performance, promises to become an effective support tool for observation and reconnaissance activities in many different fields.`,
    "thiet-bi-trinh-sat-anh-nhiet": `The VEE-B01 thermal imaging reconnaissance device is likened to the "Magic Eye" that sees through the night. It is a new generation thermal image reconnaissance device researched, manufactured and supplied to the market by Viettel High Tech. This device is highly appreciated for its effective night vision and diverse applications in many fields. The highlights of the VEE-B01 thermal imaging reconnaissance device are night vision, target detection, fog and dust observation, powerful performance, high stability, low power consumption. Easy to control and operate by specialized software on computer or mobile device. Transmit image and video data in real time, helping users monitor the observation situation remotely. Can be integrated with other systems such as radar, command and control system,... to improve operational efficiency. The device is widely applied in fields such as military, security, forest rangers, transportation, environment,... VEE-B01 is a high-tech product, affirming Viettel High Tech's position in the field of optoelectronics, contributing to improving the capacity to protect security, defense and socio-economic development of Vietnam.`,
    "dai-quang-dien-tu-tam-xa": `VEE-LRA long-range optoelectronic observatory, which is likened to the "Magic Eye" that illuminates long vision, is a typical product of Viettel High Tech in the field of optoelectronics, designed and manufactured to meet the needs of effective long-range observation and reconnaissance in many different fields. The VEE-LRA long-range optoelectronic station stands out with impressive visibility with an operating radius of up to 20km (daytime) and 10km (night), high-resolution infrared camera, providing detailed, clear images, helping observers identify targets easily even in low light conditions, ensure continuous operation 24/7. The device is able to withstand harsh weather conditions and works effectively in dusty, foggy, rainy environments,... The advanced anti-shake system ensures that the resulting image is always stable and sharp. Not only that, with optimal design to save energy, improve efficiency. Easy control system. The device transmits image and video data in real time, helping users monitor the observation situation remotely. Moreover, the device can be integrated with other systems such as radar, command and control system,... VEE-LRA long-range optoelectronic station with outstanding features, strong performance and diverse applications, contributes to providing effective observation and reconnaissance solutions, serving the protection of security, defense and socio-economic development.`,
    "may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat": `UAV2-1.5 is a tactical combat UAV product researched, manufactured and supplied by the Military Technology Research and Development Institute (VIQTECH) of Viettel Group to the Vietnam People's Army. This aircraft is highly appreciated for its effective combat capabilities and diverse applications in military missions. UAV2-1.5 possesses a modern, breakthrough design, equipped with many advanced features such as stealth, automatic flight capability, diverse weapon loads, modern data transmission system. UAV2-1.5 is effectively used in many military tasks such as reconnaissance, reconnaissance, target attack, combat support, high anti-jamming ability. UAV2-1.5 is a domestic tactical combat UAV product with many outstanding advantages, demonstrating Vietnam's level of research and production of advanced weapons.`,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16": `UAV1-6 is a short-range light reconnaissance UAV product researched, manufactured and supplied to the market by Viettel Telecommunications Technology Joint Stock Company (Viettel Tech). This aircraft is highly appreciated for its impressive performance and diverse applications in many fields. The device has outstanding features such as: compact and flexible design, weighing only 1.5kg, easy to carry, deploy in all terrains, especially difficult and difficult to access areas. The aircraft can fly continuously for 60 minutes at a speed of up to 80km / h, an operating radius of 10km, meeting the needs of effective close-range reconnaissance. In addition, UAV1-6 can operate in level 4 wind conditions, ensuring safe and stable flight operations. The aircraft is equipped with high-resolution cameras, GPS navigation system, data transmission system,... meet the needs of collecting detailed information and images. Thanks to the intelligent control system, the UAV1-6 is controlled by specialized software on a tablet or smartphone, easy to use and manipulate. From the above features, it can be seen that UAV1-6 is effectively used in many fields such as military, agriculture, environment, archaeology, search and rescue ,.... UAV1-6 is a domestic UAV product with many outstanding advantages, contributing to meeting the increasing demand for advanced technology solutions in many fields.`,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g": `VUA-SC-3G is the pride of Vietnam's high-tech industry, researched, produced and supplied to the market by Viettel Group. This close-range light reconnaissance unmanned aerial vehicle (UAV) plays an important role in many sectors, especially defense, security and agriculture. The VUA-SC-3G uses an internal combustion engine and has 2 take-off methods. With favorable conditions with straight lines, the aircraft can take off at the runway or use the launcher provided in other areas. The wingspan of the aircraft is 3.2m and the length is 2.2m, the aircraft can fly a maximum height of 3,000m (3km) and a distance within a radius of 50km, a take-off weight of 26kg and a continuous flight time of 3 hours in the speed range of 70-120km/h. Equipped with HD camera system both day and night, signals are sent to the live control room, in addition to temperature sensors, laser distance measurement system. The VUA-SC-3G uses an internal combustion engine with a thrust propeller as the main power transmission, when reaching an altitude of 150m or more with limited visibility, the aircraft can fly autonomously with a pre-made route without a direct operator, or in case of signal loss, the aircraft can also be smart enough to draw a flight map back to position derivative. VUA-SC-3G is a domestic UAV with many outstanding advantages, contributing to meeting the increasing demand for advanced technology solutions.`,
    "thiet-bi-truyen-dan-vo-tuyen-toc-do-cao": `VHCR high-speed radio transmission equipment is a modern data transmission system researched and manufactured by Viettel Group, meeting the needs of large-capacity information transmission in telecommunications networks. The system is equipped with many advanced technologies, providing high operational efficiency and adaptability to complex operating environments. VHCR is capable of transmitting data at speeds of up to 10 Gbps, meeting the needs of HD video transmission, live TV, high-speed internet access,... The system is equipped with advanced anti-jamming technologies, which help to operate effectively in complex electronic interference environments, remote areas, where fiber optic deployment is difficult. VHCR has a modular structure that is easy to disassemble and transport, allowing for rapid deployment over a variety of terrains. In particular, VHCR can be integrated with other transmission systems to form a unified and efficient information transmission network. VHCR is a modern high-speed radio transmission system that plays an important role in improving Vietnam's information transmission capacity. The system demonstrates the high technological level of Vietnam's defense industry and contributes to affirming Vietnam's position in the international arena.`,
    "may-thu-phat-vo-tuyen-dien-ca-nhan": `VRH-911 personal radio transceiver is mobile communication equipment widely used in functional forces and enterprises. The machine has a compact design, easy to use and carry, suitable for short-range communication operations. VRH-911 enables reliable and secure voice communications within a range of 1 – 3 km (depending on terrain and environmental conditions). The machine supports signal encryption to ensure communication security. 2W transmitting power helps the machine operate efficiently in high noise environments. The machine has a compact design, light weight, easy to move and use. VRH-911 is designed to operate in harsh environments, resistant to impact and dirt. The machine can operate continuously for 12 hours after each full charge. VRH-911 is used in functional forces such as police, army, border guard, fire police. The machine is also used in enterprises such as security, transport services, tourism.`,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay": `The VRH-811/S handheld ultra-shortwave radio transceiver is a tactical communication device widely used in the Vietnam People's Army, serving the tactical level; Voice communication, data transmission, secure encryption, anti-electronic warfare; Designed to be compact, oriented to equip ground forces (platoon commander, reconnaissance, commando) to operate in complex terrain conditions and high interference environment. The VRH-811/S enables reliable and secure voice communications within a range of 3 to 10 km (depending on terrain and environmental conditions). The machine supports signal encryption to ensure communication security. 5W transmitter power helps the machine operate efficiently in high noise environments. Compact size: The machine has a compact design, light weight, easy to move and use. The VRH-811/S is designed to operate in harsh environments, resistant to shocks and dirt.`,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang": `The 50W ultra-shortwave radio transceiver (VRU-812/S) and the Digital Communication Kit for tanks are two important pieces of equipment used in the Vietnam People's Army, contributing to improving communication and combat coordination capabilities in complex conditions. 50W frequency jumping ultra-short wave radio transceiver (VRU-812/S) using SDR technology, serving the tactical level; capable of voice communication, data transmission, secure encryption, anti-electronic warfare; orientation of equipping the army to the level of Brigade, Infantry Division, Armored Tank. The VRU-812/S enables reliable and secure voice communications over a range of 6 to 20 km (depending on terrain and environmental conditions). The machine uses advanced frequency hopping technology to prevent interference and secure communications. VRU-812/S supports signal encryption to ensure information security. The machine has a compact design, easy to move and use. Especially with 50W transmitting power to help the machine operate effectively in high noise environments. 2. Digital telephone sets for tanks used on tanks of the Vietnam People's Army. The equipment enhances the effectiveness of combat coordination between tank crew members. Details are as follows: The digital communication kit allows internal communication between members of the tank crew, connecting to the tank's information system to transmit clear data and images and sounds, helping to communicate effectively in loud noise environments. The telephone suite is designed to work in harsh environments.`,
    "he-thong-rada-thu-dong": `V-ELINT 18 passive radar system is a system researched and manufactured by Viettel Group, capable of accurately detecting and locating radar sources in a wide frequency range, including surveillance radar, fire control radar, reconnaissance radar,... Classification of different types of radars based on their signal characteristics. Determine the location of targets that are broadcasting radar, thereby supporting effective offensive weapon systems. The system is equipped with advanced anti-jamming technologies, which makes it efficient to operate in complex electronic interference environments. In particular, the V-ELINT 18 has a modular structure, easy to disassemble and transport, allowing for rapid deployment on a variety of terrains and operation in harsh weather conditions V-ELINT 18 is a modern passive radar system that plays an important role in improving the electronic reconnaissance capability of the Vietnam People's Army. The system demonstrates the high technological level of Vietnam's defense industry and contributes to affirming Vietnam's position in the international arena.`,
    "rada-canh-gioi-bien": `VRS-SRX is a modern radar system researched and manufactured by Viettel Group, meeting the needs of Vietnam's coastal protection in the new era. The system is researched and manufactured by Viettel Group, demonstrating the high technology level of Vietnam's defense industry and contributing to affirming Vietnam's position in the international arena. In particular, VRS-SRX is capable of accurately detecting and locating targets at sea such as boats, speedboats, swimmers ,... at a distance of up to 45 km. Distinguish small-sized targets, which enhances the effectiveness of surveillance and reconnaissance. VRS-SRX is equipped with advanced anti-interference technologies, which make the system operate effectively in complex electronic interference environments. In addition, the system has a modular structure, is easy to disassemble and transport, allows for rapid deployment over a variety of terrains, and can be integrated with other reconnaissance systems to form a unified, effective maritime surveillance network. The VRS-SRX high-resolution near-range marine surveillance radar is used to guard and protect Vietnam's coast from illegal infringement, smuggling, and drug activities,... Surveillance and reconnaissance of maritime activities in the area, used to control maritime traffic, ensure safety for sea movements.`,
    "rada-chien-thuat": `S-band tactical 3D radar (VRS-SRS) is a modern tactical 3D radar system, researched and manufactured by Viettel Group, playing an important role in improving the air defense combat capability of the Vietnam People's Army. The system demonstrates the high technological level of Vietnam's defense industry and contributes to affirming Vietnam's position in the international arena. S-band tactical 3D radar (VRS-SRS) HAS THE FOLLOWING MAIN FEATURES: 3D target detection and positioning: VRS-SRS is capable of accurately detecting and locating flying targets at distances of up to 120 km, altitudes of less than 10 km. The system can simultaneously track multiple targets, including fighter jets, helicopters, UAVs, cruise missiles,... High anti-interference ability: VRS-SRS is equipped with advanced anti-interference technologies, which makes the system operate effectively in complex electronic interference environments. High mobility: The system has a modular structure, easy to disassemble and transport, allowing for rapid deployment on a variety of terrains. High integrability: VRS-SRS can integrate with other defense systems to form a unified, effective air defense network. S-band tactical 3D radar (VRS-SRS) is used to detect, track and destroy flying targets in combat zones. Warn and protect important targets such as airports, military bases, industrial parks ,... Surveillance and reconnaissance of air targets in the area.`,
    "rada-tam-trung": `S-band medium-range realm 3D radar (VRS-MRS) is a modern radar system researched and manufactured by Viettel Group, meeting the needs of protecting Vietnam's skies in the new era. The system is equipped with many advanced technologies, providing high operational efficiency and adaptability to complex combat environments.
    The S-band mid-range 3D radar (VRS-MRS) has many features, including:
    3D target detection and positioning: VRS-MRS is capable of accurately detecting and locating flying targets at distances of up to 360 km, altitudes of less than 25 km.
    The system can simultaneously track multiple targets, including civilian aircraft, fighter jets, helicopters, UAVs, cruise missiles,...
    High anti-interference ability: VRS-MRS is equipped with advanced anti-interference technologies, making the system effective in complex electronic interference environments.
    High mobility: The system has a modular structure, easy to disassemble and transport, allowing for rapid deployment on a variety of terrains.
    High integrability: VRS-MRS can integrate with other defense systems to form a unified, effective air defense network.
    S-band medium-range 3D surveillance radar (VRS-MRS) is used in areas such as:
    Sky guard and protection: VRS-MRS is used to guard and protect Vietnam's skies from threats from aircraft, UAVs, cruise missiles,...
    Air traffic control: The system can be used to control air traffic, ensuring the safety of flight operations. Surveillance and reconnaissance: VRS-MRS can be used to monitor and reconnaissance air targets in the area. VRS-MRS is a modern medium-range 3D radar system that plays an important role in protecting Vietnam's skies. The system demonstrates the high technological level of Vietnam's defense industry and contributes to affirming Vietnam's position in the international arena.`,  
}
export const descriptionDetail1704Eng = {
    "5g-micro": ``,
    "5g-macrocell": ``,
    "5g-massive-mimo": ``,
    "site-router-100g": ``,
    "chip-5g-dfe": ``,
    "thiet-bi-ai-home-hub": ``,
    "camera-giao-thong": ``,
    "camera-thong-minh-toa-nha": ``,
    "camera-thong-minh-ho-gia-dinh": ``,
    "camera-anh-nhiet-giam-sat-toan-canh": ``,
    "he-thong-giam-sat-quang-dien-tu-tam-xa": ``,
    "thiet-bi-kinh-ngam-anh-nhiet": ``,
    "thiet-bi-trinh-sat-anh-nhiet": ``,
    "dai-quang-dien-tu-tam-xa": ``,
    "may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat": ``,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16": ``,
    "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g": ``,
    "thiet-bi-truyen-dan-vo-tuyen-toc-do-cao": ``,
    "may-thu-phat-vo-tuyen-dien-ca-nhan": ``,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay": ``,
    "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang": ``,
    "he-thong-rada-thu-dong": ``,
    "rada-canh-gioi-bien": ``,
    "rada-chien-thuat": ``,
    "rada-tam-trung": ``, 
}