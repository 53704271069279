import React from 'react';
import Approuter from './router/Approuter';

const App = () => {
  return (
    <Approuter />
  )
};

export default App;
