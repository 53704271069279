import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en.json'
import vietnam from './vn.json'

i18next.use(initReactI18next).init({
    lng: localStorage.getItem('@lang') ? localStorage.getItem('@lang') : "vi",
    compatibilityJSON: 'v3',
    resources: {
        en: english,
        vi: vietnam
    },
    react: {
        useSuspense: false
    }
})

export default i18next;
