export const descriptionGeneralEng = {
    "mayvotuyendien": `The VRS 631/S Wireless Shortwave Transceiver is a product researched and manufactured by Viettel High Tech, a subsidiary of the Viettel Group specializing in high-tech industries. This device employs Software Defined Radio (SDR) technology to serve communication needs in military campaigns and strategies. The equipment boasts capabilities for voice communication, data transmission, secure encryption, electronic warfare resistance, and can be deployed as a fixed transmitting station or installed on various platforms such as ships, mobile vehicles, and armored tanks.

 

    The VRS 631/S is one of the products incorporating core technologies innovated and mastered by Vietnamese engineers, contributing to positioning Vietnam's military wireless communication products among the top 5 globally. Production efficiency has been significantly enhanced to tens of thousands of military transceiver units per year, replacing the old production lines that could only produce hundreds of devices per year. Currently, the VRS 631/S has been put into production and deployed, with approximately 30,000 units supplied to military forces, benefiting the state budget by tens of billions of dong. Our military is no longer dependent on importing wireless communication equipment and has achieved complete self-sufficiency. Additionally, exports have been made to Laos in 2020, with plans for further expansion into markets such as India, Cuba, and others.`,
    "dairada": `The VRS-CSX Medium-Range Multisensor Coastal Surveillance Station is a product developed and manufactured by Viettel High Tech, a subsidiary of Viettel Group specializing in high-tech industries. The station is designed to serve the mission of coastal surveillance, monitoring the seas, and early detection of intrusions into Vietnam's maritime and economic exclusive zones.

    The VRS-CSX is a modern maritime security solution that meets the requirements of the armed forces and relevant agencies in protecting sovereignty, national security, order, and safety at sea. With its outstanding advantages, the VRS-CSX has successfully undergone acceptance testing by the Ministry of Defense of Vietnam and has been put into practical use. It is a representative product honored in the 2021 Ho Chi Minh Award for "New Generation Wireless Technology Platform for Manufacturing, Producing Modern Information Equipment, Meeting National Defense Missions." The station was entirely researched with domestic resources, with no technology transfer.`,
    "tramphatsong5g": `The Viettel 5G Base Station is an electronic device designed to broadcast Viettel's 5G signals. Viettel's 5G signal is a type of wireless wave with a higher frequency compared to previous mobile network generations such as 4G, 3G, and 2G. This allows Viettel's 5G to transmit data at higher speeds, lower latency, and the ability to connect to more devices.

    The Viettel 5G Base Station is an advanced technology that brings new experiences to users. It has the capability to transmit and receive 5G signals at high speeds with various Smallcell (4T4R), Microcell (8T8R), and Macrocell (8T8R, 32T32R, 64T64R) configurations. The primary focus in 5G research is the product's openness, with hardware designed according to OpenRAN standards. The Viettel 5G Base Station contributes to accelerating Vietnam's digital transformation.`,
    "hethongtrinhsat": `The VS13 Electronic Warfare Surveillance System is a system designed for collecting, detecting, analyzing, and locating radio frequency signals within the frequency range of 20 MHz to 3 GHz. The system has been developed by Viettel High Tech Corporation.

    The VS13 system is a high-tech product that meets the requirements of the People's Army of Vietnam in electronic warfare and national security protection. It has been deployed to the Ministry of Defense (Electronic Warfare Department), with 50 units delivered in 2019-2020 for use and deployment throughout the armed forces.`,
    "robotphucvu": `Viettel is a pioneering enterprise in the application of Artificial Intelligence (AI) in Vietnam. Among its notable AI applications, Viettel's service robots stand out. The Viettel service robot is a product developed by the Viettel Network Space Center (VTCC). The robot is designed to meet the needs of customers in various settings such as stores, restaurants, hotels, etc.

    Viettel's service robots have been deployed in numerous Viettel stores, restaurants, and hotels nationwide. The robots have been well-received by customers, contributing to the enhancement of Viettel's service quality. Viettel's service robot represents a breakthrough in the application of artificial intelligence in Vietnam, promising to bring new and more convenient experiences for customers while elevating the service quality of businesses.`,
    "maygaynhieusong": `The MRJ945 mobile phone signal jammer is a product developed and manufactured by Viettel High Tech, a subsidiary of Viettel Corporation. This device is designed for use in specific situations, such as:

    Safeguarding national security, maintaining order, and ensuring societal safety.
    
    Preventing illegal activities and violations of the law.
    
    Ensuring security and safety for important events.
    
    The MRJ945 is a high-tech product that meets the requirements of critical situations. It is produced according to military standards, demonstrating the ability to withstand harsh environmental conditions. The device contributes to the protection of national security, order, and societal safety, ensuring security for important events.`,
    "tuongphatbayon": `The Bayon Buddha statue, presented by the Metfone team (Viettel Cambodia) to Viettel on June 20, 2023, in Hanoi, is a remarkable gift. The sculpture, featuring four faces, replicates the architecture of the Bayon Temple at the center of the Angkor Thom complex—a heritage site constructed in the 12th century in the land of pagodas. According to local residents, the statue is cast in the likeness of a Bodhisattva in Indian Buddhism, with its four faces symbolizing the Bodhisattva's care and protection in all directions, as per Khmer Buddhism beliefs. The Metfone team gifted this statue to the Viettel Museum, wishing to bring luck and peace to all Viettel members worldwide.`,
    "tuongquocphuctimonam": `Two statues dressed in the national costume of Timor-Leste, presented by Telemor (Viettel East Timor) to Viettel in Hanoi on June 20, 2023, serve as significant symbols representing the culture and identity of Timor-Leste. When a statue adorned in Tais attire is given, it signifies that the recipient is considered a brother or family member within the Timorese community.

    The traditional attire of the Timorese people is known as Tais. Tais is a handwoven fabric made from cotton or linen fibers, featuring various colors and patterns. There are two main types, Mane and Feto, each holding its own distinct significance. Timorese individuals wear Tais on important ceremonial occasions.
    
    These statues, depicting individuals adorned in Tais, are often placed in prominent locations within Timorese homes or at the village's communal house. The act of gifting a Tais-clad statue symbolizes the acknowledgment of the recipient as a brother or family member within the Timorese community.`,
    "tuongquocphuctimonu": ``,
    "trongbatuque": `The Batuque drum is a culturally significant musical instrument with deep meaning in Mozambique. The drum holds a crucial place in Mozambican culture and stands as an emblem of the spirit of the Mozambican people. Traditional dances accompanied by the Batuque drum are often performed during important events such as welcoming guests or inaugurations. At the Viettel Museum, Movitel athletes showcased dance moves conveying reassurance to their families and colleagues in Mozambique. The message was clear: rest assured, our players in Vietnam will give their all and bring glory to Movitel. The Batuque drum was presented to the Viettel Museum by Movitel (Mozambique) on June 20, 2023.`,
    "tuongcaysusong": `The Ujamaa Tree of Life statue is a valuable and meaningful piece of art, symbolizing the culture and spirit of the Tanzanian people. The statue depicts a baobab tree, a common tree in Africa. The baobab is considered a symbol of life, growth, and unity. This tree plays a vital role in the lives of Tanzanians, serving various purposes such as treating ailments like diarrhea, gastrointestinal issues, and heart conditions. The baobab can be used as food; its fruit can be consumed fresh, juiced, or as preserves. The baobab leaves are edible, and its bark can be used for medicinal purposes or crafting household items. The baobab roots are edible or can be used medicinally. The baobab tree is versatile, providing wood, paper, plywood, and decorative materials.

    The Ujamaa Tree of Life statue was presented to the museum in Hanoi by the Halotel Football Team (Viettel Tanzania) on June 20, 2023, with the desire to connect Viettel people globally under one common roof.`,
    "tumi": `The Tumi is a crescent-shaped ceremonial knife used in the pre-Inca and Inca cultures of Peru. These knives were crafted from various materials such as gold, silver, copper, and lead. Tumis served multiple purposes, including harvesting crops and plants, cutting meat and other foods, surgical procedures, including brain surgery, and notably, in religious ceremonies such as animal sacrifices.

    The Tumi holds significant cultural importance in Peru, representing authority, power, and protection. Even today, Tumis are considered symbols of good luck in Peru. They are often hung on the walls of homes or businesses to bring luck and prosperity. This is why Viettel Peru presented one to the museum, expressing a wish for luck and prosperity to come to the shared home of Viettel.`,
    "tuongconbo": `The Lucky Bull statue, also known as the Pucará bull, holds cultural and spiritual significance for the people of Peru. Typically crafted from ceramics, these statues come in red, gold, or white colors and depict a stylized bull with intricate decorative lines.

    People in Peru often place the bull statue on their rooftops to invoke good luck. The appearance of the bull can vary between regions in Peru, sometimes resembling a bull and at other times a buffalo, adapting to local cultural nuances. The bull statue presented by the Bitel football team to the museum is sourced from the Cusco region, the ancient capital of the Inca Empire.`,
    "chodoxoilao": `The traditional sticky rice basket, known as "chõ đồ xôi" in Laos, is typically crafted from wood and has a round, pot-like shape. It features a tightly woven bamboo or rattan lid placed on top. When cooking, glutinous rice is placed in the basket, and the lid is closed. Traditional sticky rice baskets in Laos are commonly made from materials such as rattan or hardwood. These types of wood are chosen for their non-toxic properties, ensuring no harm to the health of the users. Additionally, selecting wood with a rounded trunk and moderate size makes it easier to carve the basket mold without risk of breakage.

    Unitel football team presented a replica of the traditional sticky rice basket to the museum during their visit to the Viettel Group headquarters. The gift symbolizes warmth and abundance, wishing prosperity for everyone associated with Viettel.`,
    "tranhchuamyanmar": `Temples play a crucial role in the spiritual life of the people of Myanmar, with over 40,000 temples spread across the country, each carrying its own symbols and meanings. Traditional Myanmar temple paintings represent a unique form of art, deeply rooted in the cultural essence of the nation. These artworks utilize vibrant, vivid colors, imbued with a strong symbolic significance.

    The traditional Myanmar temple paintings are crafted based on the architectural features of the country's traditional temples. The Mytel football team presents a gift to the Viettel Museum, conveying wishes of peace to the corporation and all employees worldwide.`,
    "trongtruyenthongburundi": `The traditional drum of the Burundian people is known as "ingoma." This large, oval-shaped drum is crafted from wood and animal hide. Ingoma is used in various occasions, including religious ceremonies, traditional festivals, and wartime events. It holds a significant role in Burundian culture, symbolizing the strength and unity of the Burundian people.

    Presented by Lumitel (Viettel Burundi), the traditional Burundian drum is displayed in the "Vươn ra biển lớn" area at the Viettel Museum in Hanoi, gifted on June 20, 2023.`,
    "giaithuongnoilamviec": `In late August 2023, the Military Industry and Telecoms Group (Viettel) proudly received the esteemed title of "Best Workplace in Asia" at the HR Asia Awards ceremony organized by HR Asia Magazine and Business Media International (a leading magazine in the Asian HR sector). This marks the third consecutive year that Viettel has been honored with this prestigious award.

    To achieve the recognition as the "Best Workplace in Asia 2023," a company must undergo two rounds of rigorous evaluation based on HR Asia's Best Workplace Model. The criteria for evaluation encompass:
    
    Vision and Strategy
    
    Leadership and Management
    
    Recruitment and Onboarding
    
    Training and Development
    
    Compensation and Benefits
    
    Job and Life Balance
    
    Diversity and Inclusion
    
    Corporate Social Responsibility
    
    By clinching this award, Viettel has solidified its position as one of the enterprises providing the best working environment in Asia. This accomplishment reflects Viettel's unwavering commitment to constructing and fostering a professional, dynamic, innovative, and humane work environment. Viettel pledges to continue its relentless efforts to build and develop the best working environment for all employees, creating conditions for individuals to unleash their full potential, contributing to the development of both the company and society.`,
    "cupchuyendoiso": `The Military Industry and Telecoms Group (Viettel) was honored as the "Outstanding Digital Transformation Enterprise of Vietnam in 2021" at the Vietnam Digital Awards ceremony organized by the Vietnam Digital Communications Association (VDCA). This achievement marks the third consecutive year that Viettel has received this prestigious award.

    Viettel has implemented comprehensive digital transformation across its operations, encompassing production, business, administration, and corporate culture. The digital transformation solutions employed by Viettel have yielded tangible results, contributing to enhanced productivity, operational efficiency, improved customer experience, and the sustainable development of the enterprise.
    
    Some notable digital transformation solutions by Viettel include:
    
     
    
    Viettel One Financial Management System: This system automates financial processes, enhancing management efficiency and financial control.
    
     
    
     
    
    Viettel One Customer Relationship Management (CRM) System: Improving customer experience and fostering interaction between customers and the business.
    
     
    
     
    
    Viettel One Enterprise Resource Planning (ERP) System: Providing comprehensive management of enterprise activities, from production and business to human resources.
    
     
    
    In addition, Viettel has deployed numerous digital transformation solutions in agriculture, healthcare, education, and more, contributing to the socio-economic development of the country.
    
    Through relentless efforts in digital transformation, Viettel has played a significant role in advancing the nation's digital transformation, establishing a robust foundation for sustainable development in the era of digital technology.`,
    "cuptop10": `On August 4, 2023, the Military Industry and Telecoms Group (Viettel) was honored to be recognized as the number one enterprise in the Top 10 prestigious Information Technology and Telecommunications (IT-Telco) companies in Vietnam for the year 2023. This marks the sixth consecutive year that Viettel has achieved this esteemed title.

    To be acknowledged as the "Most reputable IT-Telco enterprise in Vietnam 2023," businesses must undergo two rounds of rigorous assessments based on the reputable business model evaluation by Vietnam Report. The criteria include:
    
    Brand recognition
    
    Products and services
    
    Operational efficiency
    
    Social activities
    
    Declaration of sustainable development
    
    With this award, Viettel has solidified its position as one of the leading Information Technology and Telecommunications enterprises in Vietnam. This accomplishment is a result of Viettel's continuous efforts in development, innovation, and the enhancement of product and service quality to meet the increasing demands of customers.
    
    Viettel currently stands as the largest Information Technology and Telecommunications enterprise in Vietnam, providing diverse products and services such as telecommunications, information technology, military technology, and more. Viettel is also a pioneer in digital transformation, contributing to the nation's digital transition.
    
    In the upcoming period, Viettel remains committed to continuous development, innovation, and the improvement of product and service quality. It aims to reaffirm its position as a leading Information Technology and Telecommunications company in Vietnam and the region.`,
    "xeuat":`One week after the establishment of Sigelco, the Command ordered the General Company a U-OAT (UAZ) car driven by Comrade Nguyen Manh Tuong. The U-OAT car was also the first and only means of transportation for the Sigelco leadership at that time.

    Recalling this special car, Mr. Dinh Van Dat recounted: "At that time, I knew someone working at Apatit Lao Cai Federation. He told me that Apatit Lao Cai wanted to build a radio communication system to manage the mining and production activities of the Federation. The area of the Federation was too large, so the Hoang Lien Son Post Office and the University of Technology had tried several times without success. I reported this information to 'boss' Dang. He told me, 'If others can't do it, we must be able to. That's military information; that's the credibility of SIGELCO...'
    
    The success of the Apatit Lao Cai Radio Trunking project was partly due to the U-OAT car, which diligently transported SIGELCO soldiers across the vast territory of the Federation. This success marked a significant step forward in building Viettel's telecommunications projects.`
}
export const descriptionDetailEng = {
    "mayvotuyendien": `Some prominent features include:

    - Communication range up to 2000 km.
    
    - Clear fixed-frequency voice communication, secure fixed-frequency voice communication, and secure frequency-hopping voice communication.
    
    - Automatic establishment of HF ALE-3G transmission paths (following MIL-STD 188-141B standards).
    
    - AES256 data encryption, with support for additional external security module attachments.
    
    - Remote control up to a maximum range of 5 km via field cables.
    
    - Capability for feature upgrades through software updates.
    
    - Global positioning with GPS.
    
     
    
    The VRS 631/S Shortwave Transceiver has numerous outstanding advantages compared to similar products on the market, such as military-standard production and design for resilience in harsh environments. It features a compact design, lightweight, and easy mobility and installation. It utilizes modern SDR technology, allowing flexible adjustment of technical parameters to meet diverse user requirements. The broad operating frequency range enables communication with other shortwave transceiver systems worldwide. With high transmission power, it ensures clear and long-range signal transmission. The device supports advanced encryption methods to resist electronic warfare and can connect to computers for remote management.
    
     
    
    With its superior features and advantages, the VRS 631/S Shortwave Transceiver is employed in various fields, including communication among military units, international partner communication, emergency situations, long-distance regional communication, and high-speed data transmission between computer systems and electronic devices.`,
    "dairada": `The VRS-CSX features include:

    Detection range of targets from 10 to 100 km, ensuring the detection of small, fast-moving targets.
    
    Accurate target differentiation, excellent noise elimination, ensuring high reliability.
    
    Support for advanced encryption methods to resist electronic warfare.
    
    Capability to connect to central management and control systems.
    
    The VRS-CSX Medium-Range Multisensor Coastal Surveillance Station has many superior advantages compared to similar products on the market, including:
    
    Wide target detection range, ensuring the detection of small, fast-moving targets.
    
    Accurate target differentiation, excellent noise elimination, ensuring high reliability.
    
    Support for advanced encryption methods to resist electronic warfare.
    
    Capability to connect to central management and control systems.
    
    Applications:
    
    The VRS-CSX station is applied in various fields, including:
    
    Detection and early warning of unauthorized vessels entering Vietnam's maritime and economic exclusive zones, contributing to protecting sovereignty and national security.
    
    Detection and interception of vessels violating maritime laws, contributing to maintaining order and safety at sea.
    
    Detection of collision risks, contributing to ensuring maritime safety.
    
    Furthermore, the VRS-CSX is produced to military standards, demonstrating its ability to withstand harsh environmental conditions. It has a compact size, lightweight, and easy mobility for deployment. With features equivalent to the French Score-3000 station, it has been deployed in 45 comprehensive observation stations across the country, affirming Vietnam's sovereignty and mastery of the platform to secure military information. The product is an essential component, providing target designation for ground-to-sea missile complexes (also developed and manufactured by Vietnam). The project has obtained one patented invention in the U.S. and two patented inventions in Vietnam. Currently, about 30 stations can be produced annually, contributing to realizing Vietnam's CNC Defense Industry Complex.`,
    "tramphatsong5g": `The Viettel 5G Base Station is typically installed on high-rise buildings, towers, utility poles, or other elevated positions to provide broad coverage. The station can be divided into two main types:

 

    5G Macro Base Station: This is a large base station used to cover extensive areas such as cities, towns, or industrial zones.
    
     
    
     
    
    5G Micro and Pico Base Stations: These are smaller base stations used to cover smaller areas such as buildings, offices, or residential areas.
    
     
    
    Components of the Viettel 5G Base Station include:
    
    Antenna: The antenna is the component for transmitting and receiving 5G signals. Viettel's 5G antenna can be a microwave antenna, patch antenna, or array antenna.
    
    Radio Unit: The radio unit processes 5G signals, receiving signals from the antenna and transmitting signals to mobile devices.
    
    Power Unit: The power unit supplies electricity to the Viettel 5G Base Station.
    
    The Viettel 5G Base Station plays a crucial role in deploying Viettel's 5G network, providing 5G signals to mobile devices and enabling users to access high-speed internet, play online games, watch high-definition videos, and experience virtual reality.
    
    The deployment of Viettel's 5G Base Stations is rapidly occurring nationwide. In 2022, 300 8T8R stations were deployed for widespread testing. At the Mobile World Congress (MWC) 2023 in Spain, Viettel, in collaboration with Qualcomm, announced the successful research and production of the world's first 5G base station radio unit block using ASIC chipset according to Open RAN standards.`,
    "hethongtrinhsat": `The VS13 system has the following notable features:

    Automatic detection and location identification of radio signal sources up to 3 GHz, with the capability to expand to 6 GHz.
    
    Automatic analysis of signal characteristics (power, bandwidth, frequency, etc.).
    
    Automatic identification and demodulation of AM, FM, SSB signals.
    
    Automatic alerting in the presence of suspicious signal sources and automatic storage of signals.
    
    Designed with wideband Software-Defined Radio (SDR) technology, allowing easy feature updates through software.
    
    Ability to connect to command and control automation systems for electronic warfare, providing information and controlling systems that generate interference.
    
    The VS13 system is applied in various fields, including:
    
    Intelligence information gathering: The system is used to collect intelligence information, determine the positions of targets, counter interference, and interfere with the communication systems of adversaries.
    
    Monitoring radio frequency activities: It plays a role in monitoring radio frequency activities to ensure national security.
    
    Crime prevention: The system is utilized for monitoring radio frequency activities to prevent criminal activities.`,
    "robotphucvu": `The robot can perform tasks such as:

    Welcoming and guiding customers.
    
    Delivering items to customers.
    
    Displaying products.
    
    Answering customer inquiries.
    
    Viettel's service robot offers several advantages over traditional service staff, including:
    
    Operating 24/7 without the need for breaks.
    
    Not experiencing fatigue or stress.
    
    Rapid and accurate information processing capabilities.
    
    Ability to communicate effectively in Vietnamese.`,
    "maygaynhieusong": `The MRJ945 device features:

    The capability to interfere with various mobile phone signals, including GSM, 3G, 4G, and 5G.
    
    A wide interference range, extending up to 500 meters.
    
    Adjustable interference intensity to suit specific requirements.
    
    The MRJ945 is utilized in various sectors, including:
    
    Prevention of criminal activities such as terrorism, kidnapping, and hostage situations.
    
    Protection of critical targets, including military, political, and economic facilities.
    
    Ensuring safety for significant events such as conferences, seminars, and large gatherings.`,
    "tuongphatbayon": `The Bayon Buddha statue holds significance in various aspects:

 

    Artistic Significance: The statue is recognized as one of Cambodia's most famous symbols. It stands as a masterpiece of Khmer architecture, displaying the ancient Khmer people's craftsmanship, precision, and artistic talents.
    
     
    
     
    
    Spiritual Significance: In a spiritual context, the Bayon Buddha is a symbol of protection and shelter. The faces of the Avalokitesvara Buddha look in all four directions, signifying the intention to protect all living beings.
    
     
    
     
    
    Historical Significance: The Bayon Buddha statue carries the imprint of the golden age of the Khmer Empire. Constructed during the reign of King Jayavarman VII, a great monarch who unified Cambodia, it marks a period of prosperity for the country.`,
    "tuongquocphuctimonam": `The National Costume statue of Timor-Leste stands as a symbol of the country's culture and ethnic identity. The statue embodies the unity and solidarity of the Timorese people, transcending gender and ethnicity.

    The Timor-Leste National Costume statue carries various meanings. Culturally, it represents a symbol of the rich and diverse cultural heritage of Timor-Leste, encompassing traditional attire.
    
    Historically, the statue serves as a symbol of the unity and solidarity achieved by the Timorese people. It serves as a reminder of the challenges overcome by the Timorese in their struggle for independence and nation-building.
    
    Politically, the statue symbolizes the aspirations of the Timorese people for a peaceful, independent, and democratic nation. The National Costume statue is a valuable and meaningful piece of art, embodying the cultural, historical, and political essence of Timor-Leste.
    The Tais Dress is the primary attire for women in East Timor. Crafted from Tais fabric, a traditional woven textile of Timor-Leste, the dress showcases unique and intricate patterns. Tais is a handwoven fabric with distinctive and exquisite designs. Tais Dresses often feature colors such as red, green, and yellow, symbolizing the nation of Timor-Leste. The dress typically has a simple design, with the upper part fitting closely to the body and the lower part flaring out. Tais Dresses are commonly ankle-length or reach the heels, and they can have short or long sleeves.

The national costume for women in East Timor is an emblem of the country's culture and traditions. This attire is worn during festivals, significant events, or other formal occasions, reflecting ethnic pride and the spirit of unity among the people of East Timor.

Moreover, the national costume for women in East Timor holds spiritual significance. Tais, as a woven fabric, carries spiritual meaning for the people of East Timor. It is considered a symbol of luck, prosperity, and good health, embodying a deeper connection to the cultural and spiritual identity of the Timorese people.
    `,
    "tuongquocphuctimonu": ``,
    "trongbatuque": `The Batuque drum is a traditional percussion instrument of the Mozambican people, used in festivals, ceremonies, and cultural activities. Crafted in a cylindrical shape, the drum is made from wood and animal hide. Drums come in various sizes, ranging from small ones for concerts to larger drums for traditional festivities.

    Played either by hand or with sticks, the Batuque drum is often performed by women, creating powerful and rhythmic sounds. The smaller drums are employed in musical concerts and artistic performances, while the larger ones take center stage in traditional ceremonies and festivals.
    
    The Batuque drum is an integral part of Mozambican culture, symbolizing unity and community spirit. It serves as a means for the Mozambican people to express their cultural identity and heritage.
    
    The Batuque drum carries multiple meanings. Culturally, it symbolizes the richness and diversity of Mozambique's cultural heritage. Historically, it represents the spirit of resistance and struggle for independence among the Mozambican people. Socially, the drum stands as a symbol of unity, fostering connections and shared joy among the Mozambican community. The Batuque drum is not just an instrument; it is a vibrant expression of Mozambique's cultural, historical, and social identity.`,
    "tuongcaysusong": `The Ujamaa Tree of Life statue holds various meanings. Culturally, it symbolizes the identity and richness of Tanzania's culture, encompassing nature, humanity, and history. The statue represents the diversity and vibrancy of Tanzanian culture.

    Historically, the statue is an icon of the struggle for Tanzania's independence, embodying the people's desire for freedom and autonomy.
    
    Socially, the statue stands as a symbol of unity and community spirit among the Tanzanian people. It embodies dreams of a peaceful, prosperous, and united society.
    
    The Ujamaa Tree of Life statue serves as a reminder of the interconnectedness of Tanzanian culture with nature and history. It captures the essence of Tanzania's journey towards independence and envisions a future of harmony, prosperity, and unity within society.`,
    "tumi": `The Tumi was first developed by the Chavin culture around 1200 BCE and later adopted and refined by subsequent cultures, including the Moche, Chimú, and Inca. The Tumi reached its peak during the Inca period.

    Excavations in Peru have revealed a diverse range of Tumi knives in terms of size, shape, and decoration. Some Tumis are small, measuring only a few centimeters, while others can be as long as 40 cm. The handles of some Tumis are simple, while others are intricately decorated with images of animals, plants, and religious symbols.
    
    Tumis are considered one of Peru's most significant cultural heritage items, showcasing the skill and artistry of pre-Inca and Inca artisans. They stand as a testament to the ingenuity and craftsmanship of these ancient cultures.`,
    "tuongconbo": `The Pucará bull is a unique cultural icon in Peru, not only serving as a visually appealing decoration but also symbolizing important spiritual values for the people. The bull statue, presented by the Bitel football team to the Viettel Museum, is sourced from the Cusco region, the former capital of the Inca Empire.

    Peruvians believe that the Pucará bull brings luck, prosperity, health, and protection. These statues are often placed inside homes or offices to attract positive energies.
    
    The origin of the Pucará bull dates back to the Pucará civilization, an ancient civilization that existed in southern Peru from the 2nd to the 10th century CE. The Pucará people were skilled herders, and bulls held significant importance for them. Bulls were seen as symbols of strength, perseverance, and fertility.
    
    Today, the Pucará bull statue remains a popular cultural symbol in Peru. It is often sold as a souvenir or gift, carrying various meanings such as:
    
     
    
    Bringing Luck: Peruvians believe that the Pucará bull brings luck in all aspects of life, including work, finances, health, and love.
    
     
    
     
    
    Prosperity: The bull is a symbol of prosperity, making the Pucará bull statue a representation of wealth and abundance.
    
     
    
     
    
    Health: As bulls are associated with strength, the bull statue is also seen as a symbol of good health.
    
     
    
     
    
    Protection: People in Peru believe that the Pucará bull statue can protect them from negative forces.
    
     
    
    In summary, the Pucará bull is more than just a decorative item; it's a cultural emblem deeply intertwined with the spiritual beliefs and values of the Peruvian people.`,
    "chodoxoilao": `The traditional sticky rice basket of Laos serves the purpose of preparing sticky rice, a staple in Lao cuisine and an indispensable item in every Lao household. More than just a cooking tool, it is a cultural symbol for the people of Laos. Sticky rice holds significant importance in the spiritual life of the Lao people, consumed during festivals, weddings, religious ceremonies, and more.

    The act of gifting a sticky rice basket carries deep meanings, reflecting the giver's affection, care, and goodwill towards the recipient. Beyond a cooking utensil, the sticky rice basket is a cultural emblem, embodying Lao heritage.
    
     
    
    Cultural Symbol: The traditional sticky rice basket is not just a cooking utensil; it is a cultural symbol representing the rich heritage of the Lao people.
    
     
    
     
    
    Culinary Tradition: Sticky rice plays a vital role in Lao culinary traditions, with its usage extending to various cultural and celebratory occasions.
    
     
    
    Gifting a sticky rice basket is a profound gesture, expressing the giver's desire for the recipient to lead a life of abundance, happiness, and prosperity. The basket symbolizes richness, both in terms of material well-being and spiritual fulfillment. Additionally, it signifies the wish for luck and success in the recipient's life journey.
    
    In conclusion, the traditional sticky rice basket is more than a vessel for cooking; it embodies cultural values, traditions, and wishes for prosperity, making it a meaningful and cherished item in Lao culture.`,
    "tranhchuamyanmar": `The traditional Myanmar temple painting is created by skilled and professional artists employing high-level techniques. They utilize traditional paints made from natural materials such as clay and tree bark. These paintings hold high artistic and cultural value, becoming an integral part of the spiritual life of the Myanmar people.

    These paintings are a distinctive cultural beauty of Myanmar, not only possessing high artistic value but also deeply resonating with the philosophical principles of Buddhism.`,
    "trongtruyenthongburundi": `Ingoma is constructed from a hollow wooden body, approximately 1.5 meters in length and 0.6 meters in width. Buffalo skin is stretched across the drumhead and secured with leather straps. It is played by hand or with sticks, producing resonant tones.

    In Burundian culture, Ingoma plays a vital role, serving as an emblem of power and the unity of the Burundian people. During wartime, it inspires soldiers and signals messages to the enemy. Ingoma is also used to convey information and important messages, such as declarations of war, peace, or other significant events. Additionally, it is employed in religious ceremonies, praying to deities and transmitting messages from the divine. Ingoma can express emotions, creating a joyful atmosphere or conveying feelings of sadness or anger. It stands as a crucial musical instrument, symbolizing strength, unity, information, and tradition in Burundian culture.`,
    "giaithuongnoilamviec": ``,
    "cupchuyendoiso": ``,
    "cuptop10": ``,
    "xeuat": ``,
}