export const descriptionGeneral = {
    "mayvotuyendien": `Máy thu phát vô tuyến điện sóng ngắn nhảy tần VRS 631/S là sản phẩm do Tổng Công ty Công nghiệp Công nghệ cao Viettel (Viettel High Tech) nghiên cứu, chế tạo. Máy sử dụng công nghệ SDR, nhằm phục vụ liên lạc cấp chiến dịch, chiến lược. Thiết bị này có  khả năng liên lạc thoại, truyền dữ liệu, mã hóa bảo mật, chống tác chiến điện tử, định hướng làm trạm thu phát cố định hoặc lắp đặt trên tàu thuyền, xe cơ động, xe tăng thiết giáp.
    Máy thu phát vô tuyến điện sóng ngắn nhảy tần VRS 631/S là một trong những sản phẩm chứa đựng các công nghệ cốt lõi do người Việt sáng chế và làm chủ, góp phần đưa sản phẩm thông tin vô tuyến điện của Quân sự Việt Nam vào TOP 5 thế giới. Nâng cao hiệu suất sản xuất lên hàng chục nghìn thiết bị vô tuyến điện quân sự/năm thay cho hàng trăm trang thiết bị/năm so với dây chuyền cũ. Hiện nay Máy thu phát vô tuyến điện sóng ngắn nhảy tần VRS 631/S đã được đưa vào sản xuất và trang bị ~30.000 máy tới các lực lượng quân đội, làm lợi cho ngân sách nhà nước hàng chục nghìn tỷ đồng. Quân đội ta đã không phải nhập khẩu các trang thiết bị vô tuyến điện mà đã hoàn toàn tự chủ. Ngoài ra đã được xuất khẩu sang Lào năm 2020 và hướng tới các thị trường khác như Ấn Độ, Cu Ba...`,
    "dairada": `Đài ra đa cảnh giới biển tầm trung VRS-CSX là sản phẩm do Tổng Công ty Công nghiệp Công nghệ cao Viettel (Viettel High Tech) nghiên cứu, chế tạo. Đài được thiết kế để phục vụ nhiệm vụ cảnh giới, giám sát biển, phát hiện sớm các mục tiêu xâm nhập vùng biển, vùng đặc quyền kinh tế của Việt Nam.

    Đài ra đa cảnh giới biển tầm trung VRS-CSX là một giải pháp an ninh biển hiện đại, đáp ứng được các yêu cầu của lực lượng vũ trang và các cơ quan chức năng trong việc bảo vệ chủ quyền, an ninh quốc gia, an ninh trật tự, an toàn trên biển.Với những ưu điểm vượt trội của mình, đài ra đa cảnh giới biển tầm trung VRS-CSX đã được Bộ Quốc phòng Việt Nam nghiệm thu thành công và đưa vào sử dụng trong thực tế. Là sản phẩm đại diện nằm trong công trình đạt giải thưởng Hồ Chí Minh năm 2021 "Nền tảng công nghệ vô tuyến điện thế hệ mới phục vụ chế tạo, sản xuất trang bị thông tin hiện đại, đáp ứng nhiệm vụ quân sự quốc phòng". Đài ra đa nghiên cứu hoàn toàn bằng nguồn lực trong nước, không thực hiện chuyển giao công nghệ.`,
    "tramphatsong5g": `Trạm phát sóng 5G Viettel là một thiết bị điện tử dùng để phát sóng tín hiệu 5G của Viettel. Tín hiệu 5G Viettel là một loại sóng vô tuyến có tần số cao hơn so với các thế hệ mạng di động trước đó như: 4G, 3G và 2G. Điều này cho phép 5G Viettel truyền tải dữ liệu với tốc độ nhanh hơn, độ trễ thấp hơn và khả năng kết nối nhiều thiết bị hơn.

    Trạm phát sóng 5G Viettel là một công nghệ tiên tiến, mang đến những trải nghiệm mới cho người dùng. Khả năng thu phát sóng 5G với tốc độ cao và đa dạng các cấu hình Smallcell (4T4R), Microcell (8T8R), Macrocell (8T8R, 32T32R, 64T64R). Hướng chính khi nghiên cứu 5G là tính mở của sản phẩm, thiết kế phần cứng theo chuẩn OpenRAN. Trạm phát sóng 5G Viettel góp phần thúc đẩy quá trình chuyển đổi số của Việt Nam.`,
    "hethongtrinhsat": `Hệ thống trinh sát vô tuyến điện tử VS13 là một hệ thống thu thập, phát hiện, phân tích và định vị các tín hiệu vô tuyến điện trong dải tần số từ 20 MHz đến 3 GHz. Hệ thống được phát triển bởi Công ty Cổ phần Công nghệ cao Viettel (Viettel High Tech)

    Hệ thống VS13 là một sản phẩm công nghệ cao, đáp ứng được các yêu cầu của Quân đội nhân dân Việt Nam trong việc tác chiến điện tử và bảo vệ an ninh quốc gia. Đã đưa vào trang bị cho Bộ Quốc phòng (Cục Tác chiến Điện tử) 50 bộ năm 2019-2020 phục vụ bàn giao và trang bị trong toàn quân`,
    "robotphucvu": `Viettel là một trong những doanh nghiệp tiên phong trong ứng dụng trí tuệ nhân tạo (AI) tại Việt Nam. Trong đó, robot phục vụ là một trong những ứng dụng AI nổi bật của Viettel. Robot phục vụ Viettel là sản phẩm do Trung tâm Không gian mạng Viettel (VTCC) nghiên cứu, phát triển. Robot được thiết kế để phục vụ các nhu cầu của khách hàng tại các cửa hàng, nhà hàng, khách sạn,...

    Robot phục vụ Viettel đã được triển khai tại nhiều cửa hàng, nhà hàng, khách sạn của Viettel trên toàn quốc. Robot đã được khách hàng đón nhận tích cực, góp phần nâng cao chất lượng dịch vụ của Viettel. Robot phục vụ Viettel là một bước đột phá trong ứng dụng trí tuệ nhân tạo tại Việt Nam. Robot hứa hẹn sẽ mang đến những trải nghiệm mới, tiện lợi hơn cho khách hàng, góp phần nâng cao chất lượng dịch vụ của các doanh nghiệp.`,
    "maygaynhieusong": `Máy gây nhiễu sóng điện thoại di động MRJ945 là sản phẩm do Tổng Công ty Công nghiệp Công nghệ cao Viettel (Viettel High Tech) nghiên cứu, phát triển và sản xuất. Máy được thiết kế để sử dụng trong các trường hợp cần thiết, như:

    • Bảo vệ an ninh, trật tự, an toàn xã hội
    • Ngăn chặn các hành vi vi phạm pháp luật
    • Đảm bảo an ninh, an toàn cho các sự kiện quan trọng
    Máy MRJ945 là một sản phẩm công nghệ cao, đáp ứng được các yêu cầu trong các trường hợp cần thiết. Máy được sản xuất theo tiêu chuẩn quân sự, có khả năng chịu đựng được các điều kiện khắc nghiệt của môi trường. Máy góp phần bảo vệ an ninh, trật tự, an toàn xã hội, đảm bảo an ninh, an toàn cho các sự kiện quan trọng.`,
    "tuongphatbayon": `Tượng phật Bayon do đoàn Metfone (Viettel Cambodia) trao tặng Viettel vào ngày 20/6/2023 tại Hà Nội. Bức tượng với 4 mặt mô phỏng lại kiến trúc của ngôi đền Bayon tại trung tâm quần thể Angkor Thom, một di sản được xây dựng từ thế kỷ XII của đất nước Chùa tháp. Theo người dân địa phương, bức tượng được đúc theo 1 vị Bồ Tát trong Phật giáo Ấn Độ,  4 mặt nhìn bốn hướng đại diện cho sự quan tâm, bảo vệ tới mọi phương của Bồ Tát trong Phật giáo Khmer. Đội bóng Metfone dành tặng bảo tàng Viettel với mong muốn mang đến những điều may mắn, bình an đến tất cả người Viettel trên khắp thế giới.`,
    "tuongquocphuctimonam": `Hai bức tượng mặc Quốc phục của Timor-Leste do Telemor (Viettel Đông Timor) trao tặng Viettel tại Hà Nội vào ngày 20/6/2023. Khi bức tượng mặc Tais được trao đi có nghĩa là người nhận được coi là anh em, người thân trong gia đình của người dân Timor.

    Trang phục truyền thống của người Timor-Leste mang tên Tais. Tais là một loại vải dệt thủ công được làm từ sợi bông hoặc sợi lanh. Tais có nhiều màu sắc và hoa văn khác nhau, mỗi loại đều có ý nghĩa riêng, được chia thành 2 loại là Mane và Feto. Tais được người dân Timor-Leste mặc vào những dịp lễ quan trọng.
    
    Những bức tượng người mặc Tais thường được đặt tại những nơi trang trọng trong nhà của người Timor hoặc đặt tại nhà rông của làng. Khi bức tượng mặc Tais được trao đi có nghĩa là người nhận được coi là anh em, người thân trong gia đình của người dân Timor.`,
    "tuongquocphuctimonu": ``,
    "trongbatuque": `Trống Batuque là một loại nhạc cụ có giá trị và ý nghĩa. Trống là một phần quan trọng của văn hóa Mozambique và là một biểu tượng của tinh thần của người Mozambique. Điệu nhảy truyền thống kết hợp với trống Batuque thường được thể hiện vào những dịp quan trọng như đón khách, khai trương,… Tại bảo tàng Viettel, các cầu thủ Movitel đã thể hiện điệu nhảy với nội dung mong người nhà và đồng nghiệp ở Mozambique cứ yên tâm, các cầu thủ tới Việt Nam sẽ thi đấu hết mình và dành vinh quang cho Movitel. Trống Batuque do Movitel (Mozambique) trao tặng Bảo tàng Viettel vào ngày 20/6/2023`,
    "tuongcaysusong": `Bức tượng cây sự sống Ujamaa là một tác phẩm nghệ thuật có giá trị và ý nghĩa. Bức tượng là biểu tượng của văn hóa và tinh thần của người dân Tanzania. Bức tượng có hình một cây baobab, một loại cây phổ biến ở châu Phi. Cây baobab được coi là biểu tượng của sự sống, sự sinh sôi nảy nở và sự đoàn kết. Cây baobab có vai trò quan trọng trong đời sống của người dân Tanzania và được sử dụng trong nhiều mục đích khác nhau như: chữa một số bệnh về tiêu chảy, bệnh đường ruột, bệnh tim mạch,... Cây baobab có thể dùng làm thức ăn, quả baobab có thể ăn tươi, làm nước ép hoặc làm mứt. Lá baobab có thể ăn được như rau. Vỏ baobab có thể làm thuốc hoặc làm đồ gia dụng. Rễ baobab có thể ăn được hoặc làm thuốc. Cây baobab có thể được sử dụng để làm gỗ, giấy, ván ép,... và làm vật liệu trang trí.

    Bức tượng cây sự sống Ujamaa do Đội bóng đá Halotel (Viettel Tanzania) trao tặng Bảo tàng tại Hà Nội vào ngày 20/6/2023 với mong muốn kết nối người Viettel trên toàn cầu về với một mái nhà chung.`,
    "tumi": `Tumi là một loại dao hình bán nguyệt được sử dụng trong các nền văn hóa tiền Inca và Inca ở Peru. Chúng được làm bằng nhiều loại vật liệu như: vàng, bạc, đồng và chì. Tumi được sử dụng để thu hoạch ngũ cốc và các loại cây trồng; để cắt thịt và các loại thực phẩm; sử dụng trong phẫu thuật, bao gồm cả phẫu thuật não; đặc biệt Tumi được sử dụng trong các nghi lễ tôn giáo, chẳng hạn như các nghi lễ hiến tế động vật.

    Tumi là một biểu tượng quan trọng trong văn hóa Peru. Chúng được coi là biểu tượng của quyền lực, sức mạnh và sự bảo vệ. Ngày nay, tumi vẫn được coi là một biểu tượng may mắn ở Peru. Chúng thường được treo trên tường nhà hoặc tường của các doanh nghiệp để mang lại may mắn và thịnh vượng. Chính vì lí do đó Viettel Peru đã trao tặng Bảo tàng, với mong muốn những điều may mắn và thịnh vượng sẽ đến với ngôi nhà chung Viettel`,
    "tuongconbo": `Tượng con bò may mắn, hay còn được gọi là tượng bò Pucará, là một biểu tượng văn hóa và tâm linh quan trọng đối với người Peru. Chúng thường được làm bằng gốm sứ, có màu đỏ, vàng hoặc trắng và có hình dáng của một con bò tót với những đường nét trang trí tinh xảo.

    Bức tượng con bò thường được người Peru đặt trên mái nhà để cầu may mắn. Tại mỗi vùng của Peru, bức tượng lại được thay đổi hình dáng giữa bò và trâu để phù hợp với văn hóa địa phương. Tượng bò mà đội bóng Bitel dành tặng bảo tàng được lấy từ vùng Cusco – thủ phủ của đế chế Inca xưa kia.`,
    "chodoxoilao": `Chõ đồ xôi truyền thống của nước Lào thường được làm bằng gỗ, có hình dáng bầu tròn, phía trên đặt cái rổ đan kín bằng tre. Khi nấu, bỏ nếp vào rổ rồi đậy nắp. Chõ đồ xôi truyền thống của người Lào thường được làm bằng gỗ cơi hoặc gỗ hông. Đây là những loại gỗ có tính lành, không ảnh hưởng tới sức khỏe người dùng. Ngoài ra, còn phải chọn những cây gỗ có thân tròn, kích thước không quá to hoặc quá nhỏ, khi đục khuôn chõ sẽ dễ hơn và không bị vỡ.

    Đến với trụ sở Tập đoàn, đội bóng Unitel tặng bảo tàng tiêu bản chiếc chõ đồ xôi truyền thống của người Lào. Món quà được cho đi với mong muốn mang đến sự ấm no, đủ đầy đến với tất cả người Viettel.`,
    "tranhchuamyanmar": `Chùa là một phần quan trọng trong đời sống tinh thần của người dân Myanmar. Có hơn 40.000 ngôi chùa ở Myanmar và mỗi ngôi chùa đều có những biểu tượng và ý nghĩa riêng. Bức tranh chùa truyền thống Myanmar là một hình thức nghệ thuật đặc sắc, mang đậm bản sắc văn hóa của đất nước này. Tác phẩm sử dụng các màu sắc tươi sáng, rực rỡ, mang đậm tính biểu tượng.

    Bức tranh chùa truyền thống được làm dựa trên kiến trúc chùa truyền thống của Myanmar. Đội bóng Mytel gửi tặng bảo tàng Viettel thay cho lời chúc bình an tới Tập đoàn và tất cả CBNV trên toàn thế giới.`,
    "trongtruyenthongburundi": `Chiếc trống truyền thống của người Burundi được gọi là ingoma. Đây là một loại trống lớn, có hình bầu dục, được làm bằng gỗ và da. Ingoma được sử dụng trong nhiều dịp khác nhau, bao gồm các nghi lễ tôn giáo, các lễ hội truyền thống và các cuộc chiến. Ingoma có một vai trò quan trọng trong văn hóa của người Burundi, nó được coi là một biểu tượng của sức mạnh và sự thống nhất của dân tộc Burundi

    Chiếc trống truyền thống của người Burundi do Lumitel (Viettel Burundi) trao tặng Viettel tại Hà Nội vào ngày 20/6/2023 và được trưng bày trong khu vực Vươn ra biển lớn tại Bảo tàng Viettel.`,
    "giaithuongnoilamviec": `Cuối tháng 8/2023, Tập đoàn Công nghiệp - Viễn thông Quân đội (Viettel) đã vinh dự được vinh danh là “Nơi làm việc tốt nhất châu Á” tại Lễ trao giải HR Asia Awards do Tạp chí HR Asia và Business Media International (tạp chí hàng đầu về Nhân sự khu vực Châu Á) tổ chức. Đây là năm thứ 3 liên tiếp Viettel giành được giải thưởng danh giá này.

    Để được vinh danh “Nơi làm việc tốt nhất châu Á 2023”, doanh nghiệp cần phải trải qua hai vòng khảo sát với các tiêu chí đánh giá khắt khe, dựa trên mô hình đánh giá nơi làm việc tốt nhất của HR Asia. Các tiêu chí này bao gồm:
    
    • Tầm nhìn và chiến lược
    • Lãnh đạo và quản lý
    • Tuyển dụng và tiếp nhận
    • Đào tạo và phát triển
    • Trả lương và phúc lợi
    • Công việc và cuộc sống
    • Tính đa dạng và hòa nhập
    • Trách nhiệm xã hội của doanh nghiệp
    Với việc giành được giải thưởng này, Viettel đã khẳng định được vị thế của mình là một trong những doanh nghiệp có môi trường làm việc tốt nhất châu Á. Đây là thành quả của sự nỗ lực không ngừng nghỉ của Viettel trong việc xây dựng và phát triển môi trường làm việc chuyên nghiệp, năng động, sáng tạo và nhân văn. Viettel cam kết sẽ tiếp tục nỗ lực để xây dựng và phát triển môi trường làm việc tốt nhất cho tất cả nhân viên, tạo điều kiện để mỗi cá nhân phát huy hết khả năng của mình, đóng góp cho sự phát triển của doanh nghiệp và xã hội.`,
    "cupchuyendoiso": `Tập đoàn Công nghiệp - Viễn thông Quân đội (Viettel) đã được vinh danh là "Doanh nghiệp chuyển đổi số xuất sắc nhất Việt Nam năm 2021" tại Lễ trao giải Chuyển đổi số Việt Nam (Vietnam Digital Awards) do Hội Truyền thông số Việt Nam (VDCA) tổ chức. Đây là năm thứ 3 liên tiếp Viettel giành được giải thưởng này.

    Viettel đã triển khai chuyển đổi số trên toàn diện, từ sản xuất kinh doanh, quản trị đến văn hóa doanh nghiệp. Các giải pháp chuyển đổi số của Viettel đã mang lại hiệu quả thiết thực, góp phần nâng cao năng suất, hiệu quả hoạt động, tăng cường trải nghiệm khách hàng và phát triển bền vững của doanh nghiệp.
    
    Một số giải pháp chuyển đổi số nổi bật của Viettel bao gồm:
    
    • Hệ thống quản lý tài chính Viettel One giúp tự động hóa các quy trình tài chính, nâng cao hiệu quả quản lý và kiểm soát tài chính.
    • Hệ thống quản lý khách hàng (CRM) Viettel One giúp nâng cao trải nghiệm khách hàng, tăng cường tương tác giữa khách hàng và doanh nghiệp.
    • Hệ thống quản trị doanh nghiệp (ERP) Viettel One giúp quản lý toàn diện các hoạt động của doanh nghiệp, từ sản xuất kinh doanh đến quản trị nhân lực.
    Ngoài ra, Viettel cũng đã triển khai nhiều giải pháp chuyển đổi số trong lĩnh vực nông nghiệp, y tế, giáo dục,... nhằm góp phần phát triển kinh tế - xã hội của đất nước.
    
    Với những nỗ lực không ngừng nghỉ trong việc chuyển đổi số, Viettel đã góp phần thúc đẩy quá trình chuyển đổi số quốc gia, tạo ra nền tảng vững chắc để doanh nghiệp phát triển bền vững trong thời đại công nghệ số.`,
    "cuptop10": `Ngày 4/8/2023, Tập đoàn Công nghiệp - Viễn thông Quân đội (Viettel) đã vinh dự được vinh danh là doanh nghiệp số 1 trong Top công ty công nghệ thông tin - viễn thông (CNTT-VT) uy tín năm 2023. Đây là năm thứ 6 liên tiếp Viettel giành được danh hiệu này.

    Để được vinh danh "Doanh nghiệp CNTT-VT uy tín nhất Việt Nam 2023", doanh nghiệp cần phải trải qua hai vòng khảo sát với các tiêu chí đánh giá khắt khe, dựa trên mô hình đánh giá doanh nghiệp uy tín của Vietnam Report. Các tiêu chí này bao gồm:
    
    • Nhận diện thương hiệu
    • Sản phẩm, dịch vụ
    • Hiệu quả hoạt động
    • Hoạt động xã hội
    • Tuyên bố phát triển bền vững
    Với việc giành được giải thưởng này, Viettel đã khẳng định được vị thế của mình là một trong những doanh nghiệp công nghệ thông tin và viễn thông hàng đầu Việt Nam. Đây là thành quả của sự nỗ lực không ngừng nghỉ của Viettel trong việc phát triển, đổi mới, nâng cao chất lượng sản phẩm và dịch vụ, đáp ứng nhu cầu ngày càng cao của khách hàng.
    
    Viettel hiện đang là doanh nghiệp công nghệ thông tin và viễn thông lớn nhất Việt Nam, cung cấp đa dạng các sản phẩm, dịch vụ như viễn thông, công nghệ thông tin, công nghệ quân sự,... Viettel cũng là doanh nghiệp tiên phong trong việc triển khai chuyển đổi số, góp phần thúc đẩy quá trình chuyển đổi số quốc gia.
    
    Trong thời gian tới, Viettel tiếp tục nỗ lực phát triển, đổi mới, nâng cao chất lượng sản phẩm và dịch vụ, khẳng định vị thế là công ty công nghệ thông tin và viễn thông hàng đầu Việt Nam và khu vực.`,
    "dinhvi": `VTag là thiết bị tracking sử dụng 2 công nghệ NB-IoT và GSM cho phép định vị, giám sát trẻ em hoặc các đồ vật có giá trị.
    Thiết bị có kích thước nhỏ gọn, thiết kế dạng hộp đeo móc, đeo ở cổ hoặc gắn, móc vào các đồ vật hoặc kết hợp vỏ, dây silicon để đeo tay.
    Thiết kế hỗ trợ định vị sử dụng GPS, định vị dựa trên Wifi và Mobile Cell, sử dụng các công nghệ tiết kiệm năng lượng, có thời lượng
    Thiết bị định vị thông minh VTAG là thiết bị nhỏ gọn sử dụng công nghệ NB-IoT, kết nối đến nền tảng vThings, đi kèm với ứng dụng tracking trên smartphone, cho phép định vị, giám sát trẻ em/ phương tiện/ đồ vật có giá trị ..., cảnh báo kịp thời khi di chuyển ra ngoài vùng an toàn.`,
    "xeuat":`Một tuần sau khi thành lập Sigelco, Bộ Tư lệnh điều cho Tổng công ty một chiếc xe ô tô U-OAT (UAZ) do đồng chí Nguyễn Mạnh Tường lái. Chiếc xe U-OAT cũng chính là phương tiện di chuyển đầu tiên và duy nhất của Ban lãnh đạo Sigelco lúc bấy giờ. 

    Nhớ về chiếc xe đặc biệt này, bác Đinh Văn Đạt kể lại: “Khi ấy, tôi quen một người công tác tại Liên hiệp Apatít Lào Cai. Anh ta cho tôi biết, Apatít Lào Cai đang muốn xây dựng một hệ thống thông tin bằng Vô tuyến điện để điều hành việc khai thác quặng và sản xuất của Liên hiệp. Địa bàn của Liên hiệp quá rộng lớn nên Bưu điện Hoàng Liên Sơn, trường Đại học Bách khoa đã thử nghiệm mấy lần mà không được. Tôi báo cáo ‘sếp’ Đặng thông tin này. Ông nói với tôi: “Người ta không làm được thì mình phải làm được. Đó mới là Thông tin quân sự, mới là uy tín của SIGELCO…”.
    
    Công trình Radio Trunking Apatit Lào cai thành công một phần cũng chính là nhờ chiếc ô tô U-OAT đã cần mẫn đưa người lính SIGELCO đi khắp mọi nẻo đường trên địa bàn rộng lớn của Liên hiệp. Thành công này đánh dấu bước tiến quan trọng trong nghề xây dựng các công trình viễn thông của Viettel.`
}
export const descriptionDetail = {
    "mayvotuyendien": `Một số tính năng sử dụng nổi bật gồm:
    - Cự ly liên lạc đến 2000 km
    - Thoại rõ tần số cố định, thoại mật tần số cố định, thoại mật nhảy tần.
    - Tự động thiết lập đường truyền HF ALE-3G (theo chuẩn MIL-STD 188-141B)
    - Mã hóa bảo mật dữ liệu AES256, hỗ trợ gắn thêm mô đun cơ yếu bảo mật bên ngoài.
    - Điều khiển xa cự ly tối đa 5 km thông qua cáp dã chiến.
    - Có khả năng nâng cấp tính năng thông qua cập nhật phần mềm.
    - Định vị toàn cầu GPS.
    Máy thu phát vô tuyến điện sóng ngắn nhảy tần VRS 631/S có rất nhiều ưu điểm nổi bật so với các sản phẩm cùng loại trên thị trường như: Máy được sản xuất và thiết kế theo tiêu chuẩn quân đội, có khả năng chịu đựng tốt các tác động khắc nghiệt của môi trường. Máy có thiết kế nhỏ gọn, trọng lượng nhẹ, dễ dàng di chuyển, lắp đặt.
    - Sử dụng công nghệ SDR hiện đại, cho phép điều chỉnh linh hoạt các thông số kỹ thuật của máy, đáp ứng được các yêu cầu đa dạng của người dùng.
    - Phạm vi tần số hoạt động rộng, cho phép liên lạc với các hệ thống máy thu phát vô tuyến điện sóng ngắn khác trên thế giới.
    - Công suất phát lớn, đảm bảo khả năng truyền tín hiệu xa và rõ ràng.
    - Hỗ trợ các phương thức mã hóa bảo mật tiên tiến, chống tác chiến điện tử.
    - Khả năng kết nối với máy tính để quản lý, điều khiển từ xa
    - Công nghệ nhảy tần tốc độ cao 2000 lần/giây, tự động thiết lập đường truyền liên lạc vô tuyến trong môi trường phản xạ tầng điện ly.
    Với những tính năng và ưu điểm vượt trội, máy thu phát vô tuyến điện sóng ngắn nhảy tần VRS 631/S được sử dụng trong nhiều lĩnh vực như: liên lạc giữa các đơn vị trong lực lượng vũ trang, liên lạc với các đơn vị đối tác quốc tế, liên lạc trong các tình huống khẩn cấp, liên lạc giữa các vùng miền cách xa nhau, truyền dữ liệu tốc độ cao giữa các hệ thống máy tính, thiết bị điện tử.`,
    "dairada": `Đài ra đa VRS-CSX có các tính năng nổi bật sau:

    - Phạm vi phát hiện mục tiêu từ 10 đến 100 km, đảm bảo phát hiện được các mục tiêu nhỏ, di chuyển nhanh.
    - Khả năng phân biệt mục tiêu chính xác, loại bỏ nhiễu tốt, đảm bảo độ tin cậy cao.
    - Hỗ trợ các phương thức mã hóa bảo mật tiên tiến, chống tác chiến điện tử.
    - Có khả năng kết nối với các hệ thống quản lý, điều khiển trung tâm.
    Đài ra đa cảnh giới biển tầm trung VRS-CSX có nhiều ưu điểm vượt trội so với các sản phẩm cùng loại trên thị trường, bao gồm:
    
    - Phạm vi phát hiện mục tiêu rộng, đảm bảo phát hiện được các mục tiêu nhỏ, di chuyển nhanh.
    - Khả năng phân biệt mục tiêu chính xác, loại bỏ nhiễu tốt, đảm bảo độ tin cậy cao.
    - Hỗ trợ các phương thức mã hóa bảo mật tiên tiến, chống tác chiến điện tử.
    - Khả năng kết nối với các hệ thống quản lý, điều khiển trung tâm.
    - Ứng dụng của đài ra đa cảnh giới biển tầm trung VRS-CSX
    Đài ra đa VRS-CSX được ứng dụng trong nhiều lĩnh vực, cụ thể: đài được sử dụng để phát hiện, cảnh báo sớm các tàu, thuyền lạ xâm nhập vùng biển, vùng đặc quyền kinh tế của Việt Nam, góp phần bảo vệ chủ quyền, an ninh quốc gia. Phát hiện, truy bắt các tàu, thuyền vi phạm pháp luật trên biển, góp phần bảo đảm an ninh trật tự, an toàn trên biển. Phát hiện nguy cơ gây va chạm, góp phần đảm bảo an toàn hàng hải.
    
    Ngoài ra, đài ra đa VRS-CSX được sản xuất theo tiêu chuẩn quân sự, có khả năng chịu đựng được các điều kiện khắc nghiệt của môi trường. Đài có kích thước nhỏ gọn, trọng lượng nhẹ, dễ dàng cơ động, triển khai.
    
    Với tính năng tương đương đài ra đa Score-3000 của Pháp và đã đưa vào trang bị 45 đài phủ kín trường quan sát của đất nước, khẳng định chủ quyền và làm chủ nền tảng giúp Việt Nam bảo mật các thông tin quân sự. Ngoài ra, sản phẩm còn là thành phần không thể thiếu, giúp chỉ thị mục tiêu cho tổ hợp tên lửa đối đất đối hải (cũng do Việt Nam nghiên cứu, chế tạo). Công trình đã đạt 01 bằng sang chế được cấp độc quyền tại Mỹ, 02 bằng sáng chế được cấp độc quyền tại Việt Nam. Hiện nay có thể sản xuất ~30 đài/năm, góp phần hiện thực hóa tổ hợp Công nghiệp Quốc phòng CNC của Việt Nam.`,
    "tramphatsong5g": `Trạm phát sóng 5G Viettel thường được lắp đặt trên các tòa nhà cao tầng, tháp cao, cột điện hoặc các vị trí cao khác để có thể phủ sóng rộng. Trạm phát sóng 5G Viettel có thể được chia thành hai loại chính:

    - Trạm phát sóng 5G Macro: Đây là loại trạm phát sóng lớn, được sử dụng để phủ sóng các khu vực rộng lớn, như thành phố, thị trấn hoặc khu công nghiệp.
    - Trạm phát sóng 5G Micro và Pico: Đây là loại trạm phát sóng nhỏ hơn, được sử dụng để phủ sóng các khu vực nhỏ hơn, như tòa nhà, văn phòng hoặc khu dân cư.
    Trạm phát sóng 5G Viettel có các thành phần chính sau:
    
    - Antenna: Ăng-ten là bộ phận thu phát sóng 5G. Ăng-ten 5G Viettel có thể là ăng-ten vi mô (microwave antenna), ăng-ten ăng ten (patch antenna) hoặc ăng-ten mảng (array antenna).
    - Radio unit: Radio unit là bộ phận xử lý tín hiệu 5G. Radio unit nhận tín hiệu từ ăng-ten và truyền tín hiệu đến các thiết bị di động.
    - Power unit: Power unit là bộ phận cung cấp điện cho trạm phát sóng 5G.
    Trạm phát sóng 5G Viettel đóng vai trò quan trọng trong việc triển khai mạng 5G Viettel, cung cấp tín hiệu 5G cho các thiết bị di động, cho phép người dùng sử dụng các dịch vụ 5G Viettel như: truy cập internet tốc độ cao, chơi game trực tuyến, xem video độ nét cao và thực tế ảo.
    
    Việc triển khai trạm phát sóng 5G Viettel đang diễn ra nhanh chóng trên toàn quốc. Trong năm 2022 đã triển khai sản xuất 300 trạm 8T8R phục vụ triển khai thử nghiệm diện rộng. Tại Hội nghị Di động thế giới (MWC 2023) tại Tây Ban Nha, Viettel cùng với Qualcomm đã công bố nghiên cứu, sản xuất thành công khối vô tuyến trạm thu phát sóng 5G đầu tiên trên thế giới sử dụng chipset ASIC theo tiêu chuẩn Open RAN.`,
    "hethongtrinhsat": `Hệ thống VS13 có các tính năng nổi bật sau:

    - Tự động phát hiện và định vị vị trí nguồn phát tín hiệu vô tuyến đến 3GHz và có khả năng mở rộng lên 6GHz
    - Tự động phân tích các đặc tính của tín hiệu (công suất, băng thông, tần số...)
    - Tự động nhận dạng và giải điều chế AM, FM, SSB
    - Tự động cảnh báo khi có nguồn tín hiệu khả nghi và tự động lưu trữ tín hiệu
    - Thiết kế theo công nghệ SDR dải rộng, dễ dàng cập nhật tính năng thông qua phần mềm
    - Có khả năng kết nối với hệ thống tự động hóa chỉ huy và điều khiển tác chiến điện tử, cung cấp thông tin và điều khiển các hệ thống gây nhiễu
    Hệ thống VS13 được ứng dụng trong nhiều lĩnh vực, trong đó: Hệ thống được sử dụng để thu thập thông tin tình báo, xác định vị trí của các mục tiêu, chống nhiễu, gây nhiễu các hệ thống thông tin liên lạc của đối phương. Giám sát các hoạt động vô tuyến điện, bảo vệ an ninh quốc gia. Giám sát các hoạt động vô tuyến điện, phòng chống tội phạm.`,
    "robotphucvu": `Robot có thể thực hiện các nhiệm vụ như:

    • Chào đón, hướng dẫn khách hàng
    • Giao nhận đồ cho khách hàng
    • Trưng bày sản phẩm
    • Giải đáp thắc mắc của khách hàng
    Robot phục vụ Viettel có nhiều ưu điểm vượt trội so với các nhân viên phục vụ truyền thống, bao gồm:
    
    • Hoạt động 24/7, không cần nghỉ ngơi
    • Không bị mệt mỏi, căng thẳng
    • Có khả năng xử lý thông tin nhanh chóng, chính xác
    • Có khả năng giao tiếp bằng tiếng Việt`,
    "maygaynhieusong": `Máy MRJ945 có các tính năng nổi bật sau:

    • Có thể gây nhiễu các loại sóng điện thoại di động phổ biến hiện nay, bao gồm GSM, 3G, 4G, 5G.
    • Phạm vi gây nhiễu rộng, có thể lên đến 500 mét.
    • Có thể điều chỉnh cường độ gây nhiễu, phù hợp với từng yêu cầu cụ thể.
    Máy MRJ945 được sử dụng trong nhiều lĩnh vực như: Máy được sử dụng để ngăn chặn các hoạt động phạm tội, như khủng bố, bắt cóc, bắt giữ con tin,...Dùng để bảo vệ các mục tiêu quan trọng, như các cơ sở quân sự, chính trị, kinh tế. Đảm bảo an toàn cho các sự kiện quan trọng, như hội nghị, hội thảo, đám đông.`,
    "tuongphatbayon": `Tượng phật Bayon là một trong những biểu tượng nổi tiếng nhất của Campuchia. Tượng phật Bayon có nhiều ý nghĩa khác nhau. Về mặt nghệ thuật, tượng là một kiệt tác của kiến trúc Khmer. Tượng được xây dựng tỉ mỉ, tinh xảo, thể hiện sự tài hoa của người Khmer cổ đại.Về mặt tâm linh, tượng phật Bayon là biểu tượng của sự bảo vệ và che chở. Những khuôn mặt Phật Avalokitesvara hướng nhìn ra bốn phương trời, thể hiện ý muốn bảo vệ tất cả chúng sinh. Về mặt lịch sử, tượng phật Bayon là dấu ấn của thời kỳ hoàng kim của đế chế Khmer. Tượng được xây dựng dưới thời vua Jayavarman VII, một vị vua vĩ đại đã thống nhất Campuchia và mở ra một thời kỳ thịnh vượng cho đất nước.`,
    "tuongquocphuctimonam": `Bức tượng Quốc phục của Timor-Leste là một biểu tượng của văn hóa và bản sắc dân tộc của đất nước. Tượng thể hiện sự đoàn kết và thống nhất của người dân Timor-Leste, bất kể giới tính hay dân tộc.

    Tượng Quốc phục của Timor-Leste có nhiều ý nghĩa khác nhau. Về mặt văn hóa, tượng là một biểu tượng của văn hóa và bản sắc dân tộc của Timor-Leste. Tượng thể hiện sự đa dạng và phong phú của văn hóa Timor-Leste, bao gồm cả trang phục truyền thống.
    
    Về mặt lịch sử, tượng là một biểu tượng của sự đoàn kết và thống nhất của người dân Timor-Leste. Tượng nhắc nhở mọi người về những khó khăn mà người dân Timor-Leste đã phải trải qua để giành được độc lập và xây dựng đất nước.
    
    Về mặt chính trị, tượng là một biểu tượng của sự tự do và dân chủ. Tượng thể hiện nguyện vọng của người dân Timor-Leste được sống trong một đất nước hòa bình, độc lập và dân chủ.
    
    Tượng Quốc phục của Timor-Leste là một tác phẩm nghệ thuật có giá trị và ý nghĩa. Tượng là một biểu tượng của văn hóa, lịch sử và chính trị của Timor-Leste.
    
    Đầm Tais là trang phục chính của phụ nữ Đông Timor. Đầm được làm bằng vải dệt Tais, một loại vải dệt truyền thống của Timor-Leste. Tais là một loại vải dệt thủ công, có hoa văn độc đáo và tinh xảo. Đầm Tais thường có màu đỏ, xanh lá cây và vàng, là những màu sắc tượng trưng cho đất nước Timor-Leste. Đầm Tais thường có kiểu dáng đơn giản, với phần thân trên ôm sát cơ thể và phần thân dưới xòe rộng. Đầm Tais thường dài đến mắt cá chân hoặc gót chân. Đầm Tais có thể được may theo kiểu tay ngắn hoặc tay dài.

Trang phục quốc phục của phụ nữ Đông Timor là một biểu tượng của văn hóa và truyền thống của đất nước. Trang phục này được mặc trong các dịp lễ hội, sự kiện quan trọng hoặc các dịp trang trọng khác. Trang phục này thể hiện sự tự hào dân tộc và tinh thần đoàn kết của người dân Đông Timor.

Ngoài ra, trang phục quốc phục của phụ nữ Đông Timor còn có ý nghĩa về mặt tâm linh. Tais là một loại vải dệt có ý nghĩa tâm linh đối với người dân Đông Timor. Tais được coi là một biểu tượng của sự may mắn, thịnh vượng và sức khỏe.`,
    "tuongquocphuctimonu": `Đầm Tais là trang phục chính của phụ nữ Đông Timor. Đầm được làm bằng vải dệt Tais, một loại vải dệt truyền thống của Timor-Leste. Tais là một loại vải dệt thủ công, có hoa văn độc đáo và tinh xảo. Đầm Tais thường có màu đỏ, xanh lá cây và vàng, là những màu sắc tượng trưng cho đất nước Timor-Leste. Đầm Tais thường có kiểu dáng đơn giản, với phần thân trên ôm sát cơ thể và phần thân dưới xòe rộng. Đầm Tais thường dài đến mắt cá chân hoặc gót chân. Đầm Tais có thể được may theo kiểu tay ngắn hoặc tay dài.

    Trang phục quốc phục của phụ nữ Đông Timor là một biểu tượng của văn hóa và truyền thống của đất nước. Trang phục này được mặc trong các dịp lễ hội, sự kiện quan trọng hoặc các dịp trang trọng khác. Trang phục này thể hiện sự tự hào dân tộc và tinh thần đoàn kết của người dân Đông Timor.
    
    Ngoài ra, trang phục quốc phục của phụ nữ Đông Timor còn có ý nghĩa về mặt tâm linh. Tais là một loại vải dệt có ý nghĩa tâm linh đối với người dân Đông Timor. Tais được coi là một biểu tượng của sự may mắn, thịnh vượng và sức khỏe.`,
    "trongbatuque": `Trống Batuque là một loại trống truyền thống của người Mozambique. Trống được sử dụng trong các lễ hội, nghi lễ và các hoạt động văn hóa của người Mozambique.

    Trống Batuque có hình trụ, được làm bằng gỗ và da động vật. Trống có kích thước khác nhau, từ nhỏ đến lớn. Trống nhỏ thường được sử dụng trong các buổi hòa nhạc và các hoạt động biểu diễn nghệ thuật. Trống lớn thường được sử dụng trong các lễ hội và nghi lễ truyền thống.
    
    Trống Batuque được chơi bằng tay hoặc bằng gậy. Người chơi trống thường là phụ nữ. Họ chơi trống theo nhịp điệu mạnh mẽ và dồn dập, tạo ra âm thanh sôi động và náo nhiệt.
    
    Trống Batuque là một phần quan trọng của văn hóa Mozambique. Trống là biểu tượng của sự đoàn kết và tinh thần cộng đồng của người Mozambique. Trống cũng là một phương tiện để người Mozambique thể hiện bản sắc văn hóa của mình.
    
    Trống Batuque có nhiều ý nghĩa khác nhau. Về mặt văn hóa, trống là một biểu tượng của văn hóa và bản sắc dân tộc của Mozambique. Trống thể hiện sự đa dạng và phong phú của văn hóa Mozambique. Về mặt lịch sử, trống là một biểu tượng của tinh thần phản kháng và đấu tranh của người Mozambique. Trống là một phương tiện để người Mozambique thể hiện khát vọng tự do và độc lập. Về mặt xã hội, trống là một biểu tượng của sự đoàn kết và tinh thần cộng đồng của người Mozambique. Trống là một phương tiện để người Mozambique giao lưu, kết nối và chia sẻ niềm vui với nhau.`,
    "tuongcaysusong": `Bức tượng cây sự sống Ujamaa có nhiều ý nghĩa khác nhau. Về mặt văn hóa, bức tượng là biểu tượng của văn hóa và bản sắc dân tộc của Tanzania. Bức tượng thể hiện sự đa dạng và phong phú của văn hóa Tanzania, bao gồm cả thiên nhiên, con người và lịch sử.

    Về mặt lịch sử, bức tượng là biểu tượng của cuộc đấu tranh giành độc lập của người dân Tanzania. Bức tượng thể hiện khát vọng tự do và độc lập của người dân Tanzania.
    
    Về mặt xã hội, bức tượng là biểu tượng của sự đoàn kết và tinh thần cộng đồng của người dân Tanzania. Bức tượng thể hiện ước mơ về một xã hội hòa bình, thịnh vượng và đoàn kết.`,
    "tumi": `Tumi được phát triển lần đầu tiên bởi nền văn hóa Chavin vào khoảng năm 1200 TCN. Chúng được phổ biến bởi các nền văn hóa sau đó, bao gồm Moche, Chimú và Inca. Tumi đạt đến đỉnh cao của sự phát triển trong thời kỳ Inca.

    Những con dao tumi được tìm thấy trong các cuộc khai quật khảo cổ ở Peru rất đa dạng về kích thước, hình dạng và trang trí. Một số con dao tumi rất nhỏ, chỉ dài vài cm, trong khi những con dao tumi khác có thể dài tới 40 cm. Một số con dao tumi có cán dao đơn giản, trong khi những con dao tumi khác có cán dao được trang trí công phu với các hình ảnh của động vật, thực vật và các biểu tượng tôn giáo.
    
    Tumi là một trong những di sản văn hóa quan trọng nhất của Peru. Chúng là một minh chứng cho sự khéo léo và tài hoa của các nghệ nhân tiền Inca và Inca.`,
    "tuongconbo": `Tượng bò Pucará là một biểu tượng văn hóa độc đáo của Peru. Chúng không chỉ là một món đồ trang trí đẹp mắt, mà còn là một biểu tượng của những giá trị tinh thần quan trọng đối với người Peru. Tượng bò mà đội bóng Bitel dành tặng Bảo tàng Viettel được lấy từ vùng Cusco – thủ phủ của đế chế Inca xưa

    Người Peru tin rằng tượng bò Pucará mang lại may mắn, thịnh vượng, sức khỏe và sự bảo vệ. Chúng thường được đặt trong nhà hoặc văn phòng để cầu mong những điều tốt lành.
    
    Tượng bò Pucará có nguồn gốc từ nền văn minh Pucará, một nền văn minh cổ đại tồn tại ở miền nam Peru từ thế kỷ 2 đến thế kỷ 10 sau Công nguyên. Người Pucará là những người chăn nuôi giỏi và bò là một loài vật quan trọng đối với họ. Họ tin rằng bò là biểu tượng của sức mạnh, sự kiên trì và khả năng sinh sản.
    
    Ngày nay, tượng bò Pucará vẫn là một biểu tượng văn hóa phổ biến ở Peru. Chúng thường được bán làm đồ lưu niệm hoặc quà tặng với một số ý nghĩa như: Mang lại may mắn, người Peru tin rằng tượng bò Pucará mang lại may mắn trong mọi lĩnh vực của cuộc sống, bao gồm công việc, tài chính, sức khỏe và tình yêu. Bên cạnh đó, bò là một biểu tượng của sự thịnh vượng, vì vậy tượng bò Pucará cũng được coi là một biểu tượng của sự giàu có và của cải. Ngoài ra, biểu tượng còn mang ý nghĩa về sức khỏe bởi bò là một động vật khỏe mạnh. Người Peru cũng tin rằng tượng bò Pucará có thể bảo vệ họ khỏi những điều xấu xa.`,
    "chodoxoilao": `Chõ đồ xôi truyền thống của người Lào được sử dụng để đồ xôi, một món ăn truyền thống của người Lào và đây cũng là một vật dụng không thể thiếu trong mỗi gia đình của người Lào. Nó không chỉ là một công cụ nấu nướng mà còn là một biểu tượng văn hóa của người Lào. Xôi là một món ăn quan trọng trong đời sống tinh thần của người Lào, được sử dụng trong các dịp lễ tết, cưới hỏi, cúng bái,...

    Việc tặng chõ xôi mang nhiều ý nghĩa sâu sắc, thể hiện tình cảm, sự quan tâm và mong muốn tốt đẹp của người tặng dành cho người nhận. Ngoài ra, việc tặng chõ xôi còn mang ý nghĩa mong muốn tốt đẹp của người tặng dành cho người nhận. Chõ xôi là một biểu tượng của sự sung túc, no đủ. Việc tặng chõ xôi thể hiện mong muốn của người tặng dành cho người nhận có một cuộc sống ấm no, hạnh phúc. Là một biểu tượng của sự may mắn, thịnh vượng. Việc tặng chõ xôi còn  thể hiện mong muốn của người tặng dành cho người nhận có được nhiều may mắn, thành công trong cuộc sống.`,
    "tranhchuamyanmar": `Bức tranh chùa truyền thống Myanmar được vẽ bởi các nghệ nhân chuyên nghiệp, có kỹ thuật cao. Họ sử dụng các loại sơn truyền thống, được làm từ các nguyên liệu tự nhiên như: đất sét, vỏ cây,... Những bức tranh này có giá trị nghệ thuật và văn hóa cao, là một phần không thể thiếu trong đời sống tinh thần của người dân Myanmar.

    Bức tranh chùa truyền thống Myanmar là một nét đẹp văn hóa đặc sắc của đất nước Myanmar. Những bức tranh này không chỉ có giá trị nghệ thuật cao, mà còn mang đậm tính triết lý Phật giáo.`,
    "trongtruyenthongburundi": `Ingoma được làm từ một thân gỗ rỗng, có kích thước khoảng 1,5 mét chiều dài và 0,6 mét chiều rộng. Da trâu được căng lên mặt trống, và được buộc bằng dây da. Ingoma được chơi bằng cách dùng tay hoặc gậy gõ vào mặt trống.

    Ingoma có một vai trò quan trọng trong văn hóa của người Burundi, nó được coi là một biểu tượng của sức mạnh và sự thống nhất của dân tộc Burundi. Trong các cuộc chiến, ingoma được sử dụng để truyền cảm hứng cho binh lính và để báo hiệu cho quân địch. Ingoma cũng được sử dụng để truyền tải thông tin và thông điệp quan trọng. Ví dụ, ingoma có thể được sử dụng để tuyên bố chiến tranh, hòa bình hoặc các sự kiện quan trọng khác. Ingoma cũng được sử dụng trong các nghi lễ tôn giáo. Trong các nghi lễ này, ingoma được sử dụng để cầu nguyện cho thần linh và để truyền tải các thông điệp từ thần linh. Ingoma cũng có thể được sử dụng để truyền tải tâm trạng và cảm xúc. Ví dụ, ingoma có thể được sử dụng để tạo ra bầu không khí vui vẻ hoặc để thể hiện sự buồn bã hoặc giận dữ. Bên cạnh đó, Ingoma là một loại nhạc cụ quan trọng trong văn hóa của người Burundi. Nó là một biểu tượng của sức mạnh, sự thống nhất, thông tin và truyền thống.`,
    "giaithuongnoilamviec": ``,
    "cupchuyendoiso": ``,
    "cuptop10": ``,
    "dinhvi": `Các tính năng nổi bật\r
    + Quản lý nhiều đối tượng theo dõi\r
    + Định vị theo thời gian thực\r
    + Tính năng xem lịch sử di chuyển (trong 6 tháng)\r
    + Tính năng cảnh báo nguy hiểm và khi ra khỏi vùng an toàn\r
    + Chống bụi, nước theo chuẩn IP65\r
    + Tính bảo mật cao\r
    + Có cảm biến gia tốc (cảnh báo hành trình di chuyến/dừng di chuyển)\r
    Thông số kỹ thuật:\r
    + Kích thước: Ø 48mm x20mm\r
    + Cân nặng: 29.2gram\r
    + Màu sắc: Trắng\r
    + Pin: 500mah (pin sạc)\r
    + Thời gian sử dụng: 5-7 ngày (có thể lên đến 45 ngày)`,
    "xeuat":``
}