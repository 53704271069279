import { Col, Row, Typography, Image, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { IoDocumentText, IoHeadset, IoListOutline } from "react-icons/io5";
import '@google/model-viewer/dist/model-viewer';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { descriptionDetail, descriptionGeneral } from "../assets/description/description";
import { descriptionDetail1704, descriptionGeneral1704 } from "../assets/description/description1704";
import { descriptionDetail2805, descriptionGeneral2805 } from "../assets/description/description2805";
import { descriptionDetail1704Eng, descriptionGeneral1704Eng } from "../assets/description/descriptionEng1704";
import { descriptionDetail2805Eng, descriptionGeneral2805Eng } from "../assets/description/descriptionEng2805";
import { descriptionDetailEng, descriptionGeneralEng } from "../assets/description/descriptionEng";
import { LoadingOutlined } from '@ant-design/icons';

require('dayjs/locale/vi')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const { Title } = Typography;
const logoviettel = require('../assets/img/viettelmuseum.png')

//audio viet
const viet_caysusong = require('../assets/audio/vietnamese/tuongcaysusong.wav')
const viet_quocphuctimo = require('../assets/audio/vietnamese/quocphuctimo.wav')
const viet_trongbeburundi = require('../assets/audio/vietnamese/trongonhobrundi.wav')
const viet_trongtomozambic = require('../assets/audio/vietnamese/tronggoto.wav')
const viet_tuongconbo = require('../assets/audio/vietnamese/tuongconbovang.wav')
const viet_tuongphat = require('../assets/audio/vietnamese/tuongphat.wav')
const viet_maythuphatvotuyen = require('../assets/audio/vietnamese/maythongtin631.wav')
const viet_dairada = require('../assets/audio/vietnamese/dairadabien.wav')
const viet_tramphatsong = require('../assets/audio/vietnamese/tram5g.wav')
const viet_hethontrinhsat = require('../assets/audio/vietnamese/vs13.wav')
const viet_robot = require('../assets/audio/vietnamese/robot.wav')
const viet_tumi = require('../assets/audio/vietnamese/Tumi.wav')
const viet_chodoxoi = require('../assets/audio/vietnamese/chodoxoi.wav')
const viet_buctranhchua = require('../assets/audio/vietnamese/chuavang.wav')
const viet_maygaynhieusong = require('../assets/audio/vietnamese/mayphasong.wav')
const viet_noilamviectotnhat = require('../assets/audio/vietnamese/noilamviec.wav')
const viet_cupchuyendoiso = require('../assets/audio/vietnamese/chuyendoiso.wav')
const viet_top10ctycongnghe = require('../assets/audio/vietnamese/doanhnghiepcntt.wav')
const viet_vtag = require('../assets/audio/vietnamese/Vtag.wav')

const viet_5g_micro = require('../assets/audio/vietnamese/26.wav')
const viet_5g_macrocell = require('../assets/audio/vietnamese/25.wav')
const viet_5g_massive_mimo = require('../assets/audio/vietnamese/24.wav')
const viet_site_router_100g = require('../assets/audio/vietnamese/23.wav')
const viet_chip_5g_dfe = require('../assets/audio/vietnamese/22.wav')
const viet_thiet_bi_ai_home_hub = require('../assets/audio/vietnamese/20.wav')
const viet_camera_giao_thong = require('../assets/audio/vietnamese/19.wav')
const viet_camera_thong_minh_toa_nha = require('../assets/audio/vietnamese/18.wav')
const viet_camera_thong_minh_ho_gia_dinh = require('../assets/audio/vietnamese/17.wav')
const viet_camera_anh_nhiet_giam_sat_toan_canh = require('../assets/audio/vietnamese/16.wav')
const viet_he_thong_giam_sat_quang_dien_tu_tam_xa = require('../assets/audio/vietnamese/15.wav')
const viet_thiet_bi_kinh_ngam_anh_nhiet = require('../assets/audio/vietnamese/14.wav')
const viet_thiet_bi_trinh_sat_anh_nhiet = require('../assets/audio/vietnamese/13.wav')
const viet_dai_quang_dien_tu_tam_xa = require('../assets/audio/vietnamese/12.wav')
const viet_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat = require('../assets/audio/vietnamese/11.wav')
const viet_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16 = require('../assets/audio/vietnamese/10.wav')
const viet_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g = require('../assets/audio/vietnamese/9.wav')
const viet_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao = require('../assets/audio/vietnamese/8.wav')
const viet_may_thu_phat_vo_tuyen_dien_ca_nhan = require('../assets/audio/vietnamese/7.wav')
const viet_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay = require('../assets/audio/vietnamese/6.wav')
const viet_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang = require('../assets/audio/vietnamese/5.wav')
const viet_he_thong_rada_thu_dong = require('../assets/audio/vietnamese/4.wav')
const viet_rada_canh_gioi_bien = require('../assets/audio/vietnamese/3.wav')
const viet_rada_chien_thuat = require('../assets/audio/vietnamese/2.wav')
const viet_rada_tam_trung = require('../assets/audio/vietnamese/1.wav')

//audio eng
const eng_caysusong = require('../assets/audio/english/tuongcaysusong.wav')
const eng_quocphuctimo = require('../assets/audio/english/quocphuctimo.wav')
const eng_trongbeburundi = require('../assets/audio/english/trongonhobrundi.wav')
const eng_trongtomozambic = require('../assets/audio/english/tronggoto.wav')
const eng_tuongconbo = require('../assets/audio/english/tuongconbovang.wav')
const eng_tuongphat = require('../assets/audio/english/tuongphat.wav')
const eng_maythuphatvotuyen = require('../assets/audio/english/maythongtin631.wav')
const eng_dairada = require('../assets/audio/english/dairadabien.wav')
const eng_tramphatsong = require('../assets/audio/english/tram5g.wav')
const eng_hethontrinhsat = require('../assets/audio/english/vs13.wav')
const eng_robot = require('../assets/audio/english/robot.wav')
const eng_tumi = require('../assets/audio/english/Tumi.wav')
const eng_chodoxoi = require('../assets/audio/english/chodoxoi.wav')
const eng_buctranhchua = require('../assets/audio/english/chuavang.wav')
const eng_maygaynhieusong = require('../assets/audio/english/mayphasong.wav')
const eng_noilamviectotnhat = require('../assets/audio/english/noilamviec.wav')
const eng_cupchuyendoiso = require('../assets/audio/english/chuyendoiso.wav')
const eng_top10ctycongnghe = require('../assets/audio/vietnamese/doanhnghiepcntt.wav')

const eng_5g_micro = require('../assets/audio/english/26.mp3')
const eng_5g_macrocell = require('../assets/audio/english/25.mp3')
const eng_5g_massive_mimo = require('../assets/audio/english/24.mp3')
const eng_site_router_100g = require('../assets/audio/english/23.mp3')
const eng_chip_5g_dfe = require('../assets/audio/english/22.mp3')
const eng_thiet_bi_ai_home_hub = require('../assets/audio/english/20.mp3')
const eng_camera_giao_thong = require('../assets/audio/english/19.mp3')
const eng_camera_thong_minh_toa_nha = require('../assets/audio/english/18.mp3')
const eng_camera_thong_minh_ho_gia_dinh = require('../assets/audio/english/17.mp3')
const eng_camera_anh_nhiet_giam_sat_toan_canh = require('../assets/audio/english/16.mp3')
const eng_he_thong_giam_sat_quang_dien_tu_tam_xa = require('../assets/audio/english/15.mp3')
const eng_thiet_bi_kinh_ngam_anh_nhiet = require('../assets/audio/english/14.mp3')
const eng_thiet_bi_trinh_sat_anh_nhiet = require('../assets/audio/english/13.mp3')
const eng_dai_quang_dien_tu_tam_xa = require('../assets/audio/english/12.mp3')
const eng_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat = require('../assets/audio/english/11.mp3')
const eng_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16 = require('../assets/audio/english/10.mp3')
const eng_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g = require('../assets/audio/english/9.mp3')
const eng_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao = require('../assets/audio/english/8.mp3')
const eng_may_thu_phat_vo_tuyen_dien_ca_nhan = require('../assets/audio/english/7.mp3')
const eng_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay = require('../assets/audio/english/6.mp3')
const eng_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang = require('../assets/audio/english/5.mp3')
const eng_he_thong_rada_thu_dong = require('../assets/audio/english/4.mp3')
const eng_rada_canh_gioi_bien = require('../assets/audio/english/3.mp3')
const eng_rada_chien_thuat = require('../assets/audio/english/2.mp3')
const eng_rada_tam_trung = require('../assets/audio/english/1.mp3')

//model 3d
const model_caysusong = require('../assets/3d/caysusong/2 done.glb')
const model_quocphuctimo = require('../assets/3d/quocphuctimo/1 done.glb')
const model_trongbeburundi = require('../assets/3d/trongbeburundi/hien-vat-4.glb')
const model_trongtomozambic = require('../assets/3d/trongtomozambic/hien-vat-6.glb')
const model_tuongconbo = require('../assets/3d/tuongconbo/3 done.glb')
const model_tuongphat = require('../assets/3d/tuongphat/tuong phat 01.glb')
const model_robot = require('../assets/3d/robot/robot.glb')
const model_noilamviectotnhat = require('../assets/3d/noilamviectotnhat/Noi lam viec tot nhat chau A.glb')
const model_chuyendoiso = require('../assets/3d/cupchuyendoiso/chuyen doi so viet nam 2021.glb')
const model_chodoxoi = require('../assets/3d/chodoxoi/cho_do_xoi.glb')
const model_tram5g = require('../assets/3d/tram5g/17.glb')
const model_rada = require('../assets/3d/rada/30.glb')
const model_vtag = require('../assets/3d/vtag/21.glb')
const model_xeoto = require('../assets/3d/xe/xe.glb')
const model_dai_quang_dien_tu_tam_xa = require('../assets/3d/new1704/daiquangdientutamxa.glb')
const model_he_thong_giam_sat_quang_dien_tu_tam_xa = require('../assets/3d/new1704/hethonggiamsatquangdientutrentau.glb')
const model_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang = require('../assets/3d/new1704/Hethongmaythuphatsong50W.glb')
const model_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay = require('../assets/3d/new1704/VRH811.glb')
const model_5g_micro = require('../assets/3d/new1704/phatsong5G.glb')
const model_rada_chien_thuat = require('../assets/3d/new1704/Radachienthuat.glb')
const model_rada_canh_gioi_bien = require('../assets/3d/new1704/radacanhgioibienphangiaicao.glb')
const model_rada_tam_trung = require('../assets/3d/new1704/RadatamtrungbangtanS.glb')
const model_thiet_bi_kinh_ngam_anh_nhiet = require('../assets/3d/new1704/thietbikinhngamanhnhiet.glb')
const model_thiet_bi_trinh_sat_anh_nhiet = require('../assets/3d/new1704/thietbitrinhsatanhnhiet.glb')
const model_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao = require('../assets/3d/new1704/thietbitruyendantocdocaovhcr.glb')
const model_thiet_bi_ai_home_hub = require('../assets/3d/new1704/ThietbiAIHome.glb')
const model_camera_giao_thong = require('../assets/3d/new1704/cameragiaothong.glb')
const model_camera_toa_nha = require('../assets/3d/new1704/cameratoanha.glb')
const model_5g_macrocell = require('../assets/3d/new1704/5GMacrocell8T8R.glb')
const model_may_thu_phat_vo_tuyen_dien_ca_nhan = require('../assets/3d/new1704/Thietbithongtincamtaycanhan.glb')
const model_site_router_100g = require('../assets/3d/new1704/SiteRouter100.glb')
const model_chip_5g_dfe = require('../assets/3d/new1704/Chip5GViettel.glb')

//anh model
const anh_caysusong = require('../assets/model_image/cây sự sống.png')
const anh_quocphuctimo = require('../assets/model_image/Tượng quốc phục.png')
const anh_trongbeburundi = require('../assets/model_image/trống burundi.png')
const anh_trongtomozambic = require('../assets/model_image/Trống to batuque.png')
const anh_tuongconbo = require('../assets/model_image/Bò vàng.png')
const anh_tuongphat = require('../assets/model_image/Tượng phật.png')
const anh_robot = require('../assets/model_image/Robot Viettel.png')
const anh_noilamviectotnhat = ""
const anh_chuyendoiso = ""
const anh_top10ctycongnghe = ""
const anh_maythuphatvotuyen = require('../assets/model_image/Máy thu phát sóng 631.png')
const anh_dairada = require('../assets/model_image/Rada cảnh giới bờ biển.png')
const anh_tramphatsong = require('../assets/model_image/Trạm sóng 5G.png')
const anh_hethontrinhsat = require('../assets/model_image/Trinh sát vô tuyến điện vs13.png')
const anh_tumi = ""
const anh_chodoxoi = require('../assets/model_image/chõ đồ xôi lào.png')
const anh_buctranhchua = require('../assets/model_image/chùa myanma.png')
const anh_maygaynhieusong = require('../assets/model_image/máy phá sóng.png')
const anh_vtag = require('../assets/model_image/vtag.jpg')

//anh moi 1704
const anh1704_dai_quang_dien_tu_tam_xa = require('../assets/image_detail/daiquangdientutamxa.png')
const anh1704_he_thong_giam_sat_quang_dien_tu_tam_xa = require('../assets/image_detail/hethonggiamsatquangdientutrentau.png')
const anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang = require('../assets/image_detail/Hethongmaythuphatsong50W.jpg')
const anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay = require('../assets/image_detail/maythucamtayvrh811.png')
// const anh_5g_micro = require('../assets/image_detail/')
const anh1704_rada_chien_thuat = require('../assets/image_detail/rada3dchienthuatbangS.png')
const anh1704_rada_canh_gioi_bien = require('../assets/image_detail/radacanhgioibienphangiaicao.png')
const anh1704_rada_tam_trung = require('../assets/image_detail/Rada3DtamtrungbangS.png')
const anh1704_thiet_bi_kinh_ngam_anh_nhiet = require('../assets/image_detail/thietbikinhngamanhnhiet.png')
const anh1704_thiet_bi_trinh_sat_anh_nhiet = require('../assets/image_detail/thietbitrinhsatanhnhiet.png')
const anh1704_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao = require('../assets/image_detail/thietbitruyendantocdocaovhcr.png')
const anh1704_5g_massive_mimo = require('../assets/image_detail/03052024/5GMassiveMimo32T32R.png')
const anh1704_camera_thong_minh_ho_gia_dinh = require('../assets/image_detail/03052024/Camerathongminhchohogiadinh.png')
const anh1704_chip_5g_dfe = require('../assets/image_detail/03052024/Chip5GDFE.jpg')
const anh1704_site_router_100g = require('../assets/image_detail/03052024/Siterouter100G.png')
const anh1704_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat = require('../assets/image_detail/03052024/maybaykhongnguoilaiUAV2-1.5.jpg')
const anh1704_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g = require('../assets/image_detail/03052024/maybaykhongnguoilaiVUA-SC-3G.jpg')
const anh1704_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16 = require('../assets/image_detail/03052024/maybaykhongnguoilaitrinhsathangnheUAV1-6.png')
const anh1704_he_thong_rada_thu_dong = require('../assets/image_detail/03052024/HethongradathudongV-ELINT18.jpg')
const anh1704_camera_anh_nhiet_giam_sat_toan_canh = require('../assets/image_detail/03052024/CameraanhnhietgiamsattoancanhVEE-IRST.jpg')
const anh1704_may_thu_phat_vo_tuyen_dien_ca_nhan = require('../assets/image_detail/03052024/thongtinvotuyencamtay.png')

//anh moi 2805
const anh2805_camera_ngoai_troi_hc32 = require('../assets/image_detail/28052024/camhc32final.png')
const anh2805_camera_ngoai_troi_hc33 = require('../assets/image_detail/28052024/camhc33.png')
const anh2805_thiet_bi_modem_quang = require('../assets/image_detail/28052024/ONT.png')
const anh2805_diem_truy_cap_wifi = require('../assets/image_detail/28052024/AP.jpg')
const anh2805_to_hop_trinh_sat_gay_nhieu_chong_phuong_tien_bay_khong_nguoi_lai_cap_chien_thuat = require('../assets/image_detail/28052024/BV5.png')
const anh2805_may_thong_tin_quan_su_bang_rong_the_he_moi = require('../assets/image_detail/28052024/MCR.png')
const anh2805_may_thu_phat_vo_tuyen_dien_da_bang_nhay_tan = require('../assets/image_detail/28052024/712.png')

export const dataHienvat = {
  "tuong-cay-su-song": {
    "gioithieuchung": descriptionGeneral.tuongcaysusong,
    "gioithieuchungEng": descriptionGeneralEng.tuongcaysusong,
    "chitiet": descriptionDetail.tuongcaysusong,
    "chitietEng": descriptionDetailEng.tuongcaysusong,
    "audioviet": viet_caysusong,
    "audioeng": eng_caysusong,
    "file3d": model_caysusong,
    "anhmodel": anh_caysusong,
  },
  "quoc-phuc-timo": {
    "gioithieuchung": descriptionGeneral.tuongquocphuctimonam,
    "gioithieuchungEng": descriptionGeneralEng.tuongquocphuctimonam,
    "chitiet": descriptionDetail.tuongquocphuctimonam,
    "chitietEng": descriptionDetailEng.tuongquocphuctimonam,
    "audioviet": viet_quocphuctimo,
    "audioeng": eng_quocphuctimo,
    "file3d": model_quocphuctimo,
    "anhmodel": anh_quocphuctimo,
  },
  "trong-go-nho-brundi": {
    "gioithieuchung": descriptionGeneral.trongtruyenthongburundi,
    "gioithieuchungEng": descriptionGeneralEng.trongtruyenthongburundi,
    "chitiet": descriptionDetail.trongtruyenthongburundi,
    "chitietEng": descriptionDetailEng.trongtruyenthongburundi,
    "audioviet": viet_trongbeburundi,
    "audioeng": eng_trongbeburundi,
    "file3d": model_trongbeburundi,
    "anhmodel": anh_trongbeburundi,
  },
  "trong-go-to": {
    "gioithieuchung": descriptionGeneral.trongbatuque,
    "gioithieuchungEng": descriptionGeneralEng.trongbatuque,
    "chitiet": descriptionDetail.trongbatuque,
    "chitietEng": descriptionDetailEng.trongbatuque,
    "audioviet": viet_trongtomozambic,
    "audioeng": eng_trongtomozambic,
    "file3d": model_trongtomozambic,
    "anhmodel": anh_trongtomozambic,
  },
  "tuong-con-bo-vang": {
    "gioithieuchung": descriptionGeneral.tuongconbo,
    "gioithieuchungEng": descriptionGeneralEng.tuongconbo,
    "chitiet": descriptionDetail.tuongconbo,
    "chitietEng": descriptionDetailEng.tuongconbo,
    "audioviet": viet_tuongconbo,
    "audioeng": eng_tuongconbo,
    "file3d": model_tuongconbo,
    "anhmodel": anh_tuongconbo,
  },
  "tuong-phat": {
    "gioithieuchung": descriptionGeneral.tuongphatbayon,
    "gioithieuchungEng": descriptionGeneralEng.tuongphatbayon,
    "chitiet": descriptionDetail.tuongphatbayon,
    "chitietEng": descriptionDetailEng.tuongphatbayon,
    "audioviet": viet_tuongphat,
    "audioeng": eng_tuongphat,
    "file3d": model_tuongphat,
    "anhmodel": anh_tuongphat,
  },
  "may-thu-phat-vo-tuyen": {
    "gioithieuchung": descriptionGeneral.mayvotuyendien,
    "gioithieuchungEng": descriptionGeneralEng.mayvotuyendien,
    "chitiet": descriptionDetail.mayvotuyendien,
    "chitietEng": descriptionDetailEng.mayvotuyendien,
    "audioviet": viet_maythuphatvotuyen,
    "audioeng": eng_maythuphatvotuyen,
    "file3d": "",
    "anhmodel": anh_maythuphatvotuyen,
  },
  "dai-ra-da-canh-gioi": {
    "gioithieuchung": descriptionGeneral.dairada,
    "gioithieuchungEng": descriptionGeneralEng.dairada,
    "chitiet": descriptionDetail.dairada,
    "chitietEng": descriptionDetailEng.dairada,
    "audioviet": viet_dairada,
    "audioeng": eng_dairada,
    "file3d": model_rada,
    "anhmodel": anh_dairada,
  },
  "tram-phat-song-5g": {
    "gioithieuchung": descriptionGeneral.tramphatsong5g,
    "gioithieuchungEng": descriptionGeneralEng.tramphatsong5g,
    "chitiet": descriptionDetail.tramphatsong5g,
    "chitietEng": descriptionDetailEng.tramphatsong5g,
    "audioviet": viet_tramphatsong,
    "audioeng": eng_tramphatsong,
    "file3d": model_tram5g,
    "anhmodel": anh_tramphatsong,
  },
  "he-thong-trinh-sat-vo-tuyen": {
    "gioithieuchung": descriptionGeneral.hethongtrinhsat,
    "gioithieuchungEng": descriptionGeneralEng.hethongtrinhsat,
    "chitiet": descriptionDetail.hethongtrinhsat,
    "chitietEng": descriptionDetailEng.hethongtrinhsat,
    "audioviet": viet_hethontrinhsat,
    "audioeng": eng_hethontrinhsat,
    "file3d": "",
    "anhmodel": anh_hethontrinhsat,
  },
  "robot-phuc-vu": {
    "gioithieuchung": descriptionGeneral.robotphucvu,
    "gioithieuchungEng": descriptionGeneralEng.robotphucvu,
    "chitiet": descriptionDetail.robotphucvu,
    "chitietEng": descriptionDetailEng.robotphucvu,
    "audioviet": viet_robot,
    "audioeng": eng_robot,
    "file3d": model_robot,
    "anhmodel": anh_robot,
  },
  "may-gay-nhieu-song-dien-thoai": {
    "gioithieuchung": descriptionGeneral.maygaynhieusong,
    "gioithieuchungEng": descriptionGeneralEng.maygaynhieusong,
    "chitiet": descriptionDetail.maygaynhieusong,
    "chitietEng": descriptionDetailEng.maygaynhieusong,
    "audioviet": viet_maygaynhieusong,
    "audioeng": eng_maygaynhieusong,
    "file3d": "",
    "anhmodel": anh_maygaynhieusong,
  },
  "tu-mi": {
    "gioithieuchung": descriptionGeneral.tumi,
    "gioithieuchungEng": descriptionGeneralEng.tumi,
    "chitiet": descriptionDetail.tumi,
    "chitietEng": descriptionDetailEng.tumi,
    "audioviet": viet_tumi,
    "audioeng": eng_tumi,
    "file3d": "",
    "anhmodel": anh_tumi,
  },
  "cho-do-xoi-truyen-thong": {
    "gioithieuchung": descriptionGeneral.chodoxoilao,
    "gioithieuchungEng": descriptionGeneralEng.chodoxoilao,
    "chitiet": descriptionDetail.chodoxoilao,
    "chitietEng": descriptionDetailEng.chodoxoilao,
    "audioviet": viet_chodoxoi,
    "audioeng": eng_chodoxoi,
    "file3d": model_chodoxoi,
    "anhmodel": anh_chodoxoi,
  },
  "buc-tranh-chua-truyen-thong": {
    "gioithieuchung": descriptionGeneral.tranhchuamyanmar,
    "gioithieuchungEng": descriptionGeneralEng.tranhchuamyanmar,
    "chitiet": descriptionDetail.tranhchuamyanmar,
    "chitietEng": descriptionDetailEng.tranhchuamyanmar,
    "audioviet": viet_buctranhchua,
    "audioeng": eng_buctranhchua,
    "file3d": "",
    "anhmodel": anh_buctranhchua,
  },
  "giai-thuong-noi-lam-viec-tot-nhat": {
    "gioithieuchung": descriptionGeneral.giaithuongnoilamviec,
    "gioithieuchungEng": descriptionGeneralEng.giaithuongnoilamviec,
    "chitiet": descriptionDetail.giaithuongnoilamviec,
    "chitietEng": descriptionDetailEng.giaithuongnoilamviec,
    "audioviet": viet_noilamviectotnhat,
    "audioeng": eng_noilamviectotnhat,
    "file3d": model_noilamviectotnhat,
    "anhmodel": anh_noilamviectotnhat,
  },
  "cup-giai-thuong-chuyen-doi-so": {
    "gioithieuchung": descriptionGeneral.cupchuyendoiso,
    "gioithieuchungEng": descriptionGeneralEng.cupchuyendoiso,
    "chitiet": descriptionDetail.cupchuyendoiso,
    "chitietEng": descriptionDetailEng.cupchuyendoiso,
    "audioviet": viet_cupchuyendoiso,
    "audioeng": eng_cupchuyendoiso,
    "file3d": model_chuyendoiso,
    "anhmodel": anh_chuyendoiso,
  },
  "cup-giai-thuong-top-10-cong-ty-cong-nghe": {
    "gioithieuchung": descriptionGeneral.cuptop10,
    "gioithieuchungEng": descriptionGeneralEng.cuptop10,
    "chitiet": descriptionDetail.cuptop10,
    "chitietEng": descriptionDetailEng.cuptop10,
    "audioviet": viet_top10ctycongnghe,
    "audioeng": eng_top10ctycongnghe,
    "file3d": "",
    "anhmodel": anh_top10ctycongnghe,
  },
  "dinh-vi-vtag": {
    "gioithieuchung": descriptionGeneral.dinhvi,
    "gioithieuchungEng": '',
    "chitiet": descriptionDetail.dinhvi,
    "chitietEng": '',
    "audioviet": viet_vtag,
    "audioeng": null,
    "file3d": model_vtag,
    "anhmodel": anh_vtag,
  },
  "xe-oto-uoat": {
    "gioithieuchung": descriptionGeneral.xeuat,
    "gioithieuchungEng": descriptionGeneralEng.xeuat,
    "chitiet": descriptionDetail.xeuat,
    "chitietEng": descriptionDetailEng.xeuat,
    "audioviet": null,
    "audioeng": null,
    "file3d": model_xeoto,
    "anhmodel": "",
  },
  "5g-micro": {
    "gioithieuchung": descriptionGeneral1704['5g-micro'],
    "gioithieuchungEng": descriptionGeneral1704Eng['5g-micro'],
    "chitiet": descriptionDetail1704['5g-micro'],
    "chitietEng": descriptionDetail1704Eng['5g-micro'],
    "audioviet": viet_5g_micro,
    "audioeng": eng_5g_micro,
    "file3d": model_5g_micro,
    "anhmodel": "",
    "anhchitiet": "",
  },
  "5g-macrocell": {
    "gioithieuchung": descriptionGeneral1704['5g-macrocell'],
    "gioithieuchungEng": descriptionGeneral1704['5g-macrocell'],
    "chitiet": descriptionDetail1704['5g-macrocell'],
    "chitietEng": descriptionDetail1704Eng['5g-macrocell'],
    "audioviet": viet_5g_macrocell,
    "audioeng": eng_5g_macrocell,
    "file3d": model_5g_macrocell,
    "anhmodel": "",
    "anhchitiet": "",
  },
  "5g-massive-mimo": {
    "gioithieuchung": descriptionGeneral1704['5g-massive-mimo'],
    "gioithieuchungEng": descriptionGeneral1704Eng['5g-massive-mimo'],
    "chitiet": descriptionDetail1704['5g-massive-mimo'],
    "chitietEng": descriptionDetail1704Eng['5g-massive-mimo'],
    "audioviet": viet_5g_massive_mimo,
    "audioeng": eng_5g_massive_mimo,
    "file3d": "",
    "anhmodel": anh1704_5g_massive_mimo,
    "anhchitiet": anh1704_5g_massive_mimo,
  },
  "site-router-100g": {
    "gioithieuchung": descriptionGeneral1704['site-router-100g'],
    "gioithieuchungEng": descriptionGeneral1704Eng['site-router-100g'],
    "chitiet": descriptionDetail1704['site-router-100g'],
    "chitietEng": descriptionDetail1704Eng['site-router-100g'],
    "audioviet": viet_site_router_100g,
    "audioeng": eng_site_router_100g,
    "file3d": model_site_router_100g,
    "anhmodel": anh1704_site_router_100g,
    "anhchitiet": anh1704_site_router_100g,
  },
  "chip-5g-dfe": {
    "gioithieuchung": descriptionGeneral1704['chip-5g-dfe'],
    "gioithieuchungEng": descriptionGeneral1704Eng['chip-5g-dfe'],
    "chitiet": descriptionDetail1704['chip-5g-dfe'],
    "chitietEng": descriptionDetail1704Eng['chip-5g-dfe'],
    "audioviet": viet_chip_5g_dfe,
    "audioeng": eng_chip_5g_dfe,
    "file3d": model_chip_5g_dfe,
    "anhmodel": anh1704_chip_5g_dfe,
    "anhchitiet": anh1704_chip_5g_dfe,
  },
  "thiet-bi-ai-home-hub": {
    "gioithieuchung": descriptionGeneral1704['thiet-bi-ai-home-hub'],
    "gioithieuchungEng": descriptionGeneral1704Eng['thiet-bi-ai-home-hub'],
    "chitiet": descriptionDetail1704['thiet-bi-ai-home-hub'],
    "chitietEng": descriptionDetail1704Eng['thiet-bi-ai-home-hub'],
    "audioviet": viet_thiet_bi_ai_home_hub,
    "audioeng": eng_thiet_bi_ai_home_hub,
    "file3d": model_thiet_bi_ai_home_hub,
    "anhmodel": "",
    "anhchitiet": "",
  },
  "camera-giao-thong": {
    "gioithieuchung": descriptionGeneral1704['camera-giao-thong'],
    "gioithieuchungEng": descriptionGeneral1704Eng['camera-giao-thong'],
    "chitiet": descriptionDetail1704['camera-giao-thong'],
    "chitietEng": descriptionDetail1704Eng['camera-giao-thong'],
    "audioviet": viet_camera_giao_thong,
    "audioeng": eng_camera_giao_thong,
    "file3d": model_camera_giao_thong,
    "anhmodel": "",
    "anhchitiet": "",
  },
  "camera-thong-minh-toa-nha": {
    "gioithieuchung": descriptionGeneral1704['camera-thong-minh-toa-nha'],
    "gioithieuchungEng": descriptionGeneral1704Eng['camera-thong-minh-toa-nha'],
    "chitiet": descriptionDetail1704['camera-thong-minh-toa-nha'],
    "chitietEng": descriptionDetail1704Eng['camera-thong-minh-toa-nha'],
    "audioviet": viet_camera_thong_minh_toa_nha,
    "audioeng": eng_camera_thong_minh_toa_nha,
    "file3d": model_camera_toa_nha,
    "anhmodel": "",
    "anhchitiet": "",
  },
  "camera-thong-minh-ho-gia-dinh": {
    "gioithieuchung": descriptionGeneral1704['camera-thong-minh-ho-gia-dinh'],
    "gioithieuchungEng": descriptionGeneral1704Eng['camera-thong-minh-ho-gia-dinh'],
    "chitiet": descriptionDetail1704['camera-thong-minh-ho-gia-dinh'],
    "chitietEng": descriptionDetail1704Eng['camera-thong-minh-ho-gia-dinh'],
    "audioviet": viet_camera_thong_minh_ho_gia_dinh,
    "audioeng": eng_camera_thong_minh_ho_gia_dinh,
    "file3d": "",
    "anhmodel": anh1704_camera_thong_minh_ho_gia_dinh,
    "anhchitiet": "",
  },
  "camera-anh-nhiet-giam-sat-toan-canh": {
    "gioithieuchung": descriptionGeneral1704['camera-anh-nhiet-giam-sat-toan-canh'],
    "gioithieuchungEng": descriptionGeneral1704Eng['camera-anh-nhiet-giam-sat-toan-canh'],
    "chitiet": descriptionDetail1704['camera-anh-nhiet-giam-sat-toan-canh'],
    "chitietEng": descriptionDetail1704Eng['camera-anh-nhiet-giam-sat-toan-canh'],
    "audioviet": viet_camera_anh_nhiet_giam_sat_toan_canh,
    "audioeng": eng_camera_anh_nhiet_giam_sat_toan_canh,
    "file3d": "",
    "anhmodel": anh1704_camera_anh_nhiet_giam_sat_toan_canh,
    "anhchitiet": "",
  },
  "he-thong-giam-sat-quang-dien-tu-tam-xa": {
    "gioithieuchung": descriptionGeneral1704['he-thong-giam-sat-quang-dien-tu-tam-xa'],
    "gioithieuchungEng": descriptionGeneral1704Eng['he-thong-giam-sat-quang-dien-tu-tam-xa'],
    "chitiet": descriptionDetail1704['he-thong-giam-sat-quang-dien-tu-tam-xa'],
    "chitietEng": descriptionDetail1704Eng['he-thong-giam-sat-quang-dien-tu-tam-xa'],
    "audioviet": viet_he_thong_giam_sat_quang_dien_tu_tam_xa,
    "audioeng": eng_he_thong_giam_sat_quang_dien_tu_tam_xa,
    "file3d": model_he_thong_giam_sat_quang_dien_tu_tam_xa,
    "anhmodel": anh1704_he_thong_giam_sat_quang_dien_tu_tam_xa,
    "anhchitiet": anh1704_he_thong_giam_sat_quang_dien_tu_tam_xa,
  },
  "thiet-bi-kinh-ngam-anh-nhiet": {
    "gioithieuchung": descriptionGeneral1704['thiet-bi-kinh-ngam-anh-nhiet'],
    "gioithieuchungEng": descriptionGeneral1704Eng['thiet-bi-kinh-ngam-anh-nhiet'],
    "chitiet": descriptionDetail1704['thiet-bi-kinh-ngam-anh-nhiet'],
    "chitietEng": descriptionDetail1704Eng['thiet-bi-kinh-ngam-anh-nhiet'],
    "audioviet": viet_thiet_bi_kinh_ngam_anh_nhiet,
    "audioeng": eng_thiet_bi_kinh_ngam_anh_nhiet,
    "file3d": model_thiet_bi_kinh_ngam_anh_nhiet,
    "anhmodel": anh1704_thiet_bi_kinh_ngam_anh_nhiet,
    "anhchitiet": anh1704_thiet_bi_kinh_ngam_anh_nhiet,
  },
  "thiet-bi-trinh-sat-anh-nhiet": {
    "gioithieuchung": descriptionGeneral1704['thiet-bi-trinh-sat-anh-nhiet'],
    "gioithieuchungEng": descriptionGeneral1704Eng['thiet-bi-trinh-sat-anh-nhiet'],
    "chitiet": descriptionDetail1704['thiet-bi-trinh-sat-anh-nhiet'],
    "chitietEng": descriptionDetail1704Eng['thiet-bi-trinh-sat-anh-nhiet'],
    "audioviet": viet_thiet_bi_trinh_sat_anh_nhiet,
    "audioeng": eng_thiet_bi_trinh_sat_anh_nhiet,
    "file3d": model_thiet_bi_trinh_sat_anh_nhiet,
    "anhmodel": anh1704_thiet_bi_trinh_sat_anh_nhiet,
    "anhchitiet": anh1704_thiet_bi_trinh_sat_anh_nhiet,
  },
  "dai-quang-dien-tu-tam-xa": {
    "gioithieuchung": descriptionGeneral1704['dai-quang-dien-tu-tam-xa'],
    "gioithieuchungEng": descriptionGeneral1704Eng['dai-quang-dien-tu-tam-xa'],
    "chitiet": descriptionDetail1704['dai-quang-dien-tu-tam-xa'],
    "chitietEng": descriptionDetail1704Eng['dai-quang-dien-tu-tam-xa'],
    "audioviet": viet_dai_quang_dien_tu_tam_xa,
    "audioeng": eng_dai_quang_dien_tu_tam_xa,
    "file3d": model_dai_quang_dien_tu_tam_xa,
    "anhmodel": anh1704_dai_quang_dien_tu_tam_xa,
    "anhchitiet": anh1704_dai_quang_dien_tu_tam_xa,
  },
  "may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat": {
    "gioithieuchung": descriptionGeneral1704['may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat'],
    "chitiet": descriptionDetail1704['may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat'],
    "chitietEng": descriptionDetail1704Eng['may-bay-khong-nguoi-lai-chien-dau-cap-chien-thuat'],
    "audioviet": viet_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat,
    "audioeng": eng_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat,
    "file3d": "",
    "anhmodel": anh1704_may_bay_khong_nguoi_lai_chien_dau_cap_chien_thuat,
    "anhchitiet": "",
  },
  "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16": {
    "gioithieuchung": descriptionGeneral1704['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16'],
    "chitiet": descriptionDetail1704['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16'],
    "chitietEng": descriptionDetail1704Eng['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-uav16'],
    "audioviet": viet_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16,
    "audioeng": eng_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16,
    "file3d": "",
    "anhmodel": anh1704_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_uav16,
    "anhchitiet": "",
  },
  "may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g": {
    "gioithieuchung": descriptionGeneral1704['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g'],
    "chitiet": descriptionDetail1704['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g'],
    "chitietEng": descriptionDetail1704Eng['may-bay-khong-nguoi-lai-trinh-sat-hang-nhe-tam-gan-vuasc3g'],
    "audioviet": viet_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g,
    "audioeng": eng_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g,
    "file3d": "",
    "anhmodel": anh1704_may_bay_khong_nguoi_lai_trinh_sat_hang_nhe_tam_gan_vuasc3g,
    "anhchitiet": "",
  },
  "thiet-bi-truyen-dan-vo-tuyen-toc-do-cao": {
    "gioithieuchung": descriptionGeneral1704['thiet-bi-truyen-dan-vo-tuyen-toc-do-cao'],
    "gioithieuchungEng": descriptionGeneral1704Eng['thiet-bi-truyen-dan-vo-tuyen-toc-do-cao'],
    "chitiet": descriptionDetail1704['thiet-bi-truyen-dan-vo-tuyen-toc-do-cao'],
    "chitietEng": descriptionDetail1704Eng['thiet-bi-truyen-dan-vo-tuyen-toc-do-cao'],
    "audioviet": viet_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao,
    "audioeng": eng_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao,
    "file3d": model_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao,
    "anhmodel": anh1704_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao,
    "anhchitiet": anh1704_thiet_bi_truyen_dan_vo_tuyen_toc_do_cao,
  },
  "may-thu-phat-vo-tuyen-dien-ca-nhan": {
    "gioithieuchung": descriptionGeneral1704['may-thu-phat-vo-tuyen-dien-ca-nhan'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-thu-phat-vo-tuyen-dien-ca-nhan'],
    "chitiet": descriptionDetail1704['may-thu-phat-vo-tuyen-dien-ca-nhan'],
    "chitietEng": descriptionDetail1704Eng['may-thu-phat-vo-tuyen-dien-ca-nhan'],
    "audioviet": viet_may_thu_phat_vo_tuyen_dien_ca_nhan,
    "audioeng": eng_may_thu_phat_vo_tuyen_dien_ca_nhan,
    "file3d": model_may_thu_phat_vo_tuyen_dien_ca_nhan,
    "anhmodel": anh1704_may_thu_phat_vo_tuyen_dien_ca_nhan,
    "anhchitiet": anh1704_may_thu_phat_vo_tuyen_dien_ca_nhan,
  },
  "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay": {
    "gioithieuchung": descriptionGeneral1704['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay'],
    "chitiet": descriptionDetail1704['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay'],
    "chitietEng": descriptionDetail1704Eng['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-cam-tay'],
    "audioviet": viet_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay,
    "audioeng": eng_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay,
    "file3d": model_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay,
    "anhmodel": anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay,
    "anhchitiet": anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_cam_tay,
  },
  "may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang": {
    "gioithieuchung": descriptionGeneral1704['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang'],
    "gioithieuchungEng": descriptionGeneral1704Eng['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang'],
    "chitiet": descriptionDetail1704['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang'],
    "chitietEng": descriptionDetail1704Eng['may-thu-phat-vo-tuyen-dien-song-cuc-ngan-xe-tang'],
    "audioviet": viet_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang,
    "audioeng": eng_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang,
    "file3d": model_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang,
    "anhmodel": anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang,
    "anhchitiet": anh1704_may_thu_phat_vo_tuyen_dien_song_cuc_ngan_xe_tang,
  },
  "he-thong-rada-thu-dong": {
    "gioithieuchung": descriptionGeneral1704['he-thong-rada-thu-dong'],
    "gioithieuchungEng": descriptionGeneral1704Eng['he-thong-rada-thu-dong'],
    "chitiet": descriptionDetail1704['he-thong-rada-thu-dong'],
    "chitietEng": descriptionDetail1704Eng['he-thong-rada-thu-dong'],
    "audioviet": viet_he_thong_rada_thu_dong,
    "audioeng": eng_he_thong_rada_thu_dong,
    "file3d": "",
    "anhmodel": anh1704_he_thong_rada_thu_dong,
    "anhchitiet": "",
  },
  "rada-canh-gioi-bien": {
    "gioithieuchung": descriptionGeneral1704['rada-canh-gioi-bien'],
    "gioithieuchungEng": descriptionGeneral1704Eng['rada-canh-gioi-bien'],
    "chitiet": descriptionDetail1704['rada-canh-gioi-bien'],
    "chitietEng": descriptionDetail1704Eng['rada-canh-gioi-bien'],
    "audioviet": viet_rada_canh_gioi_bien,
    "audioeng": eng_rada_canh_gioi_bien,
    "file3d": model_rada_canh_gioi_bien,
    "anhmodel": anh1704_rada_canh_gioi_bien,
    "anhchitiet": anh1704_rada_canh_gioi_bien,
  },
  "rada-chien-thuat": {
    "gioithieuchung": descriptionGeneral1704['rada-chien-thuat'],
    "gioithieuchungEng": descriptionGeneral1704Eng['rada-chien-thuat'],
    "chitiet": descriptionDetail1704['rada-chien-thuat'],
    "chitietEng": descriptionDetail1704Eng['rada-chien-thuat'],
    "audioviet": viet_rada_chien_thuat,
    "audioeng": eng_rada_chien_thuat,
    "file3d": model_rada_chien_thuat,
    "anhmodel": anh1704_rada_chien_thuat,
    "anhchitiet": anh1704_rada_chien_thuat,
  },
  "rada-tam-trung": {
    "gioithieuchung": descriptionGeneral1704['rada-tam-trung'],
    "gioithieuchungEng": descriptionGeneral1704Eng['rada-tam-trung'],
    "chitiet": descriptionDetail1704['rada-tam-trung'],
    "chitietEng": descriptionDetail1704Eng['rada-tam-trung'],
    "audioviet": viet_rada_tam_trung,
    "audioeng": eng_rada_tam_trung,
    "file3d": model_rada_tam_trung,
    "anhmodel": anh1704_rada_tam_trung,
    "anhchitiet": anh1704_rada_tam_trung,
  },
  "may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan": {
    "gioithieuchung": descriptionGeneral2805['may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan'],
    "gioithieuchungEng": descriptionGeneral2805Eng['may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan'],
    "chitiet": descriptionDetail2805['may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan'],
    "chitietEng": descriptionDetail2805Eng['may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_may_thu_phat_vo_tuyen_dien_da_bang_nhay_tan,
    "anhchitiet": "",
  },
  "may-thong-tin-quan-su-bang-rong-the-he-moi": {
    "gioithieuchung": descriptionGeneral2805['may-thong-tin-quan-su-bang-rong-the-he-moi'],
    "gioithieuchungEng": descriptionGeneral2805Eng['may-thong-tin-quan-su-bang-rong-the-he-moi'],
    "chitiet": descriptionDetail2805['may-thong-tin-quan-su-bang-rong-the-he-moi'],
    "chitietEng": descriptionDetail2805Eng['may-thong-tin-quan-su-bang-rong-the-he-moi'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_may_thong_tin_quan_su_bang_rong_the_he_moi,
    "anhchitiet": "",
  },
  "to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat": {
    "gioithieuchung": descriptionGeneral2805['to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat'],
    "gioithieuchungEng": descriptionGeneral2805Eng['to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat'],
    "chitiet": descriptionDetail2805['to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat'],
    "chitietEng": descriptionDetail2805Eng['to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_to_hop_trinh_sat_gay_nhieu_chong_phuong_tien_bay_khong_nguoi_lai_cap_chien_thuat,
    "anhchitiet": "",
  },
  "diem-truy-cap-wifi": {
    "gioithieuchung": descriptionGeneral2805['diem-truy-cap-wifi'],
    "gioithieuchungEng": descriptionGeneral2805Eng['diem-truy-cap-wifi'],
    "chitiet": descriptionDetail2805['diem-truy-cap-wifi'],
    "chitietEng": descriptionDetail2805Eng['diem-truy-cap-wifi'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_diem_truy_cap_wifi,
    "anhchitiet": "",
  },
  "thiet-bi-modem-quang": {
    "gioithieuchung": descriptionGeneral2805['thiet-bi-modem-quang'],
    "gioithieuchungEng": descriptionGeneral2805Eng['thiet-bi-modem-quang'],
    "chitiet": descriptionDetail2805['thiet-bi-modem-quang'],
    "chitietEng": descriptionDetail2805Eng['thiet-bi-modem-quang'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_thiet_bi_modem_quang,
    "anhchitiet": "",
  },
  "camera-ngoai-troi-hc33": {
    "gioithieuchung": descriptionGeneral2805['camera-ngoai-troi-hc33'],
    "gioithieuchungEng": descriptionGeneral2805Eng['camera-ngoai-troi-hc33'],
    "chitiet": descriptionDetail2805['camera-ngoai-troi-hc33'],
    "chitietEng": descriptionDetail2805Eng['camera-ngoai-troi-hc33'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_camera_ngoai_troi_hc33,
    "anhchitiet": "",
  },
  "camera-ngoai-troi-hc32": {
    "gioithieuchung": descriptionGeneral2805['camera-ngoai-troi-hc32'],
    "gioithieuchungEng": descriptionGeneral2805Eng['camera-ngoai-troi-hc32'],
    "chitiet": descriptionDetail2805['camera-ngoai-troi-hc32'],
    "chitietEng": descriptionDetail2805Eng['camera-ngoai-troi-hc32'],
    "audioviet": "",
    "audioeng": "",
    "file3d": "",
    "anhmodel": anh2805_camera_ngoai_troi_hc32,
    "anhchitiet": "",
  },
}

const HomeScreen = () => {

  const { t, i18n } = useTranslation();
  let { uuid } = useParams();
  let refAudio = useRef()
  const [isExpand, setIsExpand] = useState(false)

  const handleExpand = () => {
    setIsExpand(!isExpand)
  }

  useEffect(() => {
    const touchArea = document.getElementById('touchArea');
    touchArea.addEventListener('touchstart', function (event) {
      refAudio && refAudio.audioEl?.current.play()
    });
  }, [refAudio])
  const handleAutoPlay = () => {
    refAudio && refAudio.audioEl?.current.play()
  }
  const handleChangeLang = () => {
    refAudio && refAudio.audioEl?.current?.play()
    i18n.changeLanguage(i18n?.language === "en-US" ? "vi-VN" : "en-US");
  }

  dayjs.locale(i18n.language);

  useEffect(() => {
    localStorage.setItem('@lang', i18n?.language)
  }, [i18n?.language]);

  return (
    <div className='HomeContainer' id="touchArea">
      {uuid ? <Row gutter={16} className='bodyContainer'>
        <Col xs={24} className='logoContainer'>
          <div>
            <img className='logoImg' src={logoviettel} alt={"logo"} />
          </div>
          <div className='flagContainer'>
            <ReactCountryFlag
              countryCode={i18n?.language === "en-US" ? "GB" : "VN"}
              svg
              style={{
                width: '80%',
                height: '80%',
              }}
              title="flag"
              onClick={() => handleChangeLang()}
            />
          </div>
        </Col>
        <Col xs={24}>
          <div className='modelContainer'>
            {dataHienvat[uuid].file3d ? <model-viewer
              src={dataHienvat[uuid].file3d}
              ios-src=""
              poster={dataHienvat[uuid].anhmodel ? dataHienvat[uuid].anhmodel : ""}
              alt="A 3D model"
              shadow-intensity="1"
              camera-controls
              auto-rotate
              ar
              className='modelContainer'
              touch-action="pan-y"
            ></model-viewer> :
              <Image
                // maxHeight={"430px"}
                width={"100%"}
                src={dataHienvat[uuid].anhmodel}
              />}
          </div>
        </Col>
        <Col className='ContentContainer'>
          <Title level={3} strong className='text-white titleText'>{t(`${uuid}`)}</Title>
          <div className='text-white timeText'>
            <div>{dayjs(new Date()).format('llll')}</div>
          </div>
          <Row>
            <Title level={4} strong className='text-white'>
              <Col className='titleContent'>
                <IoDocumentText size={18} className='text-white iconTitle' />
                {t('gioithieuchung')}
              </Col>
            </Title>
          </Row>
          <Row>
            <Col className='content text-white'>
              {i18n?.language === "en-US" ? dataHienvat[uuid].gioithieuchungEng : dataHienvat[uuid].gioithieuchung}
            </Col>
              {dataHienvat[uuid].anhchitiet ?
                <div className='image-detail-container'>
                  <Image
                    alt="image_detail"
                    height={"200px"}
                    src={dataHienvat[uuid].anhchitiet}
                    className='textCenter'
                  />
                  <div className='titleImageDetail text-white textCenter'>{t(`${uuid}`)}</div>
                </div>
                : ""}
            {dataHienvat[uuid].chitiet && <Col className='readmore'>
              <div onClick={() => handleExpand()}>{!isExpand && t('xemthem')}</div>
            </Col>}
          </Row>
          {(isExpand && dataHienvat[uuid].chitiet) && <>
            <Row>
              <Title level={4} strong className='text-white'>
                <Col className='titleContent'>
                  <IoListOutline size={18} className='text-white iconTitle' />
                  {t('chitiet')}
                </Col>
              </Title>
            </Row>
            <Row>
              <Col className='content text-white'>
                {i18n?.language === "en-US" ? dataHienvat[uuid].chitietEng : dataHienvat[uuid].chitiet}
              </Col>
              <Col className='readmore'>
                <div onClick={() => handleExpand()}>{isExpand && t('an')}</div>
              </Col>
            </Row>
          </>}
          <Row>
            <Title level={4} strong className='text-white'>
              <Col className='titleContent'>
                <IoHeadset size={18} className='text-white iconTitle' />
                Audio
              </Col>
            </Title>
            <Col xs={24}>
              {(dataHienvat[uuid].audioeng || dataHienvat[uuid].audioviet) && <ReactAudioPlayer
                src={i18n?.language === "en-US" ? dataHienvat[uuid].audioeng : dataHienvat[uuid].audioviet}
                autoplay
                onCanplay={() => handleAutoPlay()}
                ref={(element) => { refAudio = element }}
                controls
                className='audio-player'
              />}
            </Col>
          </Row>
        </Col>
      </Row> : <Spin fullscreen indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      } />}
      <div className='footerContainer text-white'>
        <div className='footerText'>{t('congthongtin')}</div>
        <div className='footerText'>{t('coquanchuquan')}</div>
      </div>
    </div>
  )
};

export default HomeScreen;
