export const descriptionGeneral2805 = {
    "camera-ngoai-troi-hc32": `Camera ngoài trời HC32 là một lựa chọn tốt để giám sát an ninh ở khu vực ngoài trời sản phẩm do Tổng Công ty Công nghiệp Công nghệ cao Viettel nghiên cứu.
    Một số thông tin về camera này:
    - Độ phân giải FullHD 2Mpx (1920×1080)**: Camera HC33 cung cấp hình ảnh sắc nét và chi tiết.
    - Hỗ trợ hồng ngoại và đèn led lên tới 30m**: Điều này cho phép quan sát trong điều kiện ánh sáng yếu hoặc ban đêm.
    - Công nghệ nén hình chuẩn H.265**: Giúp tiết kiệm băng thông và lưu trữ.
    - Chống nước, bụi IP67**: Camera HC32 có khả năng hoạt động tốt trong mọi điều kiện thời tiết khắc nghiệt.
    - Công nghệ AI phân biệt người và vật, giảm cảnh báo giả**: Camera có khả năng phát hiện chuyển động của con người và vật, giúp giảm cảnh báo không cần thiết.
    - Tích hợp tính năng đàm thoại 2 chiều**: Cho phép giao tiếp trực tiếp với người ở phía bên ngoài.
    - Quan sát màu vào ban đêm**: Camera được trang bị tính năng night vision giúp quan sát được các hoạt động trong bóng tối và đảm bảo an toàn cho người sử dụng.
    - Phạm vi quan sát có thể lên đến 30m**: Đảm bảo quan sát được diện tích rộng lớn.`,
    "camera-ngoai-troi-hc33": `Camera ngoài trời HC33 là một lựa chọn tốt để giám sát an ninh ở khu vực ngoài trời sản phẩm do Tổng Công ty Công nghiệp Công nghệ cao Viettel nghiên cứu.
    Một số thông tin về camera này:
    - Độ phân giải FullHD 2Mpx (1920×1080)**: Camera HC33 cung cấp hình ảnh sắc nét và chi tiết.
    - Hỗ trợ hồng ngoại và đèn led lên tới 30m**: Điều này cho phép quan sát trong điều kiện ánh sáng yếu hoặc ban đêm.
    - Công nghệ nén hình chuẩn H.265**: Giúp tiết kiệm băng thông và lưu trữ.
    - Chống nước, bụi IP67**: Camera HC33 có khả năng hoạt động tốt trong mọi điều kiện thời tiết khắc nghiệt.
    - Công nghệ AI phân biệt người và vật, giảm cảnh báo giả**: Camera có khả năng phát hiện chuyển động của con người và vật, giúp giảm cảnh báo không cần thiết.
    - Tích hợp tính năng đàm thoại 2 chiều**: Cho phép giao tiếp trực tiếp với người ở phía bên ngoài.
    - Quan sát màu vào ban đêm**: Camera được trang bị tính năng night vision giúp quan sát được các hoạt động trong bóng tối và đảm bảo an toàn cho người sử dụng.
    - Phạm vi quan sát có thể lên đến 30m**: Đảm bảo quan sát được diện tích rộng lớn.`,
    "thiet-bi-modem-quang": `Viettel ONT là thiết bị đầu cuối đặt tại nhà khách hàng nhằm cung cấp các dịch vụ như internet, IPTV, VoIP…
    ĐẶC TRƯNG
    • Cổng Ethernet 4 x 10/100/1000 cơ sở-TX.
    • Cổng quang: DL/UL:2.5/1.25 Gbps.
    • Ăng-ten: 2 x 2 MIMO.
    • Truyền dữ liệu cáp quang bằng bộ tách quang hoặc bằng OLT trên mạng.
    • Cho phép kết nối với thiết bị ngoại vi thông qua các chuẩn giao tiếp như USB, VoIP, Ethernet,...
    • Thực hiện truyền dữ liệu bằng tín hiệu không dây (Wifi) giữa thiết bị GPON và thiết bị đầu cuối của khách hàng.
    • Hỗ trợ tính năng băng tần kép, hoạt động trên cả hai tần số thu phát 2,4 GHz và 5 GHz.
    • Sẵn sàng triển khai mạng Wifi MESH cho khách hàng.`,
    "diem-truy-cap-wifi": `Điểm truy cập Wifi - Viettel Wifi Access Point là thiết bị đầu cuối đặt tại nhà khách hàng, có nhiệm vụ cung cấp dịch vụ Internet Wifi.
    ĐẶC TRƯNG
    • Hỗ trợ công nghệ MIMO 2 x 2 trên băng tần 2.4 GHz và 5 GHz, cho phép tốc độ kết nối lên đến 300 Mbps.
    • Hai luồng vô tuyến đồng thời cung cấp kết nối Wifi tốc độ cao cho số lượng lớn người dùng.
    • Anten thông minh được thiết kế tối ưu trong
    • Phù hợp với không gian sử dụng, giúp tăng
    • Phủ sóng và ổn định.`,
    "to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat": `Tổ hợp trinh sát gây nhiễu chống phương tiện bay không người lái cấp chiến thuật BV5 dùng để trinh sát phát hiện, nhận dạng, định hướng, bám sát và gây nhiễu các thiết bị bay không người lái UAV/Drone nhằm ngăn chặn UAV thâm nhập, theo dõi, tấn công khu vực trọng yếu.
    Tổ hợp gồm các thành phần: Trinh sát vô tuyến điện tử, Ra-đa độ phân giải cao, Quang điện tử và đài Gây nhiễu thông minh công suất lớn. Sự kết hợp nhiều thành phần làm cho tổ hợp có xác suất phát hiện mục tiêu từ cự ly 3,3 - 5,5 km và xác xuất đánh chặn mục tiêu từ cự ly 1,5 - 3,3 km lên tới 99,99%.`,
    "may-thong-tin-quan-su-bang-rong-the-he-moi": `Máy thông tin quân sự băng rộng thế hệ mới (MCR) là dòng máy thông tin thích nghi thế hệ mới, sử dụng để liên lạc đa mục đích với băng tần hoạt động ở các dải tần VHF/UHF/L Band/S Band.
    Công nghệ nền tảng Cognitive Radio (CR) cho phép máy tự động điều chỉnh các chế độ làm việc theo điều kiện môi trường, dễ dàng cập nhật các tính năng máy thông qua nâng cấp phần mềm.
    Máy vô tuyến thích nghi băng rộng thế hệ mới là máy cấu hình mang vác, liên lạc chiến thuật cấp trung, có khả năng liên lạc song công, liên lạc nhóm, tổ chức mạng nhiều thành viên.`,
    "may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan": `Máy thu phát vô tuyến điện đa băng nhảy tần VRP-712/S là máy thu phát vô tuyến điện nhảy tần sử dụng công nghệ SDR, hoạt động trong cả dải tần sóng ngắn (HF) và sóng cực ngắn (VHF), phục vụ cấp chiến dịch, chiến thuật; có khả năng liên lạc thoại, báo, truyền dữ liệu, mã hóa bảo mật, chống tác chiến điện tử. Định hướng trang bị cho tổ đài mang xách, lắp đặt tại trạm cố định hoặc trên xe cơ động.
    TÍNH NĂNG
    • Cự ly liên lạc lên đến 1000 km.
    • Thoại rõ tần số cố định, thoại rõ nhảy tần, thoại mật tần số cố định, thoại mật nhảy tần.
    • Tự động thiết lập đường truyền HF ALE-3G (theo chuẩn MIL-STD-188-141B).
    • Mã hóa bảo mật dữ liệu AES256, hỗ trợ gắn thêm mô đun cơ yếu bảo mật bên ngoài.
    • Điều khiển xa qua đôi dây dã chiến cự ly đến 5km, cho phép điều khiển xa qua giao diện IP.
    • Có khả năng nâng cấp tính năng thông qua cập nhật phần mềm.
    • Định vị toàn cầu GPS.`,
}
export const descriptionDetail2805 = {
    "camera-ngoai-troi-hc32": "",
    "camera-ngoai-troi-hc33": "",
    "thiet-bi-modem-quang": "",
    "diem-truy-cap-wifi": "",
    "to-hop-trinh-sat-gay-nhieu-chong-phuong-tien-bay-khong-nguoi-lai-cap-chien-thuat": "",
    "may-thong-tin-quan-su-bang-rong-the-he-moi": "",
    "may-thu-phat-vo-tuyen-dien-da-bang-nhay-tan": "",
}