import {
    BrowserRouter,
    Route, Routes
} from "react-router-dom";
import HomeScreen from '../screens/HomeScreen';

const Approuter = () => {
    return <BrowserRouter>
        <Routes>
            <Route
                path="/"
                // element={<HomeScreen />}
                Component={HomeScreen}
                lazy={() => import("../screens/HomeScreen")}

            />
            <Route
                path="/:uuid"
                Component={HomeScreen}
                lazy={() => import("../screens/HomeScreen")}

            />
        </Routes>
    </BrowserRouter>
}


export default Approuter;